import { useSuspenseQuery } from '@tanstack/react-query'
import React from 'react'
import { useMount } from 'react-use'
import invariant from 'tiny-invariant'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../../../../lib/constants'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import panelService from '../../../../../lib/services/panelService'
import { showCommercialAgreementsModal } from '../../../../../redux/slices/commercialAgreementsModal'
import CommercialAgreementDetails from '../../../CommercialAgreementsDetails/CommercialAgreementsDetails'

interface Props {
  panelId: number
  otherCompanyId: number
}

const CommercialAgreements: React.FC<Props> = ({ panelId, otherCompanyId }) => {
  const panel = usePanelQuery(panelId).data
  const dispatch = useAppDispatch()

  useMount(() => {
    dispatch(
      showCommercialAgreementsModal({
        panelId: panel.id,
        referralDirection: COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
      }),
    )
  })

  return (
    <section className="pb-2">
      <CommercialAgreementDetails
        variant="inline"
        otherCompanyId={otherCompanyId}
      />
    </section>
  )
}

export function usePanelQuery(panelId?: number) {
  return useSuspenseQuery({
    queryKey: QUERY_KEYS.panel(panelId),
    queryFn: async () => {
      invariant(panelId, 'Expected panelId to be defined')
      return panelService.getPanel(panelId)
    },
    refetchOnWindowFocus: false,
    staleTime: 2000,
  })
}

export default CommercialAgreements
