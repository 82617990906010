import React from 'react'

import ESignatureLetterModal, {
  ESignatureLetterModalProps,
} from '../ESignatureLetterModal'
import { usePreviewESignatureLetterModal } from './usePreviewESignatureLetterModal'

type Props = Omit<ESignatureLetterModalProps, 'isShowing' | 'onHide'>

const PreviewESignatureLetterModal: React.FC<Props> = (props) => {
  const { isShowing, hidePreview } = usePreviewESignatureLetterModal()

  return (
    <ESignatureLetterModal
      {...props}
      isShowing={isShowing}
      onHide={hidePreview}
    />
  )
}

export default PreviewESignatureLetterModal
