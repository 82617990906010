import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@rq-ratings/pro-light-svg-icons'
import classNames from 'classnames'
import React from 'react'

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'target'>

const ExternalLink: React.FC<Props> = ({
  children,
  rel = 'noreferrer',
  className,
  ...props
}) => (
  <a
    {...props}
    rel={rel}
    target="_blank"
    className={classNames(
      'd-inline-flex gap-1 justify-content-center align-items-center',
      className,
    )}
  >
    <div>{children}</div>
    <FontAwesomeIcon icon={faExternalLinkAlt} className="text-sm" />
  </a>
)

export default ExternalLink
