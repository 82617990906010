import React from 'react'

import CollapsibleContent from '../../../CollapsibleContent'
import SectionHeading from './SectionHeading'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  briefHistory?: string
}

const BriefHistory: React.FC<Props> = ({ briefHistory, ...props }) => {
  if (!briefHistory) {
    return null
  }

  return (
    <div {...props}>
      <SectionHeading>Brief history</SectionHeading>
      <CollapsibleContent content={briefHistory} />
    </div>
  )
}

export default BriefHistory
