import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { CompanyProfileItem } from '../../../../../types/responses/companies'
import { useIsPartnerModal } from '../../utils/useIsPartnerModal'
import Accreditations from './Accreditations'
import AtAGlance from './AtAGlance'
import BriefHistory from './BriefHistory'
import Locations from './Locations'

interface Props {
  companyProfile: CompanyProfileItem
}

const Overview: React.FC<Props> = ({ companyProfile }) => {
  const { company } = companyProfile
  const isPartnerModal = useIsPartnerModal()
  const briefHistory = company?.briefHistory || ''

  function renderForPartnerModal() {
    return (
      <>
        <Row>
          <Col md={6}>
            <AtAGlance companyProfile={companyProfile} />
          </Col>

          <Col md={6} className="ps-md-4 mt-4 mt-md-0 mb-md-4">
            <Accreditations
              companyProfile={companyProfile}
              badgesClassName="d-flex flex-row flex-md-column flex-wrap align-items-start gap-2 mb-3 mb-md-4"
            />

            {briefHistory && (
              <BriefHistory briefHistory={company?.briefHistory || ''} />
            )}
          </Col>
        </Row>

        <Locations companyProfile={companyProfile} />
      </>
    )
  }

  function renderForProfilePage() {
    return (
      <Row>
        <Col xl={4}>
          <AtAGlance companyProfile={companyProfile} />
        </Col>

        <Col xl={8} className="ps-xl-5">
          <div className="mt-4 mt-xl-0 mb-xl-4">
            <Accreditations
              companyProfile={companyProfile}
              badgesClassName="d-flex flex-row flex-wrap gap-2"
            />
          </div>

          {briefHistory && (
            <div className="mt-4 mt-xl-3">
              <BriefHistory briefHistory={company?.briefHistory || ''} />
            </div>
          )}

          <Locations companyProfile={companyProfile} />
        </Col>
      </Row>
    )
  }

  return (
    <div className="pb-3">
      {isPartnerModal ? renderForPartnerModal() : renderForProfilePage()}
    </div>
  )
}

export default Overview
