import { useSearchParams } from 'react-router-dom'

export function usePreviewESignatureLetterModal() {
  const [searchParams, setSearchParams] = useSearchParams()

  function showPreview() {
    searchParams.set(
      PREVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isPreviewingESignatureLetter,
      'true',
    )
    setSearchParams(searchParams, { replace: true })
  }

  function hidePreview() {
    searchParams.delete(
      PREVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isPreviewingESignatureLetter,
    )
    setSearchParams(searchParams, { replace: true })
  }

  return {
    isShowing: searchParams.has(
      PREVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isPreviewingESignatureLetter,
    ),
    showPreview,
    hidePreview,
  }
}

export const PREVIEW_ESIGNATURE_LETTER_MODAL_PARAMS = {
  isPreviewingESignatureLetter: 'isPreviewingESignatureLetter',
}
