import React from 'react'

import { CompanyProfileItem } from '../../../../../types/responses/companies'
import { useIsCompanyProfilePage } from '../../utils/useIsCompanyProfilePage'
import { useIsPartnerModal } from '../../utils/useIsPartnerModal'
import Map from './Map'
import SectionHeading from './SectionHeading'

interface Props {
  companyProfile: CompanyProfileItem
}

const Locations: React.FC<Props> = ({ companyProfile }) => {
  const isPartnerModal = useIsPartnerModal()
  const isCompanyProfilePage = useIsCompanyProfilePage()
  const { fca, company } = companyProfile
  const isTradingName = !!fca?.frnTradingNameId
  const postcodes =
    fca && !isTradingName ? [{ postcode: fca.postcode, name: fca.county }] : []

  const locations = postcodes
    .filter(
      (location, index, self) =>
        index === self.findIndex((t) => t.name === location.name),
    )
    .map((location) => location.name)

  if (!companyProfile.isRegistered || !locations.length) {
    return null
  }

  if (company) {
    for (const address of company.addresses) {
      const listing = { postcode: address.postcode, name: address.city }
      postcodes.push(listing)
    }
  }

  const clientLocations = companyProfile.company?.clientLocations

  return (
    <section>
      {isCompanyProfilePage && (
        <Map
          postcodes={postcodes}
          className="my-1 w-100"
          style={{ height: '400px' }}
        />
      )}

      <div className="row">
        <div className="col-md-6 pe-md-4 mt-3">
          <SectionHeading>Office Locations</SectionHeading>
          <p className="mb-2">{locations.join(', ')}</p>

          {isPartnerModal && (
            <Map
              postcodes={postcodes}
              className="w-100"
              style={{ height: '250px' }}
            />
          )}
        </div>

        {clientLocations && (
          <div className="col-md-6 ps-md-4 mt-3">
            <SectionHeading>Client locations</SectionHeading>
            <p>{clientLocations}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default Locations
