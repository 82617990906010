import { ErrorBoundary } from '@sentry/react'
import React from 'react'

import InfoBox from './InfoBox'

interface Props {
  children: React.ReactNode
  message: React.ReactNode
}

const ErrorBoundaryCard: React.FC<Props> = ({ children, message }) => (
  <ErrorBoundary fallback={<InfoBox variant="danger">{message}</InfoBox>}>
    {children}
  </ErrorBoundary>
)

export default ErrorBoundaryCard
