import { useSearchParams } from 'react-router-dom'

export function useReviewESignatureLetterModal() {
  const [searchParams, setSearchParams] = useSearchParams()

  function showModal() {
    searchParams.set(
      REVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isReviewingESignatureLetter,
      'true',
    )
    setSearchParams(searchParams)
  }

  function hideModal() {
    searchParams.delete(
      REVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isReviewingESignatureLetter,
    )
    setSearchParams(searchParams)
  }

  return {
    isShowingModal: searchParams.has(
      REVIEW_ESIGNATURE_LETTER_MODAL_PARAMS.isReviewingESignatureLetter,
    ),
    showModal,
    hideModal,
  }
}

export const REVIEW_ESIGNATURE_LETTER_MODAL_PARAMS = {
  isReviewingESignatureLetter: 'isReviewingESignatureLetter',
}
