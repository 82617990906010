import React from 'react'

import { FEE_SHARING_TYPE } from '../../../../lib/constants'
import { formatMoney } from '../../../../lib/helpers/helperFunctions'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeeShareValue: React.FC<Props> = ({ serviceFee }) => {
  const { shareValue, shareType } = serviceFee

  if (typeof shareValue !== 'number') {
    return ''
  }

  return shareType === FEE_SHARING_TYPE.setAmount
    ? formatMoney(shareValue)
    : `${shareValue}%`
}

export default ServiceFeeShareValue
