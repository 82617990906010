import React from 'react'

import CompanyReviews from '../../../CompanyReviews/CompanyReviews'
import TabTitle from '../../components/TabTitle'

interface Props {
  companyId: number
}

export const NUM_REVIEWS_PER_PAGE = 3

const Reviews: React.FC<Props> = ({ companyId }) => (
  <>
    <CompanyReviews
      companyId={companyId}
      render={({ data, renderReviews }) => (
        <>
          <TabTitle>
            Reviews from professionals ({data['hydra:totalItems']})
          </TabTitle>

          {renderReviews()}
        </>
      )}
    />
  </>
)

export default Reviews
