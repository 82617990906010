import { SerializableFile } from '../../../types/misc'

export interface ReviewCommercialAgreementFormValues {
  existingAgreementFile: SerializableFile | null
  clientConsentLetterApprovedAt?: string
  clientConsentLetterAmendRequest?: string
  isRqHandleFeeSharing?: boolean
}

export const REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS: Record<
  keyof ReviewCommercialAgreementFormValues,
  keyof ReviewCommercialAgreementFormValues
> = {
  existingAgreementFile: 'existingAgreementFile',
  clientConsentLetterApprovedAt: 'clientConsentLetterApprovedAt',
  clientConsentLetterAmendRequest: 'clientConsentLetterAmendRequest',
  isRqHandleFeeSharing: 'isRqHandleFeeSharing',
}
