import { useField, useFormikContext } from 'formik'
import React from 'react'

import { makeFileSerializable } from '../../lib/helpers/helperFunctions'
import FieldError from './FieldError'
import FieldLabel from './FieldLabel'
import FilePicker, { FilePickerProps } from './FilePicker'

type Props<FormValues> = FilePickerProps & {
  name: keyof FormValues
  label?: string
  renderFileSizeError?: (fileSizeLimitMb: number) => string
}

function FilePickerInput<FormValues>({
  label,
  name,
  renderFileSizeError,
  ...filePickerProps
}: Props<FormValues>) {
  const formField = name as string
  const id = `field_${formField}`
  const [, { error, touched }] = useField(formField)
  const { setFieldValue, setFieldTouched, setFieldError } =
    useFormikContext<FormValues>()
  const showError = !!(touched && error)

  async function handleAttachmentChange(file: File | null) {
    setFieldTouched(formField)
    setFieldValue(formField, await makeFileSerializable(file))
  }

  return (
    <>
      {label && (
        <FieldLabel htmlFor={id} className="mb-1">
          {label}
        </FieldLabel>
      )}

      <FilePicker
        {...filePickerProps}
        key={filePickerProps.defaultSelectedFile?.url}
        onChange={async (file) => {
          await handleAttachmentChange(file)
        }}
        onFileSizeError={(limitInMb) => {
          setFieldTouched(formField, true, false)

          setFieldError(
            formField,
            renderFileSizeError
              ? renderFileSizeError(limitInMb)
              : `Please select a file that's no larger than ${limitInMb}MB.`,
          )
        }}
      />

      {showError && <FieldError inputId={id}>{error}</FieldError>}
    </>
  )
}

export default FilePickerInput
