import React from 'react'
import { Accordion } from 'react-bootstrap'

import { FcaService } from '../../../../../../types/misc'
import TabTitle from '../../../components/TabTitle'

interface Props {
  services: FcaService[]
}

const FcaServices: React.FC<Props> = ({ services }) => (
  <>
    <TabTitle>Services offered</TabTitle>

    <Accordion defaultActiveKey="0" className="accordion--white">
      {services.map((service, index) => (
        <Accordion.Item
          key={'ap-' + index}
          eventKey={index.toString()}
          className="bg-white"
        >
          <Accordion.Header>{service.category}</Accordion.Header>

          <Accordion.Body>
            {service.permissions.map((permission, index) => (
              <p key={'api-' + index}>{permission}</p>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  </>
)

export default FcaServices
