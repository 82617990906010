import { useMutation } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import ms from 'ms'
import React from 'react'
import { Button } from 'react-bootstrap'
import * as yup from 'yup'

import useNotyf from '../../../../hooks/useNotyf'
import { scrollToFirstError } from '../../../../lib/helpers/formHelpers'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import { CommercialAgreementItem } from '../../../../types/responses/commercial-agreements'
import FormGroup from '../../../form/FormGroup'
import TextareaInput from '../../../form/TextareaInput'
import FormikSubmitErrorListener from '../../../utils/FormikSubmitErrorListener'
import ActionButton from '../../ActionButton'

interface AmendAgreementInput {
  text: string
}

const AmendAgreementInputFields: Record<
  keyof AmendAgreementInput,
  keyof AmendAgreementInput
> = {
  text: 'text',
}

interface Props {
  agreement: CommercialAgreementItem
  onAmend: () => void
  onCancel: () => void
}

const AmendAgreementForm: React.FC<Props> = ({
  agreement,
  onAmend,
  onCancel,
}) => {
  const notyf = useNotyf()

  const validationSchema = yup.object({
    [AmendAgreementInputFields.text]: yup
      .string()
      .required('Please provide some details'),
  })

  const amendAgreementMutation = useMutation({
    mutationFn: (text: string) =>
      commercialAgreementService.amendAgreement(agreement.id, text),
    onSuccess: () => {
      notyf.success({
        message: 'Amendment request successfully submitted',
        duration: ms('6s'),
      })
      onAmend()
    },
    onError: () => {
      notyf.error('Failed to submit amendment request')
    },
  })

  const isProcessing = amendAgreementMutation.isPending

  return (
    <section className="mt-5">
      <h4>Amend agreement</h4>

      <Formik
        initialValues={{ [AmendAgreementInputFields.text]: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => amendAgreementMutation.mutate(values.text)}
      >
        <Form>
          <FormikSubmitErrorListener onError={scrollToFirstError} />

          <FormGroup>
            <TextareaInput
              label="Please detail the changes you would like to make to this commercial agreement:"
              rows={4}
              name="text"
              autoFocus
              isDisabled={isProcessing}
            />
          </FormGroup>

          <div className="d-flex flex-column flex-md-row justify-content-md-end">
            <ActionButton
              className="order-md-last mb-2 mb-md-0"
              isProcessing={isProcessing}
              type="submit"
              variant="success"
            >
              Submit amend request
            </ActionButton>

            <Button
              disabled={isProcessing}
              variant="outline-secondary"
              className="me-md-2 order-md-first"
              onClick={onCancel}
            >
              Cancel amend
            </Button>
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default AmendAgreementForm
