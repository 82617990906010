import React from 'react'

import { SERVICE_FEE_SHARE_TYPE } from '../../../../lib/constants'
import { getOrFail } from '../../../../lib/helpers/helperFunctions'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeeShareType: React.FC<Props> = ({ serviceFee }) => {
  if (!serviceFee.shareType) {
    return ''
  }

  const shareType = Object.entries(SERVICE_FEE_SHARE_TYPE).find(
    ([_, shareType]) => {
      return shareType.value === serviceFee.shareType
    },
  )

  return getOrFail(shareType?.[1].label, 'Share type not found')
}

export default ServiceFeeShareType
