import { faFilePdf, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import { PANEL_STATUS } from '../../../../../lib/constants'
import { getFrnTradingNameCode } from '../../../../../lib/helpers/frnHelpers'
import { ROUTES } from '../../../../../lib/routes'
import panelService from '../../../../../lib/services/panelService'
import routeService from '../../../../../lib/services/routeService'
import {
  proceedWithEnqueuedCompanies,
  setEnqueuedCompanies,
} from '../../../../../redux/slices/panelInvite/panelInvite'
import {
  REFER_CLIENT_STEP,
  REFERRAL_METHOD,
} from '../../../../../redux/slices/referClient'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import ReferClientButton from '../../../ReferClientButton'
import ContactCompanyDropdown from './ContactCompanyDropdown'
import PendingActionNote from './PendingActionNote'

interface Props {
  companyProfile: CompanyProfileItem
}

const CompanyActions: React.FC<Props> = ({ companyProfile }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const reportSrc =
    companyProfile.company &&
    companyProfile.company.companyDetail.ifa?.reportUrl
  const { fca, company, panel } = companyProfile
  const panelStatus = panel?.status
  const isCompanyAlreadyInPanel = !!panelStatus

  const panelStatusStyles = panelStatus
    ? panelService.getPanelStatusStyles({
        panelStatus,
        textType: 'long',
        pendingActions: panel.pendingActions,
      })
    : undefined

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  function handleInviteCompany(fca: NonNullable<CompanyProfileItem['fca']>) {
    navigate(ROUTES.addExistingAdviser)
    dispatch(setEnqueuedCompanies([fca]))
    dispatch(proceedWithEnqueuedCompanies())
  }

  const isFrnTradingNameCodeSame =
    !!fca &&
    getFrnTradingNameCode(
      currentCompany.frn,
      currentCompany.frnTradingNameId,
    ) === getFrnTradingNameCode(fca?.frn, fca?.frnTradingNameId)

  const isViewingOwnCompany =
    currentCompany.id === companyProfile?.company?.id ||
    isFrnTradingNameCodeSame

  const companyIri = companyProfile?.company?.['@id']

  const shouldShowInviteButtons =
    !isViewingOwnCompany && !isCompanyAlreadyInPanel && !!fca

  function renderPanelStatusNote() {
    if (panel && panel.pendingActions.length > 0) {
      return (
        <PendingActionNote
          panel={panel}
          pendingActions={panel.pendingActions}
        />
      )
    }

    if (panelStatusStyles) {
      return (
        <div className="d-flex flex-column gap-2">
          <div
            className={classNames(
              'd-flex align-items-center text-lg',
              panelStatusStyles.textClass,
            )}
            style={{ minWidth: '120px' }}
          >
            {panelStatusStyles.icon}
            {panelStatusStyles.text}
          </div>
        </div>
      )
    }

    return <div /> // Empty div needed to keep layout consistent
  }

  return (
    <div className="d-flex flex-column flex-md-row flex-wrap flex-grow-1 justify-content-md-between align-items-md-center gap-2 gap-md-3">
      {/* Left side */}
      {renderPanelStatusNote()}

      {/* Right side*/}
      <div className="d-flex flex-column flex-sm-row gap-2">
        {!isViewingOwnCompany && company && (
          <>
            {panelStatus === PANEL_STATUS.approved && companyIri && (
              <ReferClientButton
                size="lg"
                variant="primary"
                toCompanyIri={companyIri}
                onCanRefer={() => {
                  navigate(
                    routeService.referClient({
                      referralMethod: REFERRAL_METHOD.company,
                      referralFirmId: company.id,
                      step: REFER_CLIENT_STEP.selectServiceOrAdviser,
                    }),
                  )
                }}
              >
                Refer a client
              </ReferClientButton>
            )}

            <ContactCompanyDropdown company={companyProfile?.company} />
          </>
        )}

        {/* Add to partners / invite buttons */}
        {shouldShowInviteButtons && (
          <Button
            className="btn-lg"
            variant="primary"
            onClick={() => {
              handleInviteCompany(fca)
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2 align-middle" />
            {companyProfile.isRegistered
              ? 'Add to professional network'
              : 'Invite to join RQ'}
          </Button>
        )}

        {/* Report button */}
        {reportSrc && (
          <Button
            className="btn-lg bg-black"
            variant="secondary"
            href={reportSrc}
            target="_blank"
          >
            <FontAwesomeIcon icon={faFilePdf} className="me-2 align-middle" />
            Due Diligence Report
          </Button>
        )}
      </div>
    </div>
  )
}

export default CompanyActions
