import React, { useEffect, useRef, useState } from 'react'

import {
  isBottomOfDomElementVisible,
  isElementOverflowingHorizontally,
} from '../../lib/helpers/helperFunctions'

interface Props {
  elementContainerRef: React.RefObject<HTMLDivElement>
  elementRef: React.RefObject<HTMLElement>
}

// Adds a sticky horizontal scrollbar to the bottom of the element container
// when the bottom of the element container is not visible.
// See below link for more context:
// https://rqratings.atlassian.net/jira/software/projects/RQ/boards/1?selectedIssue=RQ-903
const VirtualScrollbar: React.FC<Props> = ({
  elementContainerRef,
  elementRef,
}) => {
  const elementContainer = elementContainerRef.current
  const element = elementRef.current
  const virtualScrollbarContainerRef = useRef<HTMLDivElement | null>(null)
  const virtualScrollbarRef = useRef<HTMLDivElement | null>(null)
  const elementContainerWidth = elementContainer?.clientWidth || 0
  const elementWidth = element?.clientWidth || 0
  const [isBottomOfElementVisible, setIsBottomOfElementVisible] = useState(
    () => {
      return !!elementContainer && isBottomOfDomElementVisible(elementContainer)
    },
  )

  const isElementContainerOverflowing =
    !!elementContainer && isElementOverflowingHorizontally(elementContainer)

  // Keep the real scrollbar in sync with the virtual scrollbar
  useEffect(
    function keepScrollbarsInSync() {
      const virtualScrollbarContainer = virtualScrollbarContainerRef.current
      const virtualScrollBarElement = virtualScrollbarRef.current

      if (
        !elementContainer ||
        !element ||
        !virtualScrollbarContainer ||
        !virtualScrollBarElement
      ) {
        return
      }

      const keepVirtualScrollbarInSync = () => {
        virtualScrollbarContainer.scrollLeft = elementContainer.scrollLeft
      }

      const keepRealScrollbarInSync = () => {
        elementContainer.scrollLeft = virtualScrollbarContainer.scrollLeft
      }

      elementContainer.addEventListener('scroll', keepVirtualScrollbarInSync)
      virtualScrollbarContainer.addEventListener(
        'scroll',
        keepRealScrollbarInSync,
      )

      // Clean up by removing the event listeners
      return () => {
        elementContainer.removeEventListener(
          'scroll',
          keepVirtualScrollbarInSync,
        )
        virtualScrollbarContainer.removeEventListener(
          'scroll',
          keepRealScrollbarInSync,
        )
      }
    },
    [element, elementContainer],
  )

  // Keep track of whether the bottom of the element container is visible
  // so that we hide the virtual scrollbar when it is visible.
  useEffect(() => {
    if (!elementContainer) {
      return
    }

    document.addEventListener('scroll', () => {
      setIsBottomOfElementVisible(isBottomOfDomElementVisible(elementContainer))
    })
  }, [elementContainer])

  // We only want to show the virtual scrollbar when both:
  // 1. The element container is horizontally overflowing
  // 2. The bottom of the element container is not visible
  const shouldShow = isElementContainerOverflowing && !isBottomOfElementVisible

  return (
    <div
      ref={virtualScrollbarContainerRef}
      className="position-fixed bottom-0 overflow-x-scroll overflow-y-hidden VirtualScrollbar"
      style={{
        zIndex: 1000,
        width: elementContainerWidth,
        display: shouldShow ? 'block' : 'none',
      }}
    >
      <div
        style={{ border: '1px solid transparent', width: elementWidth }}
        ref={virtualScrollbarRef}
      ></div>
    </div>
  )
}

export default VirtualScrollbar
