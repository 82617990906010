import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import serviceFeeService from '../../../lib/services/serviceFeeService'
import { CommercialAgreementTypeOption } from '../../../redux/slices/commercialAgreementsForm'
import { ServiceFeeDetail } from './ServiceFeesTableRows'

interface Props {
  serviceFees: ServiceFeeDetail[]
  showActionsColumn?: boolean
  isRecommendedPartnerTerms?: boolean
  showAllColumns: boolean
  agreementType: CommercialAgreementTypeOption
}

export interface ServiceFeeTableHeading {
  label: string
  className?: string
  style: CSSProperties
}

const ServiceFeesTableHeaders: React.FC<Props> = ({
  serviceFees,
  showActionsColumn = false,
  isRecommendedPartnerTerms,
  showAllColumns,
  agreementType,
}) => {
  serviceFeeService.validateServiceFeesOrFail(serviceFees)

  const tableHeaders = serviceFeeService.getTableHeaders({
    serviceFees,
    showActionsColumn,
    showAllColumns,
    agreementType,
    isRecommendedPartnerTerms,
  })

  return (
    <tr>
      {tableHeaders.map((header, index) => (
        <th
          key={index}
          className={classNames('font-weight-500 align-top', header.className)}
          style={header.style}
        >
          {header.label}
        </th>
      ))}
    </tr>
  )
}

export default ServiceFeesTableHeaders
