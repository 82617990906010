import React from 'react'

import {
  COMPANY_TYPE_CODE,
  FCA_ACCREDITATIONS,
} from '../../../../../lib/constants'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import FeatureBadge from '../../../FeatureBadge'
import SectionHeading from './SectionHeading'

interface Props {
  companyProfile: CompanyProfileItem
  badgesClassName: string
}

const Accreditations: React.FC<Props> = ({
  companyProfile,
  badgesClassName,
}) => {
  const { fca } = companyProfile

  const isTradingName = !!fca?.frnTradingNameId

  const accreditations = fca?.accreditations.filter((accr) =>
    FCA_ACCREDITATIONS.some((accreditation) => accreditation.code === accr),
  )

  const isRegisteredAsIfa =
    companyProfile.company?.companyType.code === COMPANY_TYPE_CODE.ifa

  if (
    !fca ||
    !fca.frn ||
    !isRegisteredAsIfa ||
    isTradingName ||
    !accreditations
  ) {
    return null
  }

  return (
    <div className="pb-2">
      <SectionHeading className="mb-2 pb-1">
        Accreditations ({accreditations.length}/{FCA_ACCREDITATIONS.length})
      </SectionHeading>

      <div className={badgesClassName}>
        {FCA_ACCREDITATIONS.map((accreditation) => (
          <FeatureBadge
            key={accreditation.code}
            enabled={accreditations.includes(accreditation.code)}
          >
            {accreditation.name}
          </FeatureBadge>
        ))}
      </div>
    </div>
  )
}

export default Accreditations
