import { selectCommercialAgreementsMetadata } from '../redux/slices/commercialAgreementsMetadata'
import useAppSelector from './useAppSelector'

export function useCommercialAgreementMetadata() {
  return useAppSelector(selectCommercialAgreementsMetadata)
}

export function useCommercialAgreementMetadataOrFail() {
  const metadata = useCommercialAgreementMetadata()

  if (!metadata) {
    throw new Error('Expected commercial agreement metadata to be set')
  }

  return metadata
}
