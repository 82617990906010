import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCompanyProfileSearchParams } from '../../../../hooks/useCompanyProfileSearchParams'
import { useIsRoute } from '../../../../hooks/useIsRoute'
import { ROUTES } from '../../../../lib/routes'
import routeService from '../../../../lib/services/routeService'
import PageActions, { PageAction } from '../../../utils/PageActions'

const DevButtons: React.FC = () => {
  const isRoute = useIsRoute()
  const actions: PageAction[] = []
  const navigate = useNavigate()
  const { searchParams } = useCompanyProfileSearchParams()

  if (isRoute(ROUTES.companyProfile)) {
    actions.push({
      label: 'View profile modal',
      action: () => {
        navigate(
          routeService.referralPartners({
            identifier: searchParams.identifier,
            identifierType: searchParams.identifierType,
          }),
        )
      },
    })
  }

  if (isRoute(ROUTES.professionalNetworkRelationships)) {
    actions.push({
      label: 'View profile page',
      action: () => {
        navigate(
          routeService.companyProfile({
            identifier: searchParams.identifier,
            identifierType: searchParams.identifierType,
          }),
        )
      },
    })
  }

  return <PageActions actions={actions} />
}

export default DevButtons
