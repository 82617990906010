import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { Badge } from 'react-bootstrap'

interface Props {
  children: React.ReactNode
  enabled?: boolean
  className?: string
}

const FeatureBadge: React.FC<Props> = ({
  children,
  enabled = false,
  className,
}) => {
  const _className = classNames([
    'py-2 px-3 d-flex-inline',
    enabled ? 'text-white' : 'text-muted',
    className,
  ])

  return (
    <Badge pill bg={enabled ? 'success' : 'light'} className={_className}>
      {enabled ? (
        <FontAwesomeIcon icon={faCheck} className="me-2" />
      ) : (
        <FontAwesomeIcon icon={faBan} className="me-2" />
      )}
      <span>{children}</span>
    </Badge>
  )
}

export default FeatureBadge
