import { ErrorBoundary } from '@sentry/react'
import React, { Suspense } from 'react'
import { Offcanvas } from 'react-bootstrap'

import { useIsMinWidthMd } from '../../hooks/useIsMinWidthMd'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import {
  CommercialAgreementReferralDirection,
  ReferralType,
} from '../../types/misc'
import { CompanyProfileSkeleton } from '../misc/CompanyProfile/CompanyProfile'
import DetailsNotFound from '../misc/CompanyProfile/components/DetailsNotFound'
import ReferralESignaturePreview from '../misc/ReferralESignaturePreview'
import { ServiceFeeDetail } from '../misc/ServiceFeesTable/ServiceFeesTableRows'

export interface ESignatureLetterModalProps {
  isShowing: boolean
  onHide: () => void
  otherCompany: {
    presentationName: string
    id: number
  }
  referralDirection: CommercialAgreementReferralDirection
  title: string
  agreementType?: CommercialAgreementTypeOption
  serviceFees: ServiceFeeDetail[]
  renderHeader?: (props: RenderHeaderProps) => React.ReactNode
  commissionJustification?: string
  onEditCommissionJustification?: (commissionJustification: string) => void
  serviceAreaIds?: number[]
  referralType?: ReferralType
}

interface RenderHeaderProps {
  commissionJustification?: string
}

const ReviewESignatureLetterModal: React.FC<ESignatureLetterModalProps> = ({
  isShowing,
  onHide,
  otherCompany,
  referralDirection,
  serviceFees,
  title,
  renderHeader,
  agreementType,
  commissionJustification,
  onEditCommissionJustification,
  serviceAreaIds = [],
  referralType,
}) => {
  const isMinWidthMd = useIsMinWidthMd()

  return (
    <Offcanvas
      show={isShowing}
      placement="end"
      onHide={onHide}
      style={{ width: isMinWidthMd ? '880px' : '100%' }}
    >
      <Offcanvas.Header closeButton className="pe-4">
        <Offcanvas.Title style={{ marginLeft: '5mm' }}>{title}</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="pt-0 pb-5">
        <ErrorBoundary fallback={<DetailsNotFound className="px-4" />}>
          <Suspense fallback={<CompanyProfileSkeleton />}>
            <ReferralESignaturePreview
              otherCompanyId={otherCompany.id}
              referralDirection={referralDirection}
              serviceFees={serviceFees}
              agreementType={agreementType}
              commissionJustification={commissionJustification}
              onEditCommissionJustification={onEditCommissionJustification}
              serviceAreaIds={serviceAreaIds}
              referralType={referralType}
              render={({
                renderPreview,
                commissionJustification: newCommissionJustification,
              }) => (
                <>
                  <section
                    style={{
                      margin: '10px 5mm 0', // 5mm matches the horizontal margin set by the PDF preview
                    }}
                  >
                    {renderHeader &&
                      renderHeader({
                        commissionJustification: newCommissionJustification,
                      })}
                  </section>

                  <>{renderPreview()}</>
                </>
              )}
            />
          </Suspense>
        </ErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default ReviewESignatureLetterModal
