import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'

import {
  COMPANY_TYPE_CODE,
  REFERRAL_TYPE,
  REFERRAL_TYPE_OPTION,
  SERVICE_AREA_ID_WILL,
} from '../../lib/constants'
import { getNextStep, getPreviousStep } from '../../lib/helpers/helperFunctions'
import { getQueryParam } from '../../lib/helpers/routeHelpers'
import companyTypeService from '../../lib/services/companyTypeService'
import referClientService from '../../lib/services/referClientService'
import { REFER_CLIENT_SEARCH_PARAMS } from '../../lib/services/routeService'
import { ClientDetailsInput } from '../../pages/referClient/steps/5_ClientDetails/constants'
import { buildInitialValues } from '../../pages/referClient/steps/5_ClientDetails/helpers'
import { UserEntity } from '../../types/entities'
import {
  CompanyTypeCode,
  ReferralTypeOption,
  SerializableFile,
} from '../../types/misc'
import { ClientItem } from '../../types/responses/clients'
import { CompanyDetailsItem } from '../../types/responses/companies'
import { CompassReportCollectionItem } from '../../types/responses/compass-reports'
import { NewReferralMetadataItem } from '../../types/responses/referrals'
import { RootState } from '../store'
import {
  getServiceAreasByIds,
  selectCompanyTypes,
  selectServiceAreas,
} from './commonData'
import { selectCurrentCompanyOrFail } from './session'

export interface ReferClientState {
  currentStep: ReferClientStep
  metadata?: NewReferralMetadataItem
  companyTypeCode?: CompanyTypeCode
  referralMethod?: ReferralMethod
  referralFirm?: CompanyDetailsItem
  referralFirmIndividuals: ReferralFirmIndividual[]
  referralFirmHasMultipleIndividuals: boolean
  serviceAreaIds: number[]
  client?: ClientItem
  clientDetails?: ClientDetailsInput
  compassReport?: CompassReportCollectionItem
  consentType?: ReferralConsentType
  referralType?: ReferralTypeOption
  userEmailMessage?: string
  attachment?: SerializableFile | null
  requestCallbackForm?: {
    callbackTimes?: string[]
    callbackTelephone?: string
  }
  commissionJustification?: string
  hasEditedCommissionJustification?: boolean
  hasSentReviewAgreementReminder: boolean
  isShowingRequestApprovalModal?: boolean
  approvalRequestMessage?: string
  numAvailableReferralFirms: number
}

export type ReferralMethod =
  | 'individual'
  | 'service'
  | 'company'
  | 'professionalCategory'

export const REFERRAL_METHOD: Record<ReferralMethod, ReferralMethod> = {
  individual: 'individual',
  service: 'service',
  company: 'company',
  professionalCategory: 'professionalCategory',
}

export type ReferralFirmIndividual = Pick<UserEntity, 'email' | 'fullName'> & {
  userId: number
  companyUserId: number
}

export type ReferClientStep =
  | 'selectReferralMethod'
  | 'selectServiceOrAdviser'
  | 'clientConsent'
  | 'clientDetails'
  | 'commercialAgreement'
  | 'confirmReferral'
  | 'confirmPcoReferral'

export const REFER_CLIENT_STEP: Record<ReferClientStep, ReferClientStep> = {
  selectReferralMethod: 'selectReferralMethod',
  selectServiceOrAdviser: 'selectServiceOrAdviser',
  commercialAgreement: 'commercialAgreement',
  clientConsent: 'clientConsent',
  clientDetails: 'clientDetails',
  confirmReferral: 'confirmReferral',
  confirmPcoReferral: 'confirmPcoReferral',
}

export type ReferralConsentType =
  | 'YES_E-SIGNATURE'
  | 'NO_CLIENT_LETTER_SIGNED'
  | 'NO_ADVISOR_WILL_SHARE'
  | 'NA'

export const REFERRAL_CONSENT_TYPE = {
  yesESignature: 'YES_E-SIGNATURE',
  noClientLetterAlreadySigned: 'NO_CLIENT_LETTER_SIGNED',
  noAdviserWillShare: 'NO_ADVISOR_WILL_SHARE',
  na: 'NA',
} satisfies Record<string, ReferralConsentType>

const INITIAL_STATE: ReferClientState = {
  currentStep: REFER_CLIENT_STEP.selectReferralMethod,
  referralFirmIndividuals: [],
  referralFirmHasMultipleIndividuals: false,
  serviceAreaIds: [],
  hasSentReviewAgreementReminder: false,
  numAvailableReferralFirms: 0,
}

const sliceName = 'referClient'

export function buildInitialReferClientState(): ReferClientState {
  return {
    ...INITIAL_STATE,

    referralMethod: getQueryParam<ReferralMethod | undefined>({
      name: REFER_CLIENT_SEARCH_PARAMS.referralMethod,
      transform: (value) =>
        referClientService.isValidReferralTarget(value) ? value : undefined,
    }),
  }
}

const referClient = createSlice({
  name: sliceName,
  initialState: buildInitialReferClientState,
  reducers: {
    initialiseReferClient() {
      return buildInitialReferClientState()
    },

    handReferralMethodChange(state, action: PayloadAction<ReferralMethod>) {
      return {
        ...buildInitialReferClientState(),

        referralMethod: action.payload,

        // Don't clear the client as we sometimes want to pre-select it based on
        // the `clientId` search param
        client: state.client,
        clientDetails: state.clientDetails,

        // Don't clear the compass report as we sometimes want to pre-select it
        // based on the `shouldAttachLatestCompassReport` search param
        compassReport: state.compassReport,
      }
    },

    goToStep(state, action: PayloadAction<ReferClientStep>) {
      state.currentStep = action.payload
    },

    setReferralMethod(
      state,
      action: PayloadAction<ReferClientState['referralMethod']>,
    ) {
      state.referralMethod = action.payload
    },

    setReferClientMetadata: (
      state,
      action: PayloadAction<ReferClientState['metadata']>,
    ) => {
      state.metadata = action.payload
    },

    setCompanyTypeCode: (
      state,
      action: PayloadAction<ReferClientState['companyTypeCode']>,
    ) => {
      state.companyTypeCode = action.payload
    },

    setNumAvailableReferralFirms: (
      state,
      action: PayloadAction<ReferClientState['numAvailableReferralFirms']>,
    ) => {
      state.numAvailableReferralFirms = action.payload
    },

    setReferralFirm: (
      state,
      action: PayloadAction<ReferClientState['referralFirm']>,
    ) => {
      const previousReferralFirmId = state.referralFirm?.id
      const newReferralFirmId = action.payload?.id

      const hasClearedFirm = !newReferralFirmId
      const hasChangedFirm =
        newReferralFirmId &&
        previousReferralFirmId &&
        newReferralFirmId !== previousReferralFirmId

      // Clear any state that will become invalid when the referral firm changes
      if (hasClearedFirm || hasChangedFirm) {
        state.metadata = INITIAL_STATE.metadata
        state.referralFirmIndividuals = INITIAL_STATE.referralFirmIndividuals
        state.referralFirmHasMultipleIndividuals =
          INITIAL_STATE.referralFirmHasMultipleIndividuals
        state.consentType = INITIAL_STATE.consentType
        state.referralType = INITIAL_STATE.referralType
        state.hasSentReviewAgreementReminder =
          INITIAL_STATE.hasSentReviewAgreementReminder

        if (state.clientDetails) {
          state.clientDetails = referClientService.clearCommissionQuestions(
            state.clientDetails,
          )
        }
      }

      state.referralFirm = action.payload
    },

    setReferralFirmIndividuals: (
      state,
      action: PayloadAction<ReferClientState['referralFirmIndividuals']>,
    ) => {
      state.referralFirmIndividuals = action.payload
    },

    setReferralFirmHasMultipleIndividuals: (
      state,
      action: PayloadAction<
        ReferClientState['referralFirmHasMultipleIndividuals']
      >,
    ) => {
      state.referralFirmHasMultipleIndividuals = action.payload
    },

    setServiceAreaIds: (
      state,
      action: PayloadAction<ReferClientState['serviceAreaIds']>,
    ) => {
      state.serviceAreaIds = action.payload
    },

    setClientDetails: (
      state,
      action: PayloadAction<ReferClientState['clientDetails']>,
    ) => {
      state.clientDetails = action.payload
    },

    setClientCircumstances: (state, action: PayloadAction<string>) => {
      if (!state.clientDetails) {
        throw new Error(
          'Tried setting client circumstances without state.clientDetails being set',
        )
      }

      state.clientDetails.circumstances = action.payload
    },

    setClient: (state, action: PayloadAction<ClientItem>) => {
      const previousClient = state.client
      const selectedClient = action.payload

      state.client = selectedClient

      state.clientDetails = {
        ...state.clientDetails,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        telephone: selectedClient.telephone || '',
        clientId: selectedClient.clientId || '',
        circumstances: state.clientDetails?.circumstances || '',
      }

      // Clear Compass report if the client has changed
      if (previousClient && selectedClient.id !== previousClient.id) {
        state.compassReport = undefined
      }
    },

    clearClient: (state) => {
      state.client = undefined
      state.compassReport = INITIAL_STATE.compassReport
      state.clientDetails = {
        ...buildInitialValues(INITIAL_STATE.clientDetails),
        circumstances: state.clientDetails?.circumstances || '', // Preserve circumstances
      }
    },

    setCompassReport: (
      state,
      action: PayloadAction<ReferClientState['compassReport']>,
    ) => {
      state.compassReport = action.payload
    },

    clearCompassReportId: (state) => {
      state.compassReport = undefined
    },

    clearCommissionQuestions: (state) => {
      if (state.clientDetails) {
        state.clientDetails = referClientService.clearCommissionQuestions(
          state.clientDetails,
        )
      }
    },

    setConsentType: (
      state,
      action: PayloadAction<ReferClientState['consentType']>,
    ) => {
      state.consentType = action.payload
    },

    setReferralType: (
      state,
      action: PayloadAction<ReferClientState['referralType']>,
    ) => {
      state.referralType = action.payload
    },

    clearReferralType(state) {
      state.referralType = INITIAL_STATE.referralType
    },

    setUserEmailMessage: (
      state,
      action: PayloadAction<ReferClientState['userEmailMessage']>,
    ) => {
      state.userEmailMessage = action.payload
    },

    setApprovalRequestMessage: (
      state,
      action: PayloadAction<ReferClientState['approvalRequestMessage']>,
    ) => {
      state.approvalRequestMessage = action.payload
    },

    setAttachment: (
      state,
      action: PayloadAction<ReferClientState['attachment']>,
    ) => {
      state.attachment = action.payload
    },

    setRequestCallbackForm: (
      state,
      action: PayloadAction<ReferClientState['requestCallbackForm']>,
    ) => {
      state.requestCallbackForm = action.payload
    },

    setHasSentReviewAgreementReminder: (
      state,
      action: PayloadAction<ReferClientState['hasSentReviewAgreementReminder']>,
    ) => {
      state.hasSentReviewAgreementReminder = action.payload
    },

    setCommissionJustification: (
      state,
      action: PayloadAction<ReferClientState['commissionJustification']>,
    ) => {
      state.commissionJustification = action.payload
    },

    setHasEditedCommissionJustification: (
      state,
      action: PayloadAction<
        ReferClientState['hasEditedCommissionJustification']
      >,
    ) => {
      state.hasEditedCommissionJustification = action.payload
    },

    showRequestApprovalModal: (state) => {
      state.isShowingRequestApprovalModal = true
    },

    hideRequestApprovalModal: (state) => {
      state.isShowingRequestApprovalModal = false
    },
  },
})

export const referClientReducer = referClient.reducer

export const {
  initialiseReferClient,
  handReferralMethodChange,
  goToStep,
  setReferralMethod,
  setReferClientMetadata,
  setCompanyTypeCode,
  setNumAvailableReferralFirms,
  setReferralFirm,
  setReferralFirmIndividuals,
  setReferralFirmHasMultipleIndividuals,
  setServiceAreaIds,
  setClient,
  setClientDetails,
  setClientCircumstances,
  clearClient,
  setCompassReport,
  clearCompassReportId,
  clearCommissionQuestions,
  setConsentType,
  setReferralType,
  clearReferralType,
  setUserEmailMessage,
  setAttachment,
  setRequestCallbackForm,
  setHasSentReviewAgreementReminder,
  setCommissionJustification,
  setHasEditedCommissionJustification,
  setApprovalRequestMessage,
  showRequestApprovalModal,
  hideRequestApprovalModal,
} = referClient.actions

export const removeReferralFirmSelection = createAsyncThunk(
  `${sliceName}/removeReferralFirmSelection`,
  async (_, { dispatch }) => {
    dispatch(setReferralFirm(undefined))

    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete(REFER_CLIENT_SEARCH_PARAMS.referralFirmId)
    window.history.pushState({}, '', url)
  },
)

const PCO_REFER_CLIENT_STEP_NAMES: ReferClientStep[] = [
  REFER_CLIENT_STEP.selectReferralMethod,
  REFER_CLIENT_STEP.selectServiceOrAdviser,
  REFER_CLIENT_STEP.clientDetails,
  REFER_CLIENT_STEP.confirmPcoReferral,
]

const NORMAL_REFER_CLIENT_STEP_NAMES: ReferClientStep[] = [
  REFER_CLIENT_STEP.selectReferralMethod,
  REFER_CLIENT_STEP.selectServiceOrAdviser,
  REFER_CLIENT_STEP.commercialAgreement,
  REFER_CLIENT_STEP.clientConsent,
  REFER_CLIENT_STEP.clientDetails,
  REFER_CLIENT_STEP.confirmReferral,
]

const INTERNAL_REFER_CLIENT_STEP_NAMES: ReferClientStep[] = [
  REFER_CLIENT_STEP.selectReferralMethod,
  REFER_CLIENT_STEP.selectServiceOrAdviser,
  REFER_CLIENT_STEP.commercialAgreement,
  REFER_CLIENT_STEP.clientDetails,
  REFER_CLIENT_STEP.confirmReferral,
]

export type ReferClientSteps = Array<keyof typeof REFER_CLIENT_STEP>

export const selectReferClient = (state: RootState) => state.referClient

export const selectReferralMethod = createSelector(
  selectReferClient,
  (state) => state.referralMethod,
)

export const selectReferralMethodOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.referralMethod, 'Expected referralMethod to be set')

    return state.referralMethod
  },
)

export const selectReferralFirm = createSelector(
  selectReferClient,
  (state) => state.referralFirm,
)

export const selectReferralFirmOrFail = createSelector(
  selectReferralFirm,
  (referralFirm) => {
    invariant(referralFirm, 'Expected referralFirm to be set')

    return referralFirm
  },
)

export const selectCurrentStep = createSelector(
  selectReferClient,
  (state) => state.currentStep,
)

export const selectServiceAreaIds = createSelector(
  selectReferClient,
  (state) => state.serviceAreaIds,
)

export const selectIsPcoReferral = createSelector(
  selectServiceAreaIds,
  selectReferralFirm,
  (serviceAreaIds, referralFirm): boolean => {
    return (
      serviceAreaIds.includes(SERVICE_AREA_ID_WILL) && !!referralFirm?.isPco
    )
  },
)

export const selectReferralFirmHasMultipleIndividuals = createSelector(
  selectReferClient,
  (state) => {
    return state.referralFirmHasMultipleIndividuals
  },
)

export const selectReferralFirmHasSingleIndividual = createSelector(
  selectReferralFirmHasMultipleIndividuals,
  (hasMultipleIndividuals) => {
    return hasMultipleIndividuals === false
  },
)

export const selectShouldShowSelectFirmIndividuals = createSelector(
  selectReferralFirmHasSingleIndividual,
  selectReferralMethod,
  selectReferralFirm,
  selectServiceAreaIds,
  selectIsPcoReferral,
  (
    referralFirmHasSingleIndividual,
    referralMethod,
    referralFirm,
    serviceAreaIds,
    isPcoReferral,
  ) => {
    // If the referral firm has a single individual, we'll pre-select that
    // individual and not show the selection dropdown.
    if (referralFirmHasSingleIndividual) {
      return false
    }

    if (referralMethod === REFERRAL_METHOD.service) {
      return !!referralFirm && !isPcoReferral
    }

    return !!referralFirm && serviceAreaIds.length > 0 && !isPcoReferral
  },
)

export const selectReferClientMetadata = createSelector(
  selectReferClient,
  (state) => state.metadata,
)

export const selectReferClientMetadataOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.metadata, 'Expected metadata to be set')

    return state.metadata
  },
)

export const selectIsInternalReferral = createSelector(
  selectReferClientMetadata,
  (metadata) => !!metadata?.panel?.internalRelationship,
)

export const selectHasSingleAvailableReferralFirm = createSelector(
  selectReferClient,
  (state) => state.numAvailableReferralFirms === 1,
)

export const selectSteps = createSelector(
  selectIsPcoReferral,
  selectIsInternalReferral,
  selectHasSingleAvailableReferralFirm,
  (isPcoReferral, isInternalReferral, hasSingleAvailableReferralFirm) => {
    function getSteps() {
      if (isPcoReferral) {
        return PCO_REFER_CLIENT_STEP_NAMES
      }

      if (isInternalReferral) {
        return INTERNAL_REFER_CLIENT_STEP_NAMES
      }

      return NORMAL_REFER_CLIENT_STEP_NAMES
    }

    let steps = getSteps()

    if (hasSingleAvailableReferralFirm) {
      steps = steps.filter(
        (step) => step !== REFER_CLIENT_STEP.selectServiceOrAdviser,
      )
    }

    return steps
  },
)

export const selectNextStep = createSelector(
  selectSteps,
  selectCurrentStep,
  (steps, currentStep) => {
    return getNextStep<ReferClientSteps>(steps, currentStep)
  },
)

export const selectPreviousStep = createSelector(
  selectSteps,
  selectCurrentStep,
  (steps, currentStep) => {
    return getPreviousStep<ReferClientSteps>(steps, currentStep)
  },
)

export const selectCompanyTypeCode = createSelector(
  selectReferClient,
  (state) => state.companyTypeCode,
)

export const selectCompanyTypeCodeOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.companyTypeCode, 'Expected companyTypeCode to be set')

    return state.companyTypeCode
  },
)

export const selectSelectedCompanyType = createSelector(
  selectCompanyTypes,
  selectCompanyTypeCode,
  (companyTypes, selectedCompanyTypeCode) =>
    selectedCompanyTypeCode
      ? companyTypeService.getCompanyTypeByCodeOrFail(
          companyTypes,
          selectedCompanyTypeCode,
        )
      : null,
)

export const selectSelectedCompanyTypeOrFail = createSelector(
  selectSelectedCompanyType,
  (selectedCompanyType) => {
    invariant(selectedCompanyType, 'Expected selectedCompanyType to be set')

    return selectedCompanyType
  },
)

export const selectReadableCompanyType = createSelector(
  selectCompanyTypes,
  selectReferralFirm,
  (companyTypes, referralFirm) =>
    referralFirm
      ? companyTypeService.getCompanyTypeByCode(
          companyTypes,
          referralFirm.companyTypeCode,
        )?.name
      : 'Adviser',
)

export const selectShouldShowIfaColumnsInServiceFirmsTable = createSelector(
  selectCurrentCompanyOrFail,
  selectCompanyTypeCode,
  (currentCompany, companyTypeCode) => {
    return (
      // If the user explicitly selected 'Financial planner' as the company type
      // they want to refer to, always show the columns>
      companyTypeCode === COMPANY_TYPE_CODE.ifa ||
      //
      // If the user didn't select a specific company type but they have a
      // financial planner in their professional network, show the columns.
      (!companyTypeCode && currentCompany.hasIfaReferralPartner)
    )
  },
)

export const selectReferralFirmIndividuals = createSelector(
  selectReferClient,
  (state) => state.referralFirmIndividuals,
)

export const selectReferralFirmIndividualsOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(
      state.referralFirmIndividuals,
      'Expected referral firm individuals to be set',
    )

    return state.referralFirmIndividuals
  },
)

export const selectSelectedServiceAreas = createSelector(
  selectServiceAreas,
  selectServiceAreaIds,
  (serviceAreas, serviceAreaIds) => {
    return getServiceAreasByIds(serviceAreas, serviceAreaIds)
  },
)

export const selectSelectedServiceAreaLabels = createSelector(
  selectSelectedServiceAreas,
  selectCompanyTypes,
  (selectedServiceAreas, companyTypes) => {
    return selectedServiceAreas.map((serviceArea) => {
      const companyTypeLabel = serviceArea.companyTypes
        .map((serviceAreaCompanyType) => {
          const matchingCompanyType = companyTypes.find(
            (companyType) => companyType['@id'] === serviceAreaCompanyType,
          )

          invariant(
            matchingCompanyType,
            `No company type found with the IRI: ${serviceAreaCompanyType}`,
          )

          return matchingCompanyType.name
        })
        .join(', ')

      return companyTypeLabel
        ? `${serviceArea.name} (${companyTypeLabel})`
        : serviceArea.name
    })
  },
)

export const selectClient = createSelector(
  selectReferClient,
  (state) => state.client,
)

export const selectClientOrFail = createSelector(selectClient, (client) => {
  invariant(client, 'Expected client to be set')

  return client
})

export const selectClientDetails = createSelector(
  selectReferClient,
  (state) => state.clientDetails,
)

export const selectClientDetailsOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.clientDetails, 'Expected state.clientDetails to be set')

    return state.clientDetails
  },
)

export const selectCompassReport = createSelector(
  selectReferClient,
  (state) => state.compassReport,
)

export const selectConsentType = createSelector(
  selectReferClient,
  (state) => state.consentType,
)

export const selectReferralTypeOption = createSelector(
  selectReferClient,
  (state) => state.referralType,
)

export const selectIsNormalReferralType = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isNormalReferralType(referralTypeOption),
)

export const selectIsBookingCallForClient = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isBookCallForClientReferralType(referralTypeOption),
)

export const selectIsRequestingReferralCallbackForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && !metadata?.toCompany.scheduleClientCallUrl
  },
)

export const selectIsSchedulingMeetingForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && metadata?.toCompany.scheduleClientCallUrl
  },
)
export const selectNormalizedReferralType = createSelector(
  selectReferralTypeOption,
  (referralType) => referClientService.normalizeReferralType(referralType),
)

export const selectNormalizedReferralTypeOrFail = createSelector(
  selectNormalizedReferralType,
  (referralType) => {
    invariant(referralType, 'Expected referralType to be set')

    return referralType
  },
)

export const selectIsDirectReferral = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return (
      referralType === REFERRAL_TYPE.direct ||
      referralType === REFERRAL_TYPE.indirect
    )
  },
)

export const selectShouldCcClientOnInitialEmail = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return referralType === REFERRAL_TYPE_OPTION.directCc
  },
)

export const selectUserEmailMessage = createSelector(
  selectReferClient,
  (referClient) => referClient.userEmailMessage,
)

export const selectAttachment = createSelector(
  selectReferClient,
  (referClient) => referClient.attachment,
)

export const selectRequestCallbackForm = createSelector(
  selectReferClient,
  (referClient) => referClient.requestCallbackForm,
)

export const selectHasSentReviewAgreementReminder = createSelector(
  selectReferClient,
  (referClient) => referClient.hasSentReviewAgreementReminder,
)

export const selectCommissionJustification = createSelector(
  selectReferClient,
  (referClient) => referClient.commissionJustification,
)

export const selectHasEditedCommissionJustification = createSelector(
  selectReferClient,
  (referClient) => referClient.hasEditedCommissionJustification,
)

export const selectApprovalRequestMessage = createSelector(
  selectReferClient,
  (referClient) => referClient.approvalRequestMessage,
)

export const selectIsShowingRequestApprovalModal = createSelector(
  selectReferClient,
  (referClient) => referClient.isShowingRequestApprovalModal,
)
