import classNames from 'classnames'
import React from 'react'

import CompanyLogoOrName from '../../../components/misc/CompanyLogoOrName'
import { ReferralWithESignatureDetailsItem } from '../../../types/responses/referrals'

interface Props {
  referral: ReferralWithESignatureDetailsItem
  className?: string
}

const FromCompanyLogoOrName: React.FC<Props> = ({ referral, className }) => {
  const { fromCompany } = referral

  return (
    <CompanyLogoOrName
      name={fromCompany.presentationName}
      truncateName={false}
      wrapperProps={{
        className: classNames('font-weight-500 text-black', className),
        style: { fontSize: '28px', lineHeight: '1.5' },
      }}
      showLogoFallback={false}
      logoUrl={fromCompany.logoUrl}
      imageStyle={{ maxHeight: '85px', maxWidth: '240px' }}
    />
  )
}

export default FromCompanyLogoOrName
