import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import { getTextFromHtmlString } from '../../../../lib/helpers/helperFunctions'
import FormGroup from '../../../form/FormGroup'
import RichTextInput from '../../../form/RichTextInput'

interface Props {
  text?: string
  onSave: (text: string) => void
  onHide: () => void
}

interface FormValues {
  text: string
}

const FormFields: Record<keyof FormValues, keyof FormValues> = {
  text: 'text',
}

const EditCommissionJustificationModal: React.FC<Props> = ({
  text,
  onSave,
  onHide,
}) => {
  const initialValues: FormValues = {
    text: text || '',
  }

  const validationSchema = yup.object().shape({
    [FormFields.text]: yup
      .string()
      .test('required', 'Please enter something.', (value) => {
        const text = getTextFromHtmlString(value || '')

        return text.trim().length > 0
      }),
  })

  return (
    <Modal onHide={onHide} show>
      <Modal.Header closeButton>
        <Modal.Title>Edit text</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSave(values.text)}
      >
        {({ dirty }) => (
          <Form>
            <Modal.Body>
              <FormGroup>
                <RichTextInput
                  autoFocus
                  name={FormFields.text}
                  placeholder="Enter text..."
                />
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>
                Cancel
              </Button>

              <Button type="submit" disabled={!dirty}>
                Save changes
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditCommissionJustificationModal
