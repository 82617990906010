import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import reviewService from '../../../../lib/services/reviewService'
import { NUM_REVIEWS_PER_PAGE } from '../tabs/Reviews/Reviews'

function useInitialReviewsQuery(companyId?: number) {
  // No need to make this query if the firm is not registered on RQ.
  const shouldEnableQuery = !!companyId

  return useQuery({
    queryKey: ['reviews', { companyId, page: 1 }],
    queryFn: () => {
      invariant(companyId, 'Expected companyId to be set')

      return reviewService.getReviewsForCompany(companyId, {
        page: 1,
        pageSize: NUM_REVIEWS_PER_PAGE,
      })
    },
    enabled: shouldEnableQuery,
    refetchOnWindowFocus: false,
  })
}

export default useInitialReviewsQuery
