import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import { useSuspenseQuery } from '@tanstack/react-query'
import React from 'react'
import { Link } from 'react-router-dom'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { QUERY_KEYS } from '../../../../lib/queryKeys'
import { ROUTES } from '../../../../lib/routes'
import companyService from '../../../../lib/services/companyService'

const PaymentProfileStatus: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()

  const { data: companyBankDetails } = useSuspenseQuery({
    queryKey: QUERY_KEYS.companyBankDetails,
    queryFn: () => companyService.getBankDetails(currentCompany.id),
    retry: false,
  })

  if (companyBankDetails.isStripeConnected) {
    return null
  }

  return (
    <p className="text-muted">
      <FontAwesomeIcon icon={faInfoCircle} /> You do not currently have a
      payment profile configured. Click{' '}
      <Link to={ROUTES.preferences + '#payment-profile'} target="_blank">
        here
      </Link>{' '}
      to manage your payout settings.
    </p>
  )
}

export default PaymentProfileStatus
