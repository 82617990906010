import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'

import useModal from '../../../../../hooks/useModal'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import AcceptInviteModal from './AcceptInviteModal'
import DeclineInviteModal from './DeclineInviteModal'

interface Props {
  panel: CompanyProfileItemPanel
}

const RespondToInviteButtons: React.FC<Props> = ({ panel }) => {
  const {
    isShowingModal: isShowingAcceptModal,
    showModal: showAcceptModal,
    hideModal: hideAcceptModal,
  } = useModal()

  const {
    isShowingModal: isShowingRejectModal,
    showModal: showRejectModal,
    hideModal: hideRejectModal,
  } = useModal()

  return (
    <div className="d-flex flex-column flex-sm-row gap-2">
      <Button
        onClick={showAcceptModal}
        variant="success"
        size="sm"
        className="w-100 d-flex justify-content-center align-items-center gap-2"
      >
        <FontAwesomeIcon icon={faCheck} />
        Accept
      </Button>

      {isShowingAcceptModal && (
        <AcceptInviteModal
          panel={panel}
          onAccept={hideAcceptModal}
          onHide={hideAcceptModal}
        />
      )}

      <Button
        onClick={() => {
          showRejectModal()
        }}
        variant="outline-danger"
        size="sm"
        className="w-100 d-flex justify-content-center align-items-center gap-2"
      >
        <FontAwesomeIcon icon={faTimes} />
        Decline
      </Button>

      {isShowingRejectModal && (
        <DeclineInviteModal onHide={hideRejectModal} panel={panel} />
      )}
    </div>
  )
}

export default RespondToInviteButtons
