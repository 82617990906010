import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import { useInvalidatePanelQueries } from '../../../../../hooks/invalidatePanelQueries'
import usePosthogCapture from '../../../../../hooks/posthogCapture/usePosthogCapture'
import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useNotyf from '../../../../../hooks/useNotyf'
import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../../../../lib/constants'
import panelService from '../../../../../lib/services/panelService'
import { showCommercialAgreementsModal } from '../../../../../redux/slices/commercialAgreementsModal'
import { setShouldShowFirstRelationshipRequestApprovedModal } from '../../../../../redux/slices/panel'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import ActionButton from '../../../ActionButton'

interface Props {
  panel: CompanyProfileItemPanel
  onAccept: () => void
  onHide: () => void
}

const AcceptInviteModal: React.FC<Props> = ({ panel, onAccept, onHide }) => {
  const acceptPanelInviteMutation = useAcceptPanelInviteMutation()

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="text-black mb-0">Accept relationship invitation</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-0">
          <p>
            <strong>{panel.fromCompany.presentationName}</strong> has invited
            you to join their professional network.
          </p>

          <p className="mb-0">
            If you accept their invite you will be able to refer clients between
            each other.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>

        <ActionButton
          variant="success"
          onClick={() =>
            acceptPanelInviteMutation.mutate(panel, {
              onSuccess: onAccept,
            })
          }
          isProcessing={acceptPanelInviteMutation.isPending}
          isProcessingText="Accepting invite"
        >
          Accept invitation
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

function useAcceptPanelInviteMutation() {
  const notyf = useNotyf()
  const invalidatePanelQueries = useInvalidatePanelQueries()
  const dispatch = useAppDispatch()
  const { posthogCapture } = usePosthogCapture()

  return useMutation({
    mutationFn: (panel: CompanyProfileItemPanel) =>
      panelService.acceptPanelInvite(panel.id),

    onSuccess: async (response, panel) => {
      posthogCapture({
        appArea: 'relationship-management',
        action: 'relationship-accepted',
      })

      await invalidatePanelQueries()

      if (response.isFirstPanelApprovedByToCompany) {
        dispatch(setShouldShowFirstRelationshipRequestApprovedModal(true))
      }

      notyf.success(
        `${panel.fromCompany.presentationName} was added to your approved professional network`,
      )

      const outgoingAgreement = panel.commercialAgreements.find(
        (agreement) => agreement.isOutgoingAgreement,
      )

      const incomingAgreement = panel.commercialAgreements.find(
        (agreement) => agreement.isIncomingAgreement,
      )

      const referralDirectionToShow = outgoingAgreement?.needsReview
        ? COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
        : incomingAgreement?.needsReview
          ? COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
          : undefined

      if (referralDirectionToShow) {
        dispatch(
          showCommercialAgreementsModal({
            panelId: panel.id,
            referralDirection: referralDirectionToShow,
          }),
        )
      }
    },
    onError: () => {
      notyf.error('Could not accept invite')
    },
  })
}

export default AcceptInviteModal
