import React from 'react'

import { isDevelopment } from '../../../lib/helpers/helperFunctions'
import { CommercialAgreementTypeOption } from '../../../redux/slices/commercialAgreementsForm'
import DataTable from '../../datatable/DataTable'
import ServiceFeesTableHeaders from './ServiceFeesTableHeaders'
import ServiceFeesTableRows, {
  ServiceFeesTableRowsProps,
} from './ServiceFeesTableRows'

type Props = ServiceFeesTableRowsProps & {
  agreementType: CommercialAgreementTypeOption
  className?: string
}

const ServiceFeesTable: React.FC<Props> = ({
  className,
  showAllColumns,
  ...props
}) => (
  <DataTable
    tableContainerProps={{
      className,
      title:
        isDevelopment() && props.commercialAgreementId
          ? `Commercial agreement ID: ${props.commercialAgreementId}`
          : undefined,
    }}
    tableProps={{ className: 'table--is-sticky' }}
    striped={false}
  >
    <thead>
      <ServiceFeesTableHeaders
        serviceFees={props.serviceFees}
        isRecommendedPartnerTerms={props.isRecommendedPartnerTerms}
        showAllColumns={showAllColumns}
        agreementType={props.agreementType}
      />
    </thead>

    <tbody>
      <ServiceFeesTableRows {...props} showAllColumns={showAllColumns} />
    </tbody>
  </DataTable>
)

export default ServiceFeesTable
