import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPenField } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button, Card } from 'react-bootstrap'

import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../lib/constants'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import FieldError from '../form/FieldError'
import ReviewESignatureLetterModal from '../modals/ReviewESignatureLetterModal/ReviewESignatureLetterModal'
import { useReviewESignatureLetterModal } from '../modals/ReviewESignatureLetterModal/useReviewESignatureLetterModal'
import CardHeading from './CommercialAgreementsForm/components/CardHeading'
import ReviewActions from './CommercialAgreementsForm/components/ReviewActions'
import PlainButton from './PlainButton'
import { ServiceFeeDetail } from './ServiceFeesTable/ServiceFeesTableRows'

interface Props {
  otherCompany: {
    id: number
    presentationName: string
  }
  serviceFees: ServiceFeeDetail[]
  onApprove: () => void
  hasApproved: boolean
  onAmend?: (amendRequest: string) => void
  onClearAction?: () => void
  hasAddedAmendmentRequest: boolean
  amendRequest?: string
  error?: string
  className?: string
  agreementType?: CommercialAgreementTypeOption
}

export const REVIEW_ESIGNATURE_LETTER_BOX_DOM_ID =
  'js-review-e-signature-letter-box'

const ReviewESignatureLetterBox: React.FC<Props> = ({
  otherCompany,
  serviceFees,
  hasApproved,
  hasAddedAmendmentRequest,
  onApprove,
  onAmend,
  amendRequest,
  error,
  className,
  agreementType,
}) => {
  const { showModal } = useReviewESignatureLetterModal()

  function renderCta() {
    if (hasAddedAmendmentRequest) {
      return (
        <Button
          variant="success"
          disabled
          className="d-flex align-items-center gap-2"
        >
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '16px' }} />
          Amendment request added
        </Button>
      )
    }

    if (hasApproved) {
      return (
        <Button
          variant="success"
          disabled
          className="d-flex align-items-center gap-2"
        >
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '16px' }} />
          eSignature letter approved
        </Button>
      )
    }

    return (
      <Button
        variant="outline-success"
        onClick={showModal}
        id={REVIEW_ESIGNATURE_LETTER_BOX_DOM_ID}
      >
        <FontAwesomeIcon icon={faPenField} /> Preview & approve eSignature
        letter
      </Button>
    )
  }

  const hasReviewed = hasApproved || hasAddedAmendmentRequest

  return (
    <>
      <Card className={className}>
        <Card.Body>
          <CardHeading>Client consent letter</CardHeading>

          <p>
            As the FCA-regulated party, you are required to approve the wording
            of {otherCompany.presentationName}'s introductory letter as a
            financial promotion. Please view and amend / approve as appropriate.
          </p>

          <div className="d-flex justify-content-end gap-2">
            {renderCta()}

            {hasReviewed && (
              <PlainButton onClick={showModal} style={{ fontSize: '12px' }}>
                (View/amend)
              </PlainButton>
            )}
          </div>

          {error && (
            <FieldError className="mt-1 text-base text-end">{error}</FieldError>
          )}
        </Card.Body>
      </Card>

      <ReviewESignatureLetterModal
        otherCompany={otherCompany}
        title="Approve client eSignature letter"
        referralDirection={COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING}
        serviceFees={serviceFees}
        agreementType={agreementType}
        renderHeader={() => (
          <>
            <p className="text-lg">
              As the FCA-regulated party, you are required to approve the
              wording of {otherCompany.presentationName}'s introductory letter
              as a financial promotion. Please view and amend / approve as
              appropriate.
            </p>

            <ReviewActions
              onApprove={onApprove}
              onAmend={onAmend}
              amendRequest={amendRequest}
            />
          </>
        )}
      />
    </>
  )
}

export default ReviewESignatureLetterBox
