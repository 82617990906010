import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@rq-ratings/pro-solid-svg-icons'
import copy from 'copy-to-clipboard'
import React, { ButtonHTMLAttributes, useState } from 'react'
import { Button } from 'react-bootstrap'

import { filterText } from '../../../pages/inviteToRq/helpers'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonText: string
  copyText: string
  format?: 'text/html' | 'text/plain'
  useLinkButtonVariant?: boolean
  /** On click event (doesn't override the default functionality) */
  onClick?: () => void
}

const CopyLink: React.FC<Props> = ({
  buttonText,
  copyText,
  format = 'text/plain',
  className = '',
  useLinkButtonVariant = false,
  onClick,
  ...props
}) => {
  const [clicked, setClicked] = useState(false)
  const unclickedVariant = useLinkButtonVariant ? 'link' : 'primary'

  // Don't pad it like a button if we want it to look like a link
  const finalClassName =
    !clicked && useLinkButtonVariant ? `${className} p-0` : className

  return (
    <Button
      variant={clicked ? 'success' : unclickedVariant}
      className={finalClassName}
      onClick={() => {
        if (onClick !== undefined) {
          onClick()
        }

        if (copyText.length) {
          copy(filterText(copyText), {
            format: format,
          })
        }

        setClicked(true)

        setTimeout(() => {
          setClicked(false)
        }, 1000)
      }}
      {...props}
    >
      {clicked ? (
        <>
          <FontAwesomeIcon icon={faCircleCheck} className="me-1" />
          Copied
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faLink} className="me-2" />
          {buttonText}
        </>
      )}
    </Button>
  )
}

export default CopyLink
