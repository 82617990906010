import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import TextareaInput from '../../../form/TextareaInput'

interface Props {
  onSubmit: (amendmentRequest: string) => void
  onClose: () => void
  amendRequest?: string
}

interface FormValues {
  amendment: string
}

const FORM_FIELDS: Record<keyof FormValues, keyof FormValues> = {
  amendment: 'amendment',
}

const AmendESignatureModal: React.FC<Props> = ({
  onSubmit,
  onClose,
  amendRequest,
}) => {
  const initialValues: FormValues = {
    amendment: amendRequest || '',
  }

  const validationSchema = yup.object().shape({
    [FORM_FIELDS.amendment]: yup.string().trim().required(),
  })

  return (
    <Modal show onHide={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values.amendment)}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Amend eSignature letter</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TextareaInput
              label="Please explain how you want the letter to be amended:"
              name={FORM_FIELDS.amendment}
              autoFocus
              rows={5}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="outline-secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button type="submit" variant="primary">
              Add amendment request
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}

export default AmendESignatureModal
