import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faPlus } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button, Card } from 'react-bootstrap'

import useModal from '../../../../../hooks/useModal'
import { DATE_FORMATS } from '../../../../../lib/constants'
import {
  formatDate,
  getOrFail,
} from '../../../../../lib/helpers/helperFunctions'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import AddNoteModal from '../../../../modals/AddNoteModal'
import EditNoteModal from '../../../../modals/EditNoteModal'

interface Props {
  companyProfile: CompanyProfileItem
}

const YourComments: React.FC<Props> = ({ companyProfile }) => {
  const note = companyProfile.panel?.panelNote
  const lastUpdated = note?.updatedAt ?? note?.createdAt

  const companyName = companyProfile.name
  const panel = getOrFail(companyProfile.panel, 'Expected panel to be defined')

  const {
    isShowingModal: isShowingAddNoteModal,
    showModal: showAddNoteModal,
    hideModal: hideAddNoteModal,
  } = useModal()

  const {
    isShowingModal: isShowingEditNoteModal,
    showModal: showEditNoteModal,
    hideModal: hideEditNoteModal,
  } = useModal()

  if (!note) {
    return (
      <>
        <div>
          <p className="mb-2">You haven't added any comments yet.</p>

          <Button
            variant="success"
            className="d-flex gap-2 flex-row align-items-center"
            onClick={showAddNoteModal}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add a comment
          </Button>
        </div>

        {isShowingAddNoteModal && (
          <AddNoteModal
            panelIri={panel['@id']}
            otherCompanyName={companyName}
            onHide={hideAddNoteModal}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ maxWidth: '600px' }}>
        <Card className="border-box">
          <Card.Body>{note.body}</Card.Body>

          <Card.Footer className="py-2 border-top">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between gap-1 align-items-sm-center">
              <div className="d-flex gap-1 gap-sm-2">
                <Button
                  variant="outline-secondary"
                  className="d-flex gap-2 flex-row align-items-center"
                  size="sm"
                  onClick={showEditNoteModal}
                >
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{ fontSize: '11px' }}
                  />
                  Edit comment
                </Button>
              </div>

              {lastUpdated && (
                <div className="text-muted text-sm">
                  Last updated:{' '}
                  {formatDate(lastUpdated, DATE_FORMATS.DAY_MONTH_YEAR_TIME)}
                </div>
              )}
            </div>
          </Card.Footer>
        </Card>
      </div>

      {isShowingEditNoteModal && (
        <EditNoteModal
          panelNoteId={note.id}
          panelNoteBody={note.body}
          otherCompanyName={companyName}
          onHide={hideEditNoteModal}
        />
      )}
    </>
  )
}

export default YourComments
