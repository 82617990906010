import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import Skeleton from 'react-loading-skeleton'

interface Props {
  className?: string
  style?: CSSProperties
}

const SkeletonBox: React.FC<Props> = ({ className, style }) => {
  return (
    <div className="flex-grow-1 w-100">
      <Skeleton
        className={classNames(className)}
        style={{ height: '200px', ...style }}
      />
    </div>
  )
}

export default SkeletonBox
