import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { bookMeetingModalReducer } from './slices/bookMeetingModal'
import { clientMetadataReducer } from './slices/clientMetadata'
import { clientPortalReducer } from './slices/clientPortal'
import { clientsReducer } from './slices/clients'
import { commercialAgreementsFormReducer } from './slices/commercialAgreementsForm'
import { commercialAgreementsMetadataReducer } from './slices/commercialAgreementsMetadata'
import { commercialAgreementsModalReducer } from './slices/commercialAgreementsModal'
import { commonDataReducer } from './slices/commonData'
import { companyReducer } from './slices/company'
import { compassReducer } from './slices/compass'
import { compassClientsReducer } from './slices/compassClients'
import { compassPdfGuideReducer } from './slices/compassPdfGuideData'
import { completeRegistrationPopupReducer } from './slices/completeRegistrationModal'
import { layoutReducer } from './slices/layout'
import { onboardingChecklistReducer } from './slices/onboardingChecklist'
import { panelReducer } from './slices/panel'
import { panelInviteReducer } from './slices/panelInvite/panelInvite'
import { referClientReducer } from './slices/referClient'
import { referralCaseReducer } from './slices/referralCase'
import { referralsDashboardReducer } from './slices/referralsDashboard'
import { sessionReducer } from './slices/session'
import { demoVideoReducer } from './slices/videoModal'

const rootReducer = combineReducers({
  bookMeetingModal: bookMeetingModalReducer,
  clientMetadata: clientMetadataReducer,
  clientPortal: clientPortalReducer,
  layout: layoutReducer,
  clients: clientsReducer,
  commonData: commonDataReducer,
  commercialAgreementsMetadata: commercialAgreementsMetadataReducer,
  commercialAgreementsForm: commercialAgreementsFormReducer,
  commercialAgreementsModal: commercialAgreementsModalReducer,
  compass: compassReducer,
  compassClients: compassClientsReducer,
  compassPdfGuide: compassPdfGuideReducer,
  company: companyReducer,
  demoVideo: demoVideoReducer,
  panel: panelReducer,
  panelInvite: panelInviteReducer,
  onboardingChecklist: onboardingChecklistReducer,
  referralsDashboard: referralsDashboardReducer,
  referralCase: referralCaseReducer,
  referClient: referClientReducer,
  session: sessionReducer,
  completeRegistrationPopup: completeRegistrationPopupReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
