import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'

import CardHeading from '../../../../typography/CardHeading'

type Props = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

const SectionHeading: React.FC<Props> = ({ children, className, ...props }) => (
  <CardHeading className={classNames('my-2', className)} {...props}>
    {children}
  </CardHeading>
)

export default SectionHeading
