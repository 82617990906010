import React from 'react'
import { useMount } from 'react-use'
import invariant from 'tiny-invariant'

import { useCreateReferralClientConsentLetterPreviewMutation } from '../../hooks/useCreateReferralClientConsentLetterPreviewMutation'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import {
  CommercialAgreementReferralDirection,
  ReferralType,
} from '../../types/misc'
import LoadingWidget from '../widgets/LoadingWidget'
import ErrorBoundaryCard from './ErrorBoundaryCard'
import ReferralESignatureConsentPdf from './ReferralESignatureLetterPdf/ReferralESignatureConsentPdf'
import { ServiceFeeDetail } from './ServiceFeesTable/ServiceFeesTableRows'

interface Props {
  otherCompanyId: number
  referralDirection: CommercialAgreementReferralDirection
  clientId?: number
  serviceAreaIds?: number[]
  serviceFees?: ServiceFeeDetail[]
  render?: (props: RenderProps) => React.ReactNode
  agreementType?: CommercialAgreementTypeOption
  commissionJustification?: string
  onEditCommissionJustification?: (commissionJustification: string) => void
  referralType?: ReferralType
}

interface RenderProps {
  renderPreview: () => React.ReactNode
  commissionJustification?: string
}

const ReferralESignaturePreview: React.FC<Props> = ({
  otherCompanyId,
  referralDirection,
  clientId,
  serviceAreaIds = [],
  serviceFees,
  render,
  agreementType,
  commissionJustification,
  onEditCommissionJustification,
  referralType,
}) => {
  const createLetterPreviewMutation =
    useCreateReferralClientConsentLetterPreviewMutation()

  useMount(() => {
    createLetterPreviewMutation.mutate({
      otherCompanyId,
      clientId,
      serviceAreaIds,
      referralDirection,
      type: referralType,
    })
  })

  const eSignatureLetterPreview = createLetterPreviewMutation.data

  if (createLetterPreviewMutation.error) {
    throw new Error('Failed to load preview data')
  }

  if (createLetterPreviewMutation.isPending || !eSignatureLetterPreview) {
    return <LoadingWidget />
  }

  function renderPreview() {
    invariant(eSignatureLetterPreview)

    return (
      <ReferralESignatureConsentPdf
        referral={eSignatureLetterPreview}
        serviceFees={serviceFees}
        agreementType={agreementType}
        commissionJustification={commissionJustification}
        onEditCommissionJustification={onEditCommissionJustification}
      />
    )
  }

  return (
    <ErrorBoundaryCard
      message={
        <>
          We're very sorry but it looks like we're having some technical issues.
          Our team has been notified so please try again later.
        </>
      }
    >
      {render
        ? render({
            renderPreview,
            commissionJustification,
          })
        : renderPreview()}
    </ErrorBoundaryCard>
  )
}

export default ReferralESignaturePreview
