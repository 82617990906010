import React from 'react'

import { useIsPdf } from '../../../../hooks/useIsPdf'
import CollapsibleContent from '../../CollapsibleContent'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeeNotes: React.FC<Props> = ({ serviceFee }) => {
  const { notes } = serviceFee
  const isPdf = useIsPdf()

  if (!notes) {
    return null
  }

  return isPdf ? (
    notes
  ) : (
    <CollapsibleContent content={notes} collapseThreshold={100} />
  )
}

export default ServiceFeeNotes
