import {
  CreateCompanyMessagePreviewRequest,
  CreateCompanyMessageRequest,
} from '../../types/requests/company-message'
import { CompanyMessageItem } from '../../types/responses/company-message'
import { CompanyMessageEnabledItem } from '../../types/responses/company-message-enabled'
import apiService from './apiService'

export class CompanyMessageService {
  private endpoint = '/v1/companies'

  async createCompanyMessage(
    request: CreateCompanyMessageRequest,
  ): Promise<CompanyMessageItem> {
    const response = await apiService.post(
      `${this.endpoint}/${request.toCompany}/message`,
      request,
    )

    return response.data
  }

  async createCompanyMessagePreview(
    request: CreateCompanyMessagePreviewRequest,
  ): Promise<CompanyMessageItem> {
    const response = await apiService.get(
      `${this.endpoint}/${request.toCompany}/message/preview`,
    )

    return response.data
  }

  async getCompanyMessageEnabledStatus(
    companyId: number,
  ): Promise<CompanyMessageEnabledItem> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/message/enabled`,
    )

    return response.data
  }
}

const companyMessageService = new CompanyMessageService()
export default companyMessageService
