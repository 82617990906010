import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

import useAppDispatch from '../../../hooks/useAppDispatch'
import {
  endEdit,
  startEdit,
} from '../../../redux/slices/completeRegistrationModal'

/**
 * Use inside `<Formik/>` to update the redux state whenever
 * the form's dirty, so that navigation can be prevented.
 */
const CompleteRegistrationFormikDirtyNavigationGuard: React.FC = () => {
  const { dirty } = useFormikContext()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (dirty) {
      dispatch(startEdit())
    } else {
      dispatch(endEdit())
    }

    return () => {
      // Just in case, free things up at the end
      dispatch(endEdit())
    }
  }, [dirty, dispatch])

  return null
}

export default CompleteRegistrationFormikDirtyNavigationGuard
