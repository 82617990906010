import React, { CSSProperties } from 'react'

import SkeletonRow from './SkeletonRow'

interface Props {
  count: number
  className?: string
  style?: CSSProperties
  rowHeight?: number
}

const SkeletonRows: React.FC<Props> = ({
  count,
  className,
  style,
  rowHeight,
}) => {
  return (
    <div className="d-flex flex-column" style={{ gap: '4px' }}>
      {Array.from(Array(count).keys()).map((index) => (
        <SkeletonRow
          className={className}
          style={style}
          key={index}
          height={rowHeight}
        />
      ))}
    </div>
  )
}

export default SkeletonRows
