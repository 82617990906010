import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faLocationDot,
  faUserGroup,
} from '@rq-ratings/pro-light-svg-icons'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

import reviewService from '../../../lib/services/reviewService'
import { ReviewCollection } from '../../../types/responses/review'
import SkeletonTable from '../../skeleton/SkeletonTable'
import SkeletonText from '../../skeleton/SkeletonText'
import ReviewDetails from '../CompanyProfile/tabs/Reviews/components/ReviewDetails'
import { NUM_REVIEWS_PER_PAGE } from '../CompanyProfile/tabs/Reviews/Reviews'
import TablePagination from '../TablePagination'

interface Props {
  companyId: number
  render?: (props: RenderProps) => React.ReactNode
}

interface RenderProps {
  data: ReviewCollection
  renderReviews: () => React.ReactNode
}

const CompanyReviews: React.FC<Props> = ({ companyId, render }) => {
  const [page, setPage] = useState<number>(1)
  const [activeReviewId, setActiveReviewId] = useState<number | null>(null)

  const reviewsQuery = useQuery({
    queryKey: ['reviews', { companyId, page }],
    queryFn: () =>
      reviewService.getReviewsForCompany(companyId, {
        page,
        pageSize: NUM_REVIEWS_PER_PAGE,
      }),
    refetchOnWindowFocus: false,
  })

  function renderReviewerInfo(faIcon: IconProp, info: string) {
    return (
      <Col lg={4} className="d-flex align-items-center mb-3 mb-lg-0">
        <FontAwesomeIcon
          icon={faIcon}
          className="text-primary me-2"
          style={{ fontSize: '16px', width: '20px' }}
        />
        {info}
      </Col>
    )
  }

  if (reviewsQuery.isLoading) {
    return <Skeleton />
  }

  const reviews = reviewsQuery.data

  if (reviewsQuery.isError || !reviews) {
    throw new Error(`Failed to fetch reviews for company: ${companyId}`)
  }

  const renderReviews = () => {
    const totalReviewsCount = reviews['hydra:totalItems']

    return (
      <>
        <Accordion
          activeKey={activeReviewId ? activeReviewId.toString() : null}
          onSelect={(selectedReviewId) =>
            setActiveReviewId(
              selectedReviewId ? Number(selectedReviewId) : null,
            )
          }
          className="bg-white accordion--white"
        >
          {reviews['hydra:member'].map((review) => (
            <Accordion.Item
              key={review.id}
              eventKey={review.id.toString()}
              className="bg-white py-2 px-2"
            >
              <Accordion.Header className="d-flex justify-content-between align-items-center">
                <Row className="flex-grow-1">
                  <Col lg={4} className="mb-3 mb-lg-0">
                    <div className="fw-bold text-black">"{review.title}"</div>
                  </Col>

                  <Col lg={8}>
                    <Row>
                      {renderReviewerInfo(
                        faBriefcase,
                        review.reviewer.title + ', ' + review.reviewer.firm,
                      )}
                      {renderReviewerInfo(
                        faLocationDot,
                        review.reviewer.location,
                      )}
                      {renderReviewerInfo(
                        faUserGroup,
                        review.reviewer.numEmployees,
                      )}
                    </Row>
                  </Col>
                </Row>
              </Accordion.Header>

              <Accordion.Body className="py-2">
                {activeReviewId && <ReviewDetails reviewId={activeReviewId} />}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        {totalReviewsCount > NUM_REVIEWS_PER_PAGE && (
          <div className="mt-3">
            <TablePagination
              activePage={page}
              totalItemsCount={totalReviewsCount}
              numItemsPerPage={NUM_REVIEWS_PER_PAGE}
              onChange={setPage}
            />
          </div>
        )}
      </>
    )
  }

  return render
    ? render({
        data: reviewsQuery.data,
        renderReviews,
      })
    : renderReviews()
}

const Skeleton: React.FC = () => (
  <>
    <SkeletonText className="my-3" length={30} />
    <SkeletonTable rows={7} columns={4} />
  </>
)

export default CompanyReviews
