import React from 'react'

import { AgreementField } from './AgreementFields'

interface Props {
  field: AgreementField
}

const AgreementFieldRow: React.FC<Props> = ({ field }) => {
  const { label, value, visible = true, message } = field

  if (!visible) {
    return null
  }

  return (
    <div
      key={label?.toString()}
      className="px-1 py-2 border-bottom border-gray-300"
    >
      <div className="d-flex flex-column flex-sm-row justify-content-between gap-1">
        <strong className="text-black">{label}</strong>
        <span className="text-sm-end">{value}</span>
      </div>
      {message && <div>{message}</div>}
    </div>
  )
}

export default AgreementFieldRow
