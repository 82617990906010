import classNames from 'classnames'
import React, { CSSProperties, HTMLAttributes } from 'react'

export type PageProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  style?: {
    table?: CSSProperties
    footer?: CSSProperties
  }
  pageStyle?: CSSProperties
  footer?: React.ReactNode
}

const Page: React.FC<PageProps> = ({
  className,
  children,
  style,
  ...props
}) => {
  return (
    <table
      className={classNames('sheet ReferralESignatureLetterPdfPage', className)}
      {...props}
      style={style?.table}
    >
      <thead style={{ height: '35px' }} />

      <tbody>
        <div className="flex-grow-1">{children}</div>
      </tbody>

      <tfoot style={{ height: '40px' }} />
    </table>
  )
}

export default Page
