import React from 'react'

import { useIsPdf } from '../../../../hooks/useIsPdf'
import CollapsibleContent from '../../CollapsibleContent'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeeExample: React.FC<Props> = ({ serviceFee }) => {
  const isPdf = useIsPdf()
  const { example } = serviceFee

  if (!example) {
    return null
  }

  return isPdf ? (
    example
  ) : (
    <CollapsibleContent content={example} collapseThreshold={130} />
  )
}

export default ServiceFeeExample
