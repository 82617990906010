import React from 'react'

import ESignatureLetterModal, {
  ESignatureLetterModalProps,
} from '../ESignatureLetterModal'
import { useReviewESignatureLetterModal } from './useReviewESignatureLetterModal'

type Props = Omit<ESignatureLetterModalProps, 'isShowing' | 'onHide'>

const ReviewESignatureLetterModal: React.FC<Props> = (props) => {
  const { isShowingModal, hideModal } = useReviewESignatureLetterModal()

  return (
    <ESignatureLetterModal
      {...props}
      isShowing={isShowingModal}
      onHide={hideModal}
    />
  )
}

export default ReviewESignatureLetterModal
