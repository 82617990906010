import { useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../../../../../lib/queryKeys'

export function useInvalidatePanelNotes() {
  const queryClient = useQueryClient()

  return async function () {
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyProfile(),
    })

    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyReferralFirms(),
    })

    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.panelPartners(),
    })
  }
}
