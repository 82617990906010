import React, { CSSProperties } from 'react'

export const ESIGINATURE_PDF_SECTION_STYLES: CSSProperties = {
  paddingLeft: '55px',
  paddingRight: '55px',
}

type Props = React.HTMLAttributes<HTMLDivElement>

const PdfSectionContainer: React.FC<Props> = ({ style, ...props }) => (
  <div {...props} style={{ ...ESIGINATURE_PDF_SECTION_STYLES, ...style }} />
)

export default PdfSectionContainer
