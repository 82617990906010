import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faInfoCircle,
  faPlus,
} from '@rq-ratings/pro-regular-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { Suspense, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { useInvalidatePanelQueries } from '../../../../hooks/invalidatePanelQueries'
import { useCommercialAgreementMetadataOrFail } from '../../../../hooks/useCommercialAgreementMetadata'
import useNotyf from '../../../../hooks/useNotyf'
import { usePermissions } from '../../../../hooks/usePermissions'
import {
  API_VIOLATION_CODE,
  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION,
  COMMERCIAL_AGREEMENT_TYPE,
  LONG_MESSAGE_DURATION,
  PENDING_PANEL_ACTION,
} from '../../../../lib/constants'
import { bool2Human } from '../../../../lib/helpers/helperFunctions'
import { currentUrl } from '../../../../lib/helpers/routeHelpers'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import companyService from '../../../../lib/services/companyService'
import constraintViolationService from '../../../../lib/services/constraintViolationService'
import panelService from '../../../../lib/services/panelService'
import routeService from '../../../../lib/services/routeService'
import { CommercialAgreementReferralDirection } from '../../../../types/misc'
import { ConfirmCommercialAgreementRequest } from '../../../../types/requests/commercial-agreements'
import { CommercialAgreementItem } from '../../../../types/responses/commercial-agreements'
import { PanelItem } from '../../../../types/responses/panels'
import { OPTIONS } from '../../../flows/CompassFlow/utils/options'
import RadioOptionsInput from '../../../form/RadioOptionsInput'
import PreviewESignatureLetterModal from '../../../modals/PreviewESignatureLetterModal/PreviewESignatureLetterModal'
import { usePreviewESignatureLetterModal } from '../../../modals/PreviewESignatureLetterModal/usePreviewESignatureLetterModal'
import { useReviewESignatureLetterModal } from '../../../modals/ReviewESignatureLetterModal/useReviewESignatureLetterModal'
import SkeletonRows from '../../../skeleton/SkeletonRows'
import ActionButton from '../../ActionButton'
import { COMMERCIAL_AGREEMENT_TYPE_OPTION } from '../../CommercialAgreementsForm/constants'
import { useCreateCommercialAgreementsMetadataMutation } from '../../CommercialAgreementsForm/hooks/useCreateCommercialAgreementsMetadataMutation'
import InfoBox from '../../InfoBox'
import ReviewESignatureLetterBox from '../../ReviewESignatureLetterBox'
import ServiceFeesTable from '../../ServiceFeesTable/ServiceFeesTable'
import {
  REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS,
  ReviewCommercialAgreementFormValues,
} from '../constants'
import AgreementFieldRow from './AgreementFieldRow'
import AmendAgreementForm from './AmendAgreementForm'
import ExistingAgreements from './ExistingAgreements'
import PaymentProfileStatus from './PaymentProfileStatus'
import RqCompliantFeeSharingTooltip from './RqCompliantFeeSharingTooltip'

interface Props {
  agreement?: CommercialAgreementItem
  panel: PanelItem
  referralDirection: CommercialAgreementReferralDirection
  otherAgreementNeedsReview: boolean
  onAcceptInvite: () => void
  onConfirmAgreement: () => Promise<void>
}

interface ConfirmAgreementMutationVariables {
  values: ReviewCommercialAgreementFormValues
  formikHelpers: FormikHelpers<ReviewCommercialAgreementFormValues>
}

const CommercialAgreementDirectionDetails: React.FC<Props> = ({
  agreement,
  panel,
  referralDirection,
  otherAgreementNeedsReview,
  onAcceptInvite,
  onConfirmAgreement,
}) => {
  const [isAmending, setIsAmending] = useState<boolean>(false)
  const previewESignatureLetterModal = usePreviewESignatureLetterModal()
  const reviewESignatureLetterModal = useReviewESignatureLetterModal()
  const needsReview = !!agreement?.needsReview
  const notyf = useNotyf()
  const permissions = usePermissions()
  const invalidatePanelsQuery = useInvalidatePanelQueries()
  const reviewAdviseText =
    agreement?.commercialAgreementType === COMMERCIAL_AGREEMENT_TYPE.NO
      ? 'advised that there is no commercial agreement in place'
      : 'added the details of your commercial agreement with this firm'
  const metadata = useCommercialAgreementMetadataOrFail()
  const createMetadataMutation = useCreateCommercialAgreementsMetadataMutation()

  const isPendingApproval = !!agreement?.isAgreementPending
  const shouldShowAmendButton = isPendingApproval && !isAmending
  const isOutgoingAgreement =
    commercialAgreementService.isOutgoingAgreement(referralDirection)

  const isPanelAwaitingApproval = panel.pendingActions.includes(
    PENDING_PANEL_ACTION.respondToInvite,
  )

  const isOnlyAgreementThatNeedsReview =
    needsReview && !otherAgreementNeedsReview

  const canConfirmAndAcceptInvite =
    isOnlyAgreementThatNeedsReview && isPanelAwaitingApproval

  async function confirmAgreement(values: ReviewCommercialAgreementFormValues) {
    const { existingAgreementFile, isRqHandleFeeSharing } = values
    invariant(agreement, 'Can only confirm an agreement if an agreement exists')

    const request: ConfirmCommercialAgreementRequest = {
      consentLetterApprovedAt: values.clientConsentLetterApprovedAt,
      consentLetterAmendRequest: values.clientConsentLetterAmendRequest,
    }

    if (existingAgreementFile) {
      request.existingAgreementFileName = existingAgreementFile.name
      request.existingAgreementFileBase64 = existingAgreementFile.base64
    }

    if (isRqHandleFeeSharing !== null) {
      request.isRqHandleFeeSharing = isRqHandleFeeSharing
    }

    await commercialAgreementService.confirmAgreement(agreement.id, request)

    await onConfirmAgreement()
  }

  async function invalidateQueries() {
    await invalidatePanelsQuery()
    await createMetadataMutation.mutateAsync({
      otherCompany: companyService.getIri(metadata.otherCompany.id),
      referralDirection: COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING, // direction doesn't matter here
    })
  }

  const confirmAgreementMutation = useMutation({
    mutationFn: (variables: ConfirmAgreementMutationVariables) =>
      confirmAgreement(variables.values),
    onSuccess: async () => {
      await invalidateQueries()
      notyf.success('Agreement successfully confirmed')
    },
    onError: handleConfirmAgreementError,
  })

  const confirmAgreementAndAcceptInviteMutation = useMutation({
    mutationFn: async (variables: ConfirmAgreementMutationVariables) => {
      await confirmAgreement(variables.values)
      return panelService.acceptPanelInvite(panel.id)
    },
    onSuccess: async () => {
      await invalidateQueries()
      notyf.success(
        `${panel.fromCompany.presentationName} was added to your approved referral partners`,
      )
      onAcceptInvite()
    },
    onError: handleConfirmAgreementError,
  })

  function handleConfirmAgreementError(error: unknown) {
    const consentLetterError = constraintViolationService.getViolationByCode(
      error,
      API_VIOLATION_CODE.unapprovedOrUnamendedConsentLetter,
    )

    if (consentLetterError) {
      notyf.open({
        type: 'error',
        message: consentLetterError.message,
        duration: LONG_MESSAGE_DURATION,
      })
      return
    }

    notyf.error('Failed to confirm agreement')
  }

  const renderActionButtonText = () => {
    return canConfirmAndAcceptInvite ? 'Confirm & accept invitation' : 'Confirm'
  }

  function handleSubmit(
    values: ReviewCommercialAgreementFormValues,
    helpers: FormikHelpers<ReviewCommercialAgreementFormValues>,
  ) {
    if (canConfirmAndAcceptInvite) {
      confirmAgreementAndAcceptInviteMutation.mutate({
        values,
        formikHelpers: helpers,
      })

      return
    }

    if (needsReview) {
      confirmAgreementMutation.mutate({ values, formikHelpers: helpers })
      return
    }

    throw new Error('Did not expect to reach this point')
  }

  function renderActionButton() {
    // Don't show the button if the user is currently amending the agreement
    if (isAmending) {
      return null
    }

    if (canConfirmAndAcceptInvite) {
      return (
        <ActionButton
          type="submit"
          variant="success"
          isProcessing={confirmAgreementAndAcceptInviteMutation.isPending}
          style={{ minWidth: '110px' }}
        >
          {renderActionButtonText()}
        </ActionButton>
      )
    }

    if (needsReview) {
      return (
        <ActionButton
          type="submit"
          variant="success"
          isProcessing={confirmAgreementMutation.isPending}
          style={{ minWidth: '110px' }}
        >
          {renderActionButtonText()}
        </ActionButton>
      )
    }

    return null
  }

  const initialValues: ReviewCommercialAgreementFormValues = {
    existingAgreementFile: null,
  }

  // Only show review box for incoming agreements
  const shouldReviewESignatureLetter =
    referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING &&
    !!agreement &&
    metadata.incomingAgreement.shouldReviewESignatureLetter

  return (
    <>
      <div className="mt-4">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => {
            function onApprove() {
              setFieldValue(
                REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.clientConsentLetterApprovedAt,
                new Date().toISOString(),
              )

              reviewESignatureLetterModal.hideModal()
            }

            function onAmend(amendRequest: string) {
              notyf.success('Amendment request added')

              setFieldValue(
                REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.clientConsentLetterAmendRequest,
                amendRequest,
              )

              reviewESignatureLetterModal.hideModal()
            }

            return (
              <Form>
                {commercialAgreementService.shouldShowServiceFees(
                  agreement?.commercialAgreementType,
                ) && (
                  <div className="my-4">
                    <ServiceFeesTable
                      agreementType={agreement?.commercialAgreementType}
                      commercialAgreementId={agreement?.id}
                      serviceFees={agreement?.serviceFees ?? []}
                      showAllColumns={false}
                      addAgreementLink={routeService.addRelationshipAgreement(
                        panel.id,
                        {
                          referralDirection,
                          returnPath: currentUrl(),
                        },
                      )}
                    />
                  </div>
                )}

                {agreement?.commercialAgreementType ? (
                  <>
                    <AgreementFieldRow
                      field={{
                        label: 'Agreement type',
                        value: commercialAgreementService.getAgreementTypeLabel(
                          agreement?.commercialAgreementType,
                        ),
                      }}
                    />

                    {isOutgoingAgreement &&
                      commercialAgreementService.hasCommercialAgreement(
                        agreement?.commercialAgreementType,
                      ) && (
                        <AgreementFieldRow
                          field={{
                            label: 'eSignature via RQ PDF',
                            value: (
                              <Button
                                onClick={() =>
                                  previewESignatureLetterModal.showPreview()
                                }
                                variant="outline-primary"
                                size="sm"
                                className="btn-fluid"
                              >
                                Preview PDF
                              </Button>
                            ),
                          }}
                        />
                      )}

                    <AgreementFieldRow
                      field={{
                        label: 'Existing agreements',
                        value: (
                          <ExistingAgreements
                            agreement={agreement}
                            needsReview={needsReview}
                          />
                        ),
                      }}
                    />
                  </>
                ) : (
                  <p>
                    No agreement added.{' '}
                    <Link
                      to={routeService.addRelationshipAgreement(panel.id, {
                        referralDirection,
                        returnPath: currentUrl(),
                      })}
                      className="d-block mt-2 text-decoration-none"
                    >
                      <Button
                        variant="success"
                        size="lg"
                        className="d-flex gap-2 align-items-center btn-fluid d-flex justify-content-center justify-content-sm-start"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        Add an agreement
                      </Button>
                    </Link>
                  </p>
                )}

                {agreement?.commercialAgreementType ===
                  COMMERCIAL_AGREEMENT_TYPE_OPTION.YFS &&
                  isOutgoingAgreement && (
                    <AgreementFieldRow
                      field={{
                        label: (
                          <>
                            Would you like RQ to handle compliant fee sharing?
                            <RqCompliantFeeSharingTooltip />
                          </>
                        ),
                        value: needsReview ? (
                          <RadioOptionsInput
                            label={false}
                            optionLabelStyle={{ height: '32px' }}
                            name={
                              REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.isRqHandleFeeSharing
                            }
                            options={OPTIONS.yesNo}
                          />
                        ) : (
                          <>{bool2Human(agreement.isRqHandleFeeSharing)}</>
                        ),
                        message: values.isRqHandleFeeSharing &&
                          permissions.canManagePaymentProfile && (
                            <Suspense fallback={<SkeletonRows count={1} />}>
                              <div className="small font-weight-normal text-end mt-2">
                                <PaymentProfileStatus />
                              </div>
                            </Suspense>
                          ),
                      }}
                    />
                  )}

                {referralDirection ===
                  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING &&
                  agreement?.isRqHandleFeeSharing && (
                    <InfoBox
                      icon={faInfoCircle}
                      variant="blue"
                      className="mt-3"
                    >
                      {metadata.otherCompany.presentationName} has chosen RQ to
                      manage payments. In future, all fee sharing will need to
                      be paid directly to RQ rather than directly to{' '}
                      {metadata.otherCompany.presentationName}.
                    </InfoBox>
                  )}

                {isPendingApproval && (
                  <div className="mt-4">
                    <FontAwesomeIcon icon={faClock} className="me-2" />
                    {needsReview ? (
                      <>
                        Your referral partner {reviewAdviseText}. If you agree
                        with this, please press ‘{renderActionButtonText()}’
                        below or ‘Amend’ if you do not.
                      </>
                    ) : (
                      <>
                        You have {reviewAdviseText}. We are waiting for
                        confirmation from your referral partner.
                      </>
                    )}
                  </div>
                )}

                {shouldReviewESignatureLetter && (
                  <section className="mt-4">
                    <ReviewESignatureLetterBox
                      className="border-box mb-0"
                      otherCompany={{
                        presentationName:
                          metadata.incomingAgreement.referralFromCompany
                            .presentationName,
                        id: metadata.otherCompany.id,
                      }}
                      serviceFees={agreement?.serviceFees || []}
                      onApprove={onApprove}
                      onAmend={onAmend}
                      amendRequest={values.clientConsentLetterAmendRequest}
                      hasApproved={!!values.clientConsentLetterApprovedAt}
                      hasAddedAmendmentRequest={
                        !!values.clientConsentLetterAmendRequest
                      }
                    />
                  </section>
                )}

                <div className="d-flex justify-content-end mt-4">
                  {shouldShowAmendButton && (
                    <Button
                      className="me-2"
                      variant="outline-secondary"
                      onClick={() => setIsAmending(true)}
                    >
                      Amend
                    </Button>
                  )}

                  {renderActionButton()}
                </div>
              </Form>
            )
          }}
        </Formik>

        {agreement && (
          <>
            {isAmending && (
              <AmendAgreementForm
                agreement={agreement}
                onAmend={() => setIsAmending(false)}
                onCancel={() => setIsAmending(false)}
              />
            )}
          </>
        )}
      </div>

      <PreviewESignatureLetterModal
        otherCompany={metadata.otherCompany}
        title="eSignature via RQ PDF"
        referralDirection={COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING}
        serviceFees={agreement?.serviceFees || []}
        renderHeader={() => (
          <p className="mb-0">
            This is what an eSignature letter via RQ will look like:
          </p>
        )}
      />
    </>
  )
}

export default CommercialAgreementDirectionDetails
