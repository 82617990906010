import { CompanyProfileSearchParams } from '../components/misc/CompanyProfile/CompanyProfile'
import {
  GetReferralFirmIndividualsParams,
  GetReferralFirmsQuery,
} from '../types/requests/companies'
import {
  GetCompassReportsWithActionsParams,
  GetCompassReportsWithBusinessSaleParams,
  GetCompassReportsWithMortgageParams,
  GetCompassReportsWithPensionDetailsParams,
  GetCompassReportsWithProtectionDetailsParams,
  GetCompassReportsWithWillDetailsParams,
} from '../types/requests/compass-reports'
import { GetUserInvitesParams } from '../types/requests/invite-users'
import { GetReferralServiceAreaRequestParams } from '../types/requests/referrals'
import {
  GetPanelPartnersParams,
  GetPanelsParams,
} from './services/panelService'

export const QUERY_KEYS = {
  appState: ['app-state'],
  client: (id: number) => ['client', { id }],
  companyBankDetails: ['company-bank-details'],
  companyByReferralCode: (referralCode: string) => [
    'company-by-referral-code',
    { referralCode },
  ],
  companyCompassContents: ['company-compass-contents'],
  companyDetails: (companyId: number) => ['company-details', { companyId }],
  companyNpsFeedback: (companyId: number) => [
    'company-nps-feedback',
    { companyId },
  ],
  companyReferralFirms: (params?: GetReferralFirmsQuery) =>
    params ? ['company-referral-firms', params] : ['company-referral-firms'],
  compassPartners: (params?: { companyId: number }) =>
    params ? ['compass-partners', params] : ['compass-partners'],
  compassReportSharings: ['compass-report-sharings'],
  compassReportsWithActions: (params: GetCompassReportsWithActionsParams) =>
    params
      ? ['compass-reports-with-actions', params]
      : ['compass-reports-with-actions'],
  compassReportsWithBusinessSale: (
    params: GetCompassReportsWithBusinessSaleParams,
  ) =>
    params
      ? ['compass-reports-with-business-sale', params]
      : ['compass-reports-with-business-sale'],
  compassReportsWithMortgage: (params: GetCompassReportsWithMortgageParams) =>
    params
      ? ['compass-reports-with-mortgage', params]
      : ['compass-reports-with-mortgage'],
  compassReportsWithWillDetails: (
    params: GetCompassReportsWithWillDetailsParams,
  ) =>
    params
      ? ['compass-reports-with-will-details', params]
      : ['compass-reports-with-will-details'],
  compassReportsWithProtectionDetails: (
    params: GetCompassReportsWithProtectionDetailsParams,
  ) =>
    params
      ? ['compass-reports-with-protection-details', params]
      : ['compass-reports-with-protection-details'],
  compassReportsWithPensionDetails: (
    params: GetCompassReportsWithPensionDetailsParams,
  ) =>
    params
      ? ['compass-reports-with-pension-details', params]
      : ['compass-reports-with-pension-details'],
  compassReport: (token: string) => ['compass-report', { token }],
  companiesHouseSearch: ({ name }: { name: string }) => [
    'companies-house-search',
    { name },
  ],
  companyProfile: (
    params?: Pick<CompanyProfileSearchParams, 'identifierType' | 'identifier'>,
  ) => (params ? ['company-profile', params] : ['company-profile']),
  companyEmails: {
    all: ['company-emails'],
    compassInviteEmail: () => [
      QUERY_KEYS.companyEmails.all,
      { emailType: 'COMPASS_INVITE' },
    ],
  },
  companySmtpDetail: (companyId: number) => [
    'company-smtp-detail',
    { companyId },
  ],
  fcaSearch: ({ name }: { name: string }) => ['fca-search', { name }],
  latestCompassReport: ['latest-compass-report'],
  latestCompletedCompassReport: (clientId: number) => [
    'latest-completed-compass-report',
    { clientId },
  ],
  onboardingChecklist: () => ['onboarding-checklist'],
  panels: (params?: GetPanelsParams) =>
    params ? ['panels', params] : ['panels'],
  panelPartners: (params?: GetPanelPartnersParams) =>
    params ? ['panel-partners', params] : ['panel-partners'],
  panelApprovals: (fromCompanyId: number) => [
    'panel-approvals',
    { fromCompanyId },
  ],
  panel: (panelId?: number) => (panelId ? ['panels', { panelId }] : ['panels']),
  panelAgreements: (panelId?: number) =>
    panelId ? ['panel-agreements', { panelId }] : ['panel-agreements'],
  panelStats: () => ['panel-stats'],
  recommendedPartners: () => ['recommended-partners'],
  recommendedPartner: (companyId: number) => [
    'recommended-partners',
    { companyId },
  ],
  referClient: {
    all: ['refer-client'],
    referralFirm: (id: number) => ['referral-firm', { id }],
  },
  referralFirmIndividuals: (
    params?: GetReferralFirmIndividualsParams & { referralFirmId: number },
  ) =>
    params
      ? ['referral-firm-individuals', params]
      : ['referral-firm-individuals'],
  referralServices: (params?: GetReferralServiceAreaRequestParams) =>
    params ? ['referral-services', params] : ['referral-services'],
  referralCompanyTypes: () => ['referral-company-types'],
  referralWithESignatureDetails: (id: number) => [
    'referral-esignature',
    { id },
  ],
  rqCertifiedAssessment: ['rq-certified-assessment'],
  referralCommissionConsent: (id: number) => [
    'request-commission-consent',
    { id },
  ],
  referralFeedback: (id: number) => ['requestfeedback', { id }],
  referralSummary: (id: number) => ['referral-summary', { id }],
  referrals: () => ['referrals'],
  recentReferrals: () => ['recentReferrals'],
  referralNotes: (id: number) => ['referral-notes', { id }] as const,
  referralNoteMessages: (id: number) =>
    ['referral-note-messages', { id }] as const,
  reviews: (companyId: number) => ['reviews', { companyId }],
  sraSearch: ({ name }: { name: string }) => ['sra-search', { name }],
  userInvites: (params?: GetUserInvitesParams) =>
    params ? ['user-invites', params] : ['user-invites'],
}
