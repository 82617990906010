import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import get from 'lodash/get'
import queryString from 'query-string'
import { useParams, useSearchParams } from 'react-router-dom'

import { DATE_FORMATS } from '../../../../lib/constants'
import {
  capitalizeFirstLetter,
  formatDate,
  isConstraintViolationListError,
  isDevelopment,
  isStaging,
} from '../../../../lib/helpers/helperFunctions'
import { isRoute } from '../../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../../lib/routes'
import compassFlowService from '../../../../lib/services/compass/compassFlowService'
import { FormErrors, SelectOption } from '../../../../types/misc'
import { CompassMetadataItem } from '../../../../types/responses/compass'
import { TooltipHint } from '../../../form/FieldLabel'
import {
  COMPASS_API_ERRORS,
  COMPASS_INPUT_STEPS,
  COMPASS_PARAMS,
  COMPASS_STEP_NAMES,
  COMPASS_STEPS,
  WILL_FAMILY_PROTECTION_OPTION,
} from './constants'
import {
  BusinessAssetsInput,
  CompassParams,
  CompassStep,
  LiabilitiesInput,
  ShareInput,
} from './types'

export function willSellBusiness(businessAssets?: BusinessAssetsInput) {
  const shares =
    businessAssets?.shares && Array.isArray(businessAssets.shares)
      ? businessAssets.shares
      : []

  if (shares.length === 0) {
    return false
  }

  const wantsToSellShares = shares.some((share) => share.want_to_sell_shares)
  const hasProvidedSellYear = shares.some((share) => share.year)
  const hasProvidedExpectedSaleValue = shares.some(
    (share) => typeof share.expected_value === 'number',
  )
  const hasProvidedSaleValueConfidence = shares.some(
    (share) => typeof share.confidence === 'number',
  )

  return !!(
    businessAssets &&
    wantsToSellShares &&
    hasProvidedSellYear &&
    hasProvidedExpectedSaleValue &&
    hasProvidedSaleValueConfidence
  )
}

export function getCompleteShareInputs(
  businessAssets?: BusinessAssetsInput,
): ShareInput[] {
  const shares = businessAssets?.shares || []
  return shares.filter(isCompleteShareInput)
}

export function isCompleteShareInput(share: ShareInput) {
  const wantsToSellShares = share.want_to_sell_shares === true
  const hasProvidedSellYear = share.year
  const hasProvidedExpectedSaleValue = typeof share.expected_value === 'number'
  const hasProvidedSaleValueConfidence = typeof share.confidence === 'number'

  return !!(
    wantsToSellShares &&
    hasProvidedSellYear &&
    hasProvidedExpectedSaleValue &&
    hasProvidedSaleValueConfidence
  )
}

export function formatApiErrors(originalErrors: FormErrors): FormErrors {
  const messageReplacements: Record<string, string> = {
    [COMPASS_API_ERRORS.required]: 'This field is required.',
    [COMPASS_API_ERRORS.invalidFloat]: 'Must be a numeric value',
    [COMPASS_API_ERRORS.invalidInteger]: 'Must be a number',
  }

  const formattedErrors: FormErrors = {}

  for (const originalErrorFieldName in originalErrors) {
    const originalErrorMessage = get(originalErrors, originalErrorFieldName, '')

    const formattedMessage =
      messageReplacements[originalErrorMessage] || originalErrorMessage

    formattedErrors[originalErrorFieldName] = capitalizeFirstLetter(
      formattedMessage || '',
    )
  }

  return formattedErrors
}

export function hasFinancialDependant(
  liabilitiesInput: LiabilitiesInput,
): boolean {
  return (
    liabilitiesInput.has_financial_dependants === true &&
    Array.isArray(liabilitiesInput.financial_dependants) &&
    liabilitiesInput.financial_dependants.some(
      (financialDependant) =>
        !!financialDependant.financial_dependant_birth_month &&
        !!financialDependant.financial_dependant_birth_year,
    )
  )
}

export function normalizeClientDateOfBirth(
  dateOfBirth?: string | null,
): string {
  if (!dateOfBirth) {
    return ''
  }

  return formatDate(dateOfBirth, DATE_FORMATS.DAY_MONTH_YEAR)
}

export function useCompassParams(): CompassParams {
  const [searchParams] = useSearchParams()
  const params = useParams()

  const clientId = searchParams.get(COMPASS_PARAMS.clientId)
    ? Number(searchParams.get(COMPASS_PARAMS.clientId))
    : null

  const referralId = searchParams.get(COMPASS_PARAMS.referralId)
    ? Number(searchParams.get(COMPASS_PARAMS.referralId))
    : null

  const compassReportId = searchParams.get(COMPASS_PARAMS.compassReportId)
    ? Number(searchParams.get(COMPASS_PARAMS.compassReportId))
    : null

  const willShareWithClient =
    searchParams.get(COMPASS_PARAMS.willShareWithClient) === 'true'

  const token =
    params.token || searchParams.get(COMPASS_PARAMS.token) || undefined

  const companyReferralCode = params[COMPASS_PARAMS.companyReferralCode]

  const step = searchParams.get(COMPASS_PARAMS.step)

  const inviteClientsTab = searchParams.get(COMPASS_PARAMS.inviteClientsTab)

  const returnUrl = searchParams.get(COMPASS_PARAMS.returnUrl)

  const withCompany = searchParams.get(COMPASS_PARAMS.withCompany)
    ? Number(searchParams.get(COMPASS_PARAMS.withCompany))
    : undefined

  return {
    clientId,
    referralId,
    compassReportId,
    token,
    companyReferralCode,
    returnUrl: returnUrl,
    step,
    inviteClientsTab,
    willShareWithClient,
    withCompany,
  }
}

// The Python API accepts options in fully-formed label formats
// (e.g., "Income Tax" instead of something like "income_tax").
// This function creates an option object the uses the same value for
// both `label` and `value`.

export function makeOption(
  label: string,
  tooltipHint?: TooltipHint,
): SelectOption {
  return { label, value: label, tooltipHint }
}

export function buildUserCompassUrl(params: Partial<CompassParams>) {
  return `${ROUTES.userCompass}?${queryString.stringify(params)}`
}

// We only want to allow users to resume Compass from one of the info steps
// or the client details step. Letting them resume from a step beyond the client
// details will cause subtle issues (e.g., data in the prior input steps might
// have become invalid by recent edits so validation errors won't show).
export function isValidInitialUserCompassStep(
  step: string | null,
): step is CompassStep {
  return isValidCompassStep(step) && !isAdvancedCompassInputStep(step)
}

export function isValidInitialCompanyUserCompassStep(
  step: string | null,
): step is CompassStep {
  if (compassFlowService.isReadOnlyMode()) {
    return true
  }

  return (
    isValidInitialUserCompassStep(step) ||
    [COMPASS_STEPS.howToUse, COMPASS_STEPS.inviteClients].includes(
      step as CompassStep,
    )
  )
}

export function isAdvancedCompassInputStep(
  step: string | null,
): step is CompassStep {
  return (
    COMPASS_INPUT_STEPS.includes(step as CompassStep) &&
    step !== COMPASS_STEPS.clientDetails
  )
}

export function isValidCompassStep(step: string | null): step is CompassStep {
  return !!step && COMPASS_STEP_NAMES.includes(step as CompassStep)
}

export type ExistingCompanyClientError = AxiosError<{
  code: 'existing-company-client'
  message: string
}>

export function isExistingCompanyClientError(
  error: unknown,
): error is ExistingCompanyClientError {
  return (
    error instanceof AxiosError &&
    error.response?.status === StatusCodes.BAD_REQUEST &&
    error.response?.data?.code === 'existing-company-client'
  )
}

export function isUnauthenticatedClientFlow(metadata?: CompassMetadataItem) {
  return !!metadata?.isUnauthenticatedClient || isRoute(ROUTES.userCompass)
}

export function hasWillFamilyProtection(
  familyProtectionOptions: LiabilitiesInput['family_protection_options'],
) {
  return (
    familyProtectionOptions &&
    familyProtectionOptions.includes(WILL_FAMILY_PROTECTION_OPTION)
  )
}

export function shouldShowTestButtons() {
  return isDevelopment() || isStaging()
}

export function shouldRetryCompassRequest(
  failureCount: number,
  error: unknown,
): boolean {
  // Don't retry if it's a validation error
  if (isConstraintViolationListError(error)) {
    return false
  }

  // Otherwise, try 3 times
  return failureCount < 3
}
