import React from 'react'

import Markers from '../../components/Markers'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  postcodes: { postcode: string; name: string }[]
}

const Map: React.FC<Props> = ({ style, postcodes, ...props }) => {
  if (!postcodes.length) {
    return null
  }

  return (
    <div style={style} {...props}>
      <Markers postcodes={postcodes} />
    </div>
  )
}

export default Map
