import { NoContentResponse } from '../../types/api'
import apiService from './apiService'

interface ServicePostRequestParams {
  id?: number
  company: IRI
  serviceArea: IRI
}

interface FcaServicePostRequestParams extends ServicePostRequestParams {
  serviceType: IRI
  isStandaloneService: boolean
  notes: string | null
}

/**
 * Yes, "ServiceService"!
 */
class ServiceService {
  private serviceEndpoint = '/v1/services'
  private fcaServiceEdnpoint = '/v1/fca-services'

  public async postService(companyId: number, serviceAreaId: number) {
    const response = await apiService.post(this.serviceEndpoint, {
      company: `/v1/companies/${companyId}`,
      serviceArea: `/v1/service-areas/${serviceAreaId}`,
    })

    return response.data
  }

  public async postFcaService(
    companyId: number,
    serviceAreaId: number,
    isStandaloneService: boolean,
    notes: string | null,
    serviceType: IRI,
  ) {
    const response = await apiService.post(
      this.fcaServiceEdnpoint,
      this.buildPostOrPathParamsForFcaService(
        companyId,
        serviceAreaId,
        isStandaloneService,
        notes,
        serviceType,
      ),
    )

    return response.data
  }

  public async patchFcaService(
    serviceId: number,
    companyId: number,
    serviceAreaId: number,
    isStandaloneService: boolean,
    notes: string | null,
    serviceType: IRI,
  ) {
    const response = await apiService.patch(
      `${this.fcaServiceEdnpoint}/${serviceId}`,
      this.buildPostOrPathParamsForFcaService(
        companyId,
        serviceAreaId,
        isStandaloneService,
        notes,
        serviceType,
      ),
    )

    return response.data
  }

  private buildPostOrPathParamsForFcaService(
    companyId: number,
    serviceAreaId: number,
    isStandaloneService: boolean,
    notes: string | null,
    serviceTypeId: IRI,
  ): FcaServicePostRequestParams {
    return {
      company: `/v1/companies/${companyId}`,
      serviceArea: `/v1/service-areas/${serviceAreaId}`,
      isStandaloneService,
      notes,
      serviceType: serviceTypeId,
    }
  }

  public async deleteService(serviceId: number): Promise<NoContentResponse> {
    const respone = await apiService.delete<NoContentResponse>(
      `${this.serviceEndpoint}/${serviceId}`,
    )

    return respone.data
  }
}

const serviceService = new ServiceService()

export default serviceService
