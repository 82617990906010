import React, { useState } from 'react'

interface Props {
  content: string
  collapseThreshold?: number // The number of characters after which the content will be collapsed
  noCollapse?: boolean
  expandLabel?: string
  showToggleLabels?: boolean
}

const CollapsibleContent: React.FC<Props> = ({
  content,
  collapseThreshold = 430,
  noCollapse = false,
  expandLabel = ' ...read more',
  showToggleLabels = true,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggleIsCollapsed = () => setIsCollapsed((prevState) => !prevState)

  if (content.length < collapseThreshold || noCollapse) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  const readMoreLink = showToggleLabels
    ? `<button class='text-start btn btn-sm btn-link ps-0 text-muted' style="line-height: 1.4">${expandLabel}</button>`
    : `<span class='hover-underline'>...</span>`

  const collapsedContent = content.slice(0, collapseThreshold) + readMoreLink

  return (
    <>
      {isCollapsed ? (
        <div
          className="cursor-pointer"
          onClick={(event) => {
            event.stopPropagation()
            toggleIsCollapsed()
          }}
          title="Click to expand"
        >
          <div dangerouslySetInnerHTML={{ __html: collapsedContent }} />
        </div>
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {showToggleLabels && (
            <button
              className="btn btn-link btn-sm text-muted ps-0"
              onClick={(event) => {
                event.stopPropagation()
                toggleIsCollapsed()
              }}
            >
              Read less
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default CollapsibleContent
