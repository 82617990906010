import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import without from 'lodash/without'
import queryString from 'query-string'
import invariant from 'tiny-invariant'

import { getInitialValues as getInitialClientDetailsValues } from '../../components/flows/CompassFlow/steps/7_ClientDetails/helpers/helpers'
import {
  COMPASS_FORM_STEPS,
  COMPASS_INFO_STEPS,
  COMPASS_INPUT_STEPS,
  COMPASS_PARAMS,
  COMPASS_STEP_NAMES,
  COMPASS_STEPS,
  CompassSteps,
} from '../../components/flows/CompassFlow/utils/constants'
import {
  isAdvancedCompassInputStep,
  isUnauthenticatedClientFlow,
  isValidInitialCompanyUserCompassStep,
  isValidInitialUserCompassStep,
  willSellBusiness,
} from '../../components/flows/CompassFlow/utils/helpers'
import {
  AboutYourClientInput,
  CompassInput,
  CompassStep,
} from '../../components/flows/CompassFlow/utils/types'
import { ClientSelectOption } from '../../components/select/SelectClient'
import { DATE_FORMATS } from '../../lib/constants'
import {
  getNextStep,
  getPreviousStep,
  isValidDateFormat,
} from '../../lib/helpers/helperFunctions'
import { isRoute } from '../../lib/helpers/routeHelpers'
import { ROUTES } from '../../lib/routes'
import compassClientDetailsService from '../../lib/services/compass/compassClientDetailsService'
import compassFeedbackService from '../../lib/services/compass/compassFeedbackService'
import compassFlowService from '../../lib/services/compass/compassFlowService'
import compassInterestsService from '../../lib/services/compass/compassInterestsService'
import compassQuestionsService from '../../lib/services/compass/compassQuestionService'
import compassQuestionService from '../../lib/services/compass/compassQuestionService'
import compassReportService from '../../lib/services/compass/compassReportService'
import compassService from '../../lib/services/compass/compassService'
import {
  CompassMetadataItem,
  CompassScores,
} from '../../types/responses/compass'
import {
  CompassReportItem,
  CreateBulkCompassReportsResponseItem,
} from '../../types/responses/compass-reports'
import { SessionMetadataUser } from '../../types/responses/session-metadata'
import { RootState } from '../store'
import {
  selectIsAuthenticated,
  selectIsAuthenticatedClient,
  selectIsCompanyUser,
} from './session'

export type CompassState = {
  version: number
  currentStep: CompassStep
  metadata?: CompassMetadataItem
  input: CompassInput
  existingClientSelection: ClientSelectOption | null
  companyClient?: CompanyClient
  compassReport?: CompassReportItem
  scores: CompassScores
  compassReportInvites?: CreateBulkCompassReportsResponseItem['compassReports']
  hasUnsavedChanges: boolean
  accessToken?: string // Only applicable when clients must verify their identity (dob or email code)
  shouldShowCreateAccountButton: boolean
  isShowingConfirmStartModal: boolean
  emailValidationCode?: string
  hasVerifiedEmail: boolean
}

export type CompanyClient = { '@id': IRI; id: number }

export type CompassSaveState = {
  input: CompassInput
  scores?: CompassScores
  version: number
}

// NOTE: If you make any changes to the data format or rename fields, increment
// this version number and make a migration script in the API:
// https://github.com/RQ-Ratings/RQ-API/tree/dev/src/Service/MigrateCompass/Version
const COMPASS_VERSION = 17

const INITIAL_STATE: CompassState = {
  version: COMPASS_VERSION,
  currentStep: COMPASS_STEPS.aboutCompass,
  input: {},
  existingClientSelection: null,
  scores: {
    '@context': '',
    '@id': '',
    '@type': '',
    feedback: [],
    outlook: {
      total: {},
    },
    monthlyPayments: {
      mortgages: {
        user: null,
        partner: null,
        joint: null,
      },
      personal_loans: {
        user: null,
        partner: null,
        joint: null,
      },
    },
    onTrackResilience: { score: 0, text: '' },
    organisedOptimised: { score: 0, text: '', rules_true: [] },
    financialHealth: { score: 0, text: '' },
    csv: '',
  },
  hasUnsavedChanges: false,
  shouldShowCreateAccountButton: false,
  isShowingConfirmStartModal: false,
  hasVerifiedEmail: false,
}

const compass = createSlice({
  name: 'compass',
  initialState: INITIAL_STATE,
  reducers: {
    initializeCompass(
      state,
      action: PayloadAction<{
        metadata: CompassMetadataItem
        currentUser?: SessionMetadataUser
        searchParams: string
      }>,
    ) {
      const payload = action.payload
      const { metadata, searchParams, currentUser } = payload

      state.metadata = metadata
      state.currentStep = determineInitialStep({ metadata, searchParams })

      // If the user is authenticated, we can pre-populate the client details
      if (metadata.isAuthenticatedClient && currentUser) {
        state.input.aboutYourClient = {
          ...state.input.aboutYourClient,
          ...buildClientDetailsFromUser(currentUser),
        }
      }
    },

    loadCompassReport(
      state,
      action: PayloadAction<{
        compassReport?: CompassReportItem
        step?: CompassStep
      }>,
    ) {
      const { compassReport, step } = action.payload

      state.compassReport = compassReport

      // Pre-populate the inputs
      if (compassReport && compassReport?.jsonPayload) {
        if (compassReport.jsonPayload.input) {
          state.input = compassReport.jsonPayload.input
        }

        if (compassReport.jsonPayload.scores) {
          state.scores = compassReport.jsonPayload.scores
        }

        state.version = compassReport.jsonPayload.version
      }

      if (compassReport?.client) {
        state.companyClient = {
          '@id': compassReport.client['@id'],
          id: compassReport.client.id,
        }
      }

      if (step) {
        state.currentStep = step
      }
    },

    clearCompassInput(state) {
      state.input = {
        aboutYourClient: getInitialClientDetailsValues(),
      }
    },

    setCompanyClient(
      state,
      action: PayloadAction<CompassState['companyClient']>,
    ) {
      state.companyClient = action.payload
    },

    resetCompass(
      state,
      action: PayloadAction<Partial<CompassState> | undefined>,
    ) {
      const newState = action.payload || {}

      return {
        metadata: state.metadata, // Keep the metadata
        ...INITIAL_STATE,
        ...newState,
      }
    },

    setClientDateOfBirth(state, action: PayloadAction<string>) {
      const dateOfBirth = action.payload

      if (!isValidDateFormat(dateOfBirth, DATE_FORMATS.DAY_MONTH_YEAR)) {
        console.warn(`Invalid date of birth format: ${dateOfBirth}`)
        return state
      }

      const clientDetails = state.input.aboutYourClient

      return {
        ...state,
        input: {
          ...state.input,
          aboutYourClient: {
            ...clientDetails,
            user_date_of_birth: dateOfBirth,
          },
        },
      }
    },

    setCompassMetadata(state, action: PayloadAction<CompassMetadataItem>) {
      state.metadata = action.payload
    },

    goToStep(state, action: PayloadAction<CompassStep>) {
      state.currentStep = action.payload
    },

    setCompassInput(state, action: PayloadAction<Partial<CompassInput>>) {
      state.input = {
        ...state.input,
        ...action.payload,
      }

      if (compassFlowService.isEditMode(state.compassReport?.status)) {
        state.hasUnsavedChanges = true
      }
    },

    setExistingClientSelection(
      state,
      action: PayloadAction<ClientSelectOption | null>,
    ) {
      state.existingClientSelection = action.payload
    },

    setCompassReport(state, action: PayloadAction<CompassReportItem>) {
      state.compassReport = action.payload
    },

    setScores(state, action: PayloadAction<CompassScores>) {
      state.scores = action.payload
    },

    setCompassReportPrivacy(state, action: PayloadAction<boolean>) {
      if (state.compassReport) {
        state.compassReport.isPrivate = action.payload
      }
    },

    setCompassReportInvites(
      state,
      action: PayloadAction<CompassState['compassReportInvites']>,
    ) {
      state.compassReportInvites = action.payload
    },

    clearCompassReportInvites(state) {
      state.compassReportInvites = undefined
    },

    startCompass(_state, action: PayloadAction<CompassStep>) {
      return {
        ...INITIAL_STATE,
        currentStep: action.payload,
      }
    },

    setHasUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.hasUnsavedChanges = action.payload
    },

    setUserDetails(
      state,
      action: PayloadAction<{
        currentUser: SessionMetadataUser
      }>,
    ) {
      const { currentUser } = action.payload

      const clientDetails: Partial<AboutYourClientInput> = {
        user_name_first: currentUser.firstName,
        user_name_last: currentUser.lastName,
        user_email: currentUser.email,
      }

      state.input = {
        ...state.input,
        aboutYourClient: {
          ...state.input.aboutYourClient,
          ...clientDetails,
        },
      }
    },

    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },

    setEmailValidationCode(state, action: PayloadAction<string>) {
      state.emailValidationCode = action.payload
    },

    setHasVerifiedEmail(state, action: PayloadAction<boolean>) {
      state.hasVerifiedEmail = action.payload
    },

    setShouldShowCreateAccountButton(state, action: PayloadAction<boolean>) {
      state.shouldShowCreateAccountButton = action.payload
    },

    showConfirmStartModal(state) {
      state.isShowingConfirmStartModal = true
    },

    hideConfirmStartModal(state) {
      state.isShowingConfirmStartModal = false
    },
  },
})

export const {
  initializeCompass,
  goToStep,
  setCompassInput,
  setScores,
  setClientDateOfBirth,
  setExistingClientSelection,
  setCompanyClient,
  setCompassReport,
  setCompassReportPrivacy,
  setCompassReportInvites,
  clearCompassReportInvites,
  clearCompassInput,
  resetCompass,
  startCompass,
  loadCompassReport,
  setHasUnsavedChanges,
  setUserDetails,
  setAccessToken,
  setEmailValidationCode,
  setHasVerifiedEmail,
  setShouldShowCreateAccountButton,
  showConfirmStartModal,
  hideConfirmStartModal,
} = compass.actions

export const selectCompass = (state: RootState) => state.compass

export const selectCompassState = createSelector(
  selectCompass,
  (compass): CompassSaveState => {
    return {
      input: compass.input,
      scores: compass.scores,
      version: compass.version,
    }
  },
)
export const selectCompassInput = createSelector(
  selectCompass,
  (compass) => compass.input,
)

export const selectCompassScores = createSelector(
  selectCompass,
  (compass) => compass.scores,
)

export const selectCompassFeedback = createSelector(
  selectCompassScores,
  (scores) => scores.feedback,
)

export const selectCompassActionsFeedback = createSelector(
  selectCompassFeedback,
  (feedback) => compassFeedbackService.getActions(feedback),
)

export const selectMonthlyPayments = createSelector(
  selectCompassScores,
  (scores) => scores.monthlyPayments,
)

export const selectUserCurrentMonthlyMortgagePayment = createSelector(
  selectMonthlyPayments,
  (monthlyPayments) => monthlyPayments.mortgages.user,
)

export const selectCurrentStep = createSelector(
  selectCompass,
  (compass): CompassStep => compass.currentStep,
)

export const selectCompassReport = createSelector(
  selectCompass,
  (compass) => compass.compassReport,
)

export const selectCompassReportOrFail = createSelector(
  selectCompassReport,
  (compassReport) => {
    invariant(compassReport, 'Expected compassReport to be defined')

    return compassReport
  },
)

export const selectIsCompassEditMode = createSelector(
  selectCompassReport,
  (compassReport) => compassFlowService.isEditMode(compassReport?.status),
)

export const selectIsCompassReadOnlyMode = createSelector(
  selectCompassReport,
  (compassReport) => compassFlowService.isReadOnlyMode(compassReport?.status),
)

export const selectCompassMode = createSelector(
  selectIsCompassEditMode,
  selectIsCompassReadOnlyMode,
  (isEditMode, isReadOnlyMode) => {
    return {
      isEditMode,
      isReadOnlyMode,
    }
  },
)

export const selectIsNewCompassReport = createSelector(
  selectCompassReport,
  (compassReport) => !compassReport,
)

export const selectCompanyClient = createSelector(
  selectCompass,
  (compass) => compass.companyClient,
)

export const selectIsCompassReportOwnedByCompany = createSelector(
  selectCompassReport,
  selectIsCompanyUser,
  selectIsNewCompassReport,
  (compassReport, isCompanyUser, isNewCompassReport) => {
    return (
      compassReport?.isOwnedByCompany === true ||
      (isCompanyUser && isNewCompassReport)
    )
  },
)

export const selectIsCompassReportOwnedByUser = createSelector(
  selectCompassReport,
  (compassReport) => compassReport?.isOwnedByUser === true,
)

export const selectIsExistingCompassReport = createSelector(
  selectCompassReport,
  (compassReport) => !!compassReport,
)

export const selectIsInfoStep = createSelector(
  selectCurrentStep,
  (currentStep) => COMPASS_INFO_STEPS.includes(currentStep),
)

export const selectIsInputStep = createSelector(
  selectCurrentStep,
  (currentStep) => COMPASS_INPUT_STEPS.includes(currentStep),
)

export const selectIsFormStep = createSelector(
  selectCurrentStep,
  (currentStep) => COMPASS_FORM_STEPS.includes(currentStep),
)

export const selectExistingClientSelection = createSelector(
  selectCompass,
  (compass) => compass.existingClientSelection,
)

export const selectClientDetails = createSelector(
  selectCompassInput,
  (compassData) => {
    return compassData.aboutYourClient
  },
)

export const selectClientAndPartnerName = createSelector(
  selectClientDetails,
  (clientDetails) => {
    invariant(
      clientDetails,
      'Can only use this selector once client details are available',
    )

    return `${clientDetails.user_name_first} and ${clientDetails.partner_name_first}`
  },
)

export const selectPartnerName = createSelector(
  selectClientDetails,
  (clientDetails) => clientDetails?.partner_name_first,
)

export const selectPartnerOrSpouseTerm = createSelector(
  selectClientDetails,
  (clientDetails) =>
    compassService.getPartnerOrSpouseTerm(
      clientDetails?.user_private_situation,
    ),
)

export const selectShouldShowPartnerQuestions = createSelector(
  selectClientDetails,
  (clientDetails) =>
    compassQuestionService.shouldShowPartnerQuestions(
      clientDetails?.user_private_situation,
    ),
)

export const selectProfessionalSituation = createSelector(
  selectClientDetails,
  (clientDetails) => clientDetails?.user_professional_situation,
)

export const selectBusinessAssetsTerm = createSelector(
  selectProfessionalSituation,
  (professionalSituation) => {
    return compassReportService.getBusinessAssetsTerm(professionalSituation)
  },
)

export const selectQuestions = createSelector(selectCompassInput, (input) =>
  compassQuestionsService.getQuestions(input),
)

export const selectUsageType = createSelector(
  selectCompassInput,
  (compassData) => compassData.usageType,
)

export const selectPersonalAssets = createSelector(
  selectCompassInput,
  (compassData) => compassData.personalAssets,
)

export const selectLiabilities = createSelector(
  selectCompassInput,
  (compassData) => compassData.liabilities,
)

export const selectBusinessAssets = createSelector(
  selectCompassInput,
  (compassData) => compassData.businessAssets,
)

export const selectInterests = createSelector(
  selectCompassInput,
  (compassData) => compassData.interests,
)

export const selectIncome = createSelector(
  selectCompassInput,
  (compassData) => compassData.income,
)

export const selectFinancialIndependenceAge = createSelector(
  selectInterests,
  (interests) =>
    compassInterestsService.getUserFinancialIndependenceAge(interests),
)

export const selectWillSellBusiness = createSelector(
  selectBusinessAssets,
  (businessAssets) => willSellBusiness(businessAssets),
)

export const selectCompassReportInvites = createSelector(
  selectCompass,
  (compass) => compass.compassReportInvites,
)

export const selectCompassMetadata = createSelector(
  selectCompass,
  (compass) => compass.metadata,
)

export const selectIsBusinessOwnerOrPartner = createSelector(
  selectProfessionalSituation,
  (professionalSituation) =>
    compassClientDetailsService.isBusinessOwnerOrPartner(professionalSituation),
)

export const selectHasVerifiedEmail = createSelector(
  selectCompass,
  (compass) => compass.hasVerifiedEmail,
)

export const selectAccessToken = createSelector(
  selectCompass,
  (compass) => compass.accessToken,
)

export const selectHasAccessToken = createSelector(
  selectAccessToken,
  (accessToken) => !!accessToken,
)

export const selectInputSteps = createSelector(
  selectIsAuthenticated,
  selectHasVerifiedEmail,
  selectHasAccessToken,
  selectIsBusinessOwnerOrPartner,
  (
    isAuthenticated,
    hasVerifiedEmail,
    hasAccessToken,
    isBusinessOwnerOrPartner,
  ) => {
    let inputSteps = COMPASS_STEP_NAMES.slice()

    inputSteps = without(
      inputSteps,
      COMPASS_STEPS.welcome,
      COMPASS_STEPS.aboutCompass,
      COMPASS_STEPS.requiredDetails,
      COMPASS_STEPS.howToUse,
      COMPASS_STEPS.verifyIdentity,
      COMPASS_STEPS.usageType,
      COMPASS_STEPS.inviteClients,
    )

    if (isAuthenticated || hasVerifiedEmail || hasAccessToken) {
      inputSteps = without(inputSteps, COMPASS_STEPS.verifyEmail)
    }

    // Don't include business assets step if client is not a business owner / partner
    if (!isBusinessOwnerOrPartner) {
      inputSteps = without(inputSteps, COMPASS_STEPS.businessAssets)
    }

    return inputSteps
  },
)

export interface StepIndicator {
  name: CompassStep
  readableNumber: number
  index: number
  hasVisited: boolean
  canJumpToStep: boolean
}

export const selectIsLastStep = createSelector(
  selectCurrentStep,
  selectInputSteps,
  (currentStep, inputSteps) => {
    return currentStep === inputSteps[inputSteps.length - 1]
  },
)

export const selectHasUnsavedChanges = createSelector(
  selectCompass,
  (compass) => compass.hasUnsavedChanges,
)

export const selectHasSavedAllChanges = createSelector(
  selectCompass,
  (compass) => !compass.hasUnsavedChanges,
)

export const selectShouldVerifyIdentity = createSelector(
  selectCompassReport,
  selectAccessToken,
  (compassReport, accessToken) => {
    return (
      compassReport?.shouldVerifyIdentity === true &&
      typeof accessToken === 'undefined' // Don't require user to re-verify if they've already done so
    )
  },
)

export const selectVerifyIdentityType = createSelector(
  selectCompassReport,
  (compassReport) => {
    return compassReport?.isPrivate === true ? 'code' : 'dateOfBirth'
  },
)

export const selectInfoSteps = createSelector(
  selectIsAuthenticatedClient,
  selectShouldVerifyIdentity,
  (isAuthenticatedClient, shouldVerifyIdentity) => {
    let steps = COMPASS_INFO_STEPS

    if (isAuthenticatedClient) {
      steps = without(steps, COMPASS_STEPS.welcome)
    }

    if (!shouldVerifyIdentity) {
      steps = without(steps, COMPASS_STEPS.verifyIdentity)
    }

    return steps
  },
)

export const selectIsUnauthenticatedClient = createSelector(
  selectCompassMetadata,
  (metadata) => !!metadata?.isUnauthenticatedClient,
)

export const selectIsClient = createSelector(
  selectCompassMetadata,
  (metadata) =>
    !!metadata?.isUnauthenticatedClient || !!metadata?.isAuthenticatedClient,
)

export const selectNextInputStep = createSelector(
  selectInputSteps,
  selectCurrentStep,
  (inputSteps, currentStep) =>
    getNextStep<CompassSteps>(inputSteps, currentStep),
)

export const selectPreviousInputStep = createSelector(
  selectInputSteps,
  selectCurrentStep,
  (inputSteps, currentStep) =>
    getPreviousStep<CompassSteps>(inputSteps, currentStep),
)

export const selectNextStep = createSelector(
  selectInputSteps,
  selectInfoSteps,
  selectIsInputStep,
  selectCurrentStep,
  (inputSteps, infoSteps, isInputStep, currentStep) => {
    return getNextStep<CompassSteps>(
      isInputStep ? inputSteps : infoSteps,
      currentStep,
    )
  },
)

export const selectSteps = createSelector(
  selectInputSteps,
  selectInfoSteps,
  selectIsInputStep,
  (inputSteps, infoSteps, isInputStep) => {
    return isInputStep ? inputSteps : infoSteps
  },
)

export const selectPreviousStep = createSelector(
  selectSteps,
  selectCurrentStep,
  (steps, currentStep) => getPreviousStep<CompassSteps>(steps, currentStep),
)

export const selectPreviousFlowStep = createSelector(
  selectCurrentStep,
  (currentStep) =>
    getPreviousStep<CompassSteps>(COMPASS_STEP_NAMES, currentStep),
)

export const selectCurrentStepIndex = createSelector(
  selectInputSteps,
  selectCurrentStep,
  (inputSteps, currentStep) => inputSteps.indexOf(currentStep),
)

export const selectIsCompanyUserFlow = createSelector(
  selectCompassMetadata,
  (metadata) => !!metadata?.isCompanyUser && isRoute(ROUTES.companyCompass),
)

export const selectIsClientFlow = createSelector(
  selectCompassMetadata,
  (metadata) => {
    // Need to check the route too because we could have a company user who is
    // using a client link (e.g., a invite link they sent to the client).
    return !!metadata?.isClient || isRoute(ROUTES.userCompass)
  },
)

export const selectStepIndicatorSteps = createSelector(
  selectInputSteps,
  (inputSteps) => {
    let steps = inputSteps.slice()

    steps = without(
      steps,
      COMPASS_STEPS.verifyIdentity,
      COMPASS_STEPS.verifyEmail,
      COMPASS_STEPS.results,
    )

    return steps
  },
)

export const selectStepIndicators = createSelector(
  selectStepIndicatorSteps,
  selectCurrentStep,
  selectIsCompassReadOnlyMode,
  (steps, currentStep, isReadOnlyMode): StepIndicator[] => {
    const isResultsStep = currentStep === COMPASS_STEPS.results
    const currentStepIndex = steps.indexOf(currentStep)

    return steps.map((step, index): StepIndicator => {
      const stepNumber = index + 1

      const canJumpToStep =
        isReadOnlyMode || isResultsStep || currentStepIndex > index

      return {
        name: step,
        index,
        readableNumber: stepNumber,
        hasVisited: currentStepIndex >= index || isResultsStep,
        canJumpToStep,
      }
    })
  },
)

export const selectShouldShowCreateAccountButton = createSelector(
  selectCompass,
  (compass) => compass.shouldShowCreateAccountButton,
)

export const selectIsUnauthenticatedClientFlow = createSelector(
  selectCompassMetadata,
  (metadata) => isUnauthenticatedClientFlow(metadata),
)

export const selectIsCompanyUserActingAsClient = createSelector(
  selectCompassMetadata,
  (metadata) =>
    !!metadata?.isCompanyUser && isUnauthenticatedClientFlow(metadata),
)

export const selectIsShowingConfirmStartModal = createSelector(
  selectCompass,
  (compass) => compass.isShowingConfirmStartModal,
)

export const selectEmailValidationCode = createSelector(
  selectCompass,
  (compass) => compass.emailValidationCode,
)

export const compassReducer = compass.reducer

// TODO: Add unit tests
function determineInitialStep(options: {
  metadata: CompassMetadataItem
  searchParams: string
}): CompassStep {
  const { metadata, searchParams } = options
  const searchParamsObj = queryString.parse(searchParams)
  const searchParamStep = searchParamsObj[COMPASS_PARAMS.step] as string | null

  if (isAdvancedCompassInputStep(searchParamStep)) {
    return COMPASS_STEPS.clientDetails
  }

  if (metadata.isCompanyUser) {
    if (isValidInitialCompanyUserCompassStep(searchParamStep)) {
      return searchParamStep
    }

    return isRoute(ROUTES.userCompass)
      ? COMPASS_STEPS.welcome
      : COMPASS_STEPS.usageType
  }

  if (isValidInitialUserCompassStep(searchParamStep)) {
    return searchParamStep
  }

  if (metadata.isAuthenticatedClient) {
    return COMPASS_STEPS.aboutCompass
  }

  if (metadata.isUnauthenticatedClient) {
    return COMPASS_STEPS.welcome
  }

  throw new Error("Couldn't determine initial step")
}

export function buildClientDetailsFromUser(user: SessionMetadataUser) {
  const clientDetails: Partial<AboutYourClientInput> = {
    user_name_first: user.firstName,
    user_name_last: user.lastName,
    user_email: user.email,
  }

  return clientDetails
}
