import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMessage, faPhone } from '@rq-ratings/pro-light-svg-icons'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import useModal from '../../../../../hooks/useModal'
import companyMessageService from '../../../../../lib/services/companyMessageService'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import ContactCompanyModal from '../ContactCompanyModal'

interface Props {
  company: CompanyProfileItem['company']
}

const ContactCompanyDropdown: React.FC<Props> = ({ company }) => {
  const isCompanyMessagingEnabledQuery = useQuery({
    queryKey: ['company-message-default', { toCompanyId: company?.id }],
    queryFn: () => {
      const companyId = company?.id ?? 0
      return companyMessageService.getCompanyMessageEnabledStatus(companyId)
    },
  })

  const scheduleCallUrl = company?.scheduleCompanyCallUrl

  const {
    isShowingModal: isShowingContactCompanyModal,
    showModal: showContactCompanyModal,
    hideModal: hideContactCompanyModal,
  } = useModal()

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          id="bg-nested-dropdown"
          size="lg"
          variant="outline-dark"
          className="btn-lg flex-grow-1 w-100"
        >
          <span className="me-2">
            <FontAwesomeIcon icon={faMessage} className="me-2 align-middle" />
            Contact company
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-lg w-100">
          {/* Schedule call button */}
          <Dropdown.Item
            eventKey="1"
            disabled={!scheduleCallUrl}
            href={scheduleCallUrl ?? '#'}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPhone} className="me-2" />
            Schedule Call
          </Dropdown.Item>

          {/* Message button */}
          <Dropdown.Item
            eventKey="2"
            disabled={
              company == null ||
              isCompanyMessagingEnabledQuery.isLoading ||
              !isCompanyMessagingEnabledQuery?.data?.enabled
            }
            onClick={showContactCompanyModal}
          >
            <FontAwesomeIcon icon={faEnvelope} className="me-2" />
            Message
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {isShowingContactCompanyModal && (
        <ContactCompanyModal
          toCompanyId={company?.id ?? 0}
          toCompanyName={company?.name ?? ''}
          handleClose={hideContactCompanyModal}
        />
      )}
    </>
  )
}

export default ContactCompanyDropdown
