import { createSelector, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface CompleteRegistrationPopupState {
  isEditInProgress: boolean
}

const INITIAL_STATE: CompleteRegistrationPopupState = {
  isEditInProgress: false,
}

const completeRegistrationPopup = createSlice({
  name: 'completeRegistrationPopup',
  initialState: INITIAL_STATE,
  reducers: {
    startEdit: (state) => {
      state.isEditInProgress = true
    },
    endEdit: (state) => {
      state.isEditInProgress = false
    },
  },
})

export const { startEdit, endEdit } = completeRegistrationPopup.actions

const selectCompleteRegistrationPopup = (state: RootState) =>
  state.completeRegistrationPopup

export const selectCompleteRegistrationPopupIsEditInProgress = createSelector(
  selectCompleteRegistrationPopup,
  (completeRegistrationPopup) => completeRegistrationPopup.isEditInProgress,
)

export const completeRegistrationPopupReducer =
  completeRegistrationPopup.reducer
