import GoogleMapReact from 'google-map-react'
import React, { useState } from 'react'

const Markers = (props) => {
  const [position, setPosition] = useState({
    lat: 52.34306587013695,
    lng: -1.3514258741423504,
  })

  const getMapOptions = (maps) => {
    return {
      mapId: 'RQ_PROFILE_MAP',
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControlOptions: { mapTypeIds: [] },
    }
  }

  const renderMarkers = async (map, maps) => {
    let geocoder = new maps.Geocoder()
    const { AdvancedMarkerElement, PinElement } =
      await google.maps.importLibrary('marker')

    props.postcodes.map((postcode) => {
      return geocoder.geocode(
        {
          componentRestrictions: { postalCode: postcode.postcode },
          country: 'UK',
        },
        function (results, status) {
          if (status === maps.GeocoderStatus.OK) {
            // get the LatLng of the postalcode
            var result = results[0].geometry.location
            let marker = {
              lat: result.lat(),
              lng: result.lng(),
            }
            new AdvancedMarkerElement({
              content: new PinElement({
                background: '#000000',
                borderColor: 'transparent',
                glyphColor: '#EFF2F6',
                scale: 0.8,
              }).element,
              position: marker,
              map,
              title: postcode.name,
            })
            setPosition(marker)
          }
        },
      )
    })
  }

  return (
    <GoogleMapReact
      options={getMapOptions}
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{
        key: import.meta.env.VITE_GOOGLE_MAPS_KEY,
      }}
      center={position}
      disableAutoPan={true}
      defaultZoom={props.postcodes.length > 1 ? 6 : 15}
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  )
}

export default Markers
