import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faRemove } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { FormControl, Spinner } from 'react-bootstrap'
import * as yup from 'yup'

import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import addressService from '../../../../../../lib/services/addressService'
import ActionButton from '../../../../../misc/ActionButton'
import CompleteRegistrationFormikDirtyNavigationGuard from '../../../CompleteRegistrationFormikDirtyNavigationGuard'

export interface AddressFormValues {
  id?: number
  street: string
  city: string
  county: string
  postcode: string
  telephone: string
  isMainAddress: boolean
}

interface Props {
  address: AddressFormValues
  isEditingByDefault: boolean
  onSave: (values: AddressFormValues) => void
  onDelete: () => void
  allowDelete: boolean
}

const CompanyAddressRow: React.FC<Props> = ({
  address,
  isEditingByDefault,
  onSave,
  onDelete,
  allowDelete,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(isEditingByDefault)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const currentCompany = useCurrentCompanyOrFail()

  const addOrUpdateMutation = useMutation({
    mutationFn: async (values: AddressFormValues) => {
      const params = {
        company: currentCompany['@id'],
        ...values,
      }

      return address.id === undefined
        ? addressService.postAddress(params)
        : addressService.patchAddress(address.id, params)
    },
    onSuccess: () => {
      setIsEditing(false)
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (addressId: number) => {
      return addressService.deleteAddress(addressId)
    },
    onSuccess: () => {
      onDelete()
    },
    onError: () => {
      setIsDeleting(false)
    },
  })

  return (
    <div
      className={`company-address-row${isDeleting ? ' deleting' : ''} text-start`}
    >
      {!isEditing && (
        <button
          onClick={() => setIsEditing(true)}
          className="card border p-3 m-0 d-flex flex-row gap-3 text-start w-100"
        >
          <div className="flex-grow-1">
            <h3 className="h4 m-0">{address.street}</h3>

            <p className="m-0">
              {[address.city, address.county, address.postcode]
                .filter((part) => !!part)
                .join(', ')}
            </p>
          </div>

          {/* Not a button because we're already inside a button that does the same thing, this
            is just in case it's not clear to the user that they can just click the card */}
          <div className="btn btn-lg btn-outline-primary align-self-center text-nowrap">
            <FontAwesomeIcon icon={faPencil} className="me-2" /> Edit
          </div>
        </button>
      )}

      {isEditing && (
        <Formik
          initialValues={address}
          enableReinitialize
          validateOnChange
          validationSchema={yup.object().shape({
            id: yup.number().min(0).nullable(),
            street: yup.string().required('Required'),
            county: yup.string().required('Required'),
            city: yup.string().required('Required'),
            postcode: yup.string().required('Required'),
            telephone: yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await addOrUpdateMutation.mutateAsync(values)

            setSubmitting(false)

            onSave(values)
          }}
        >
          {({ isSubmitting, submitForm, errors, touched }) => (
            <Form
              className={`complete-registration-address-form m-0 row border p-3 ${address.id === undefined ? 'pop-in' : ''}`}
            >
              <CompleteRegistrationFormikDirtyNavigationGuard />

              <div className="col-md-6 col-sm-10 mb-3">
                <label
                  className="d-block form-label"
                  htmlFor={`${address.id ?? 'new'}-street`}
                >
                  Street
                </label>

                <Field
                  name="street"
                  id={`${address.id ?? 'new'}-street`}
                  className={classNames('form-control', {
                    'is-invalid': errors.street && touched.street,
                  })}
                />

                {errors.street && touched.street ? (
                  <FormControl.Feedback type="invalid">
                    {errors.street}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="col-md-6 col-sm-10 mb-3">
                <label
                  className="d-block form-label"
                  htmlFor={`${address.id ?? 'new'}-city`}
                >
                  City
                </label>

                <Field
                  name="city"
                  id={`${address.id ?? 'new'}-city`}
                  className={classNames('form-control', {
                    'is-invalid': errors.city && touched.city,
                  })}
                />

                {errors.city && touched.city ? (
                  <FormControl.Feedback type="invalid">
                    {errors.city}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="col-md-6 col-sm-10 mb-3">
                <label
                  className="d-block form-label"
                  htmlFor={`${address.id ?? 'new'}-county`}
                >
                  County
                </label>

                <Field
                  name="county"
                  id={`${address.id ?? 'new'}-county`}
                  className={classNames('form-control', {
                    'is-invalid': errors.county && touched.county,
                  })}
                />

                {errors.county && touched.county ? (
                  <FormControl.Feedback type="invalid">
                    {errors.county}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="col-md-6 col-sm-10 mb-3">
                <label
                  className="d-block form-label"
                  htmlFor={`${address.id ?? 'new'}-postcode`}
                >
                  Postcode
                </label>

                <Field
                  name="postcode"
                  id={`${address.id ?? 'new'}-postcode`}
                  className={classNames('form-control', {
                    'is-invalid': errors.postcode && touched.postcode,
                  })}
                />

                {errors.postcode && touched.postcode ? (
                  <FormControl.Feedback type="invalid">
                    {errors.postcode}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="col-md-6 col-sm-10">
                <label
                  className="d-block form-label"
                  htmlFor={`${address.id ?? 'new'}-telephone`}
                >
                  Telephone
                </label>

                <Field
                  name="telephone"
                  id={`${address.id ?? 'new'}-telephone`}
                  className={classNames('form-control', {
                    'is-invalid': errors.telephone && touched.telephone,
                  })}
                />

                {errors.telephone && touched.telephone ? (
                  <FormControl.Feedback type="invalid">
                    {errors.telephone}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="col-md-6 col-sm-10">
                <div className="form-check mt-4">
                  <Field
                    name="isMainAddress"
                    id={`${address.id ?? 'new'}-isMainAddress`}
                    type="checkbox"
                    className={classNames('form-check-input', {
                      'is-invalid':
                        errors.isMainAddress && touched.isMainAddress,
                    })}
                  />

                  <label
                    className="d-block form-check-label"
                    htmlFor={`${address.id ?? 'new'}-isMainAddress`}
                  >
                    Main address
                  </label>
                </div>

                {errors.isMainAddress && touched.isMainAddress ? (
                  <FormControl.Feedback type="invalid">
                    {errors.isMainAddress}
                  </FormControl.Feedback>
                ) : null}
              </div>

              <div className="buttons-row d-flex justify-content-end align-items-end gap-3">
                {allowDelete && (
                  <ActionButton
                    isProcessing={isDeleting}
                    isProcessingText="Removing"
                    showSpinner={isDeleting}
                    variant="outline-secondary"
                    size="lg"
                    onClick={() => {
                      if (!isDeleting) {
                        setIsDeleting(true)

                        if (address.id !== undefined) {
                          deleteMutation.mutate(address.id)
                        } else {
                          onDelete()
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faRemove} className="me-2" />
                    Remove
                    {isDeleting && <Spinner className="ms-2" />}
                  </ActionButton>
                )}

                <ActionButton
                  isProcessing={isSubmitting}
                  isProcessingText="Saving"
                  showSpinner={isSubmitting}
                  variant="success"
                  size="lg"
                  onClick={() => {
                    if (!isSubmitting) {
                      submitForm()
                    }
                  }}
                >
                  Save
                  {isSubmitting && <Spinner className="ms-2" />}
                </ActionButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default CompanyAddressRow
