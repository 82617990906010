import React from 'react'
import { Button } from 'react-bootstrap'

import useModal from '../../../../hooks/useModal'
import { useReviewESignatureLetterModal } from '../../../modals/ReviewESignatureLetterModal/useReviewESignatureLetterModal'
import AmendESignatureModal from './AmendESignatureModal'

interface Props {
  onApprove?: () => void
  onAmend?: (amendRequest: string) => void
  amendRequest?: string
}

const ReviewActions: React.FC<Props> = ({
  onApprove,
  onAmend,
  amendRequest,
}) => {
  const { hideModal } = useReviewESignatureLetterModal()

  const {
    isShowingModal: isShowingAmendModal,
    showModal: showAmendModal,
    hideModal: hideAmendModal,
  } = useModal()

  return (
    <>
      {(onApprove || onAmend) && (
        <section className="d-flex flex-column flex-sm-row flex-row justify-content-sm-end align-items-center row-gap-2 column-gap-3">
          <Button
            variant="success"
            size="lg"
            className="btn-fluid order-sm-last"
            onClick={onApprove}
          >
            Approve
          </Button>

          <Button
            variant="outline-primary"
            size="lg"
            className="btn-fluid order-sm-first"
            onClick={showAmendModal}
          >
            Amend
          </Button>
        </section>
      )}

      {onAmend && isShowingAmendModal && (
        <AmendESignatureModal
          onSubmit={(amendRequest) => {
            onAmend(amendRequest)
            hideAmendModal()
            hideModal()
          }}
          onClose={hideAmendModal}
          amendRequest={amendRequest}
        />
      )}
    </>
  )
}

export default ReviewActions
