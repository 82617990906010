import React, { useState } from 'react'

interface CollapsibleListProps {
  items: string[]
  collapseThreshold?: number
}

const CollapsibleList: React.FC<CollapsibleListProps> = ({
  items,
  collapseThreshold = 5,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const displayItems = isOpen ? items : items.slice(0, collapseThreshold)

  return (
    <>
      <ul className="list-unstyled mb-0">
        {displayItems.map((item, index) => (
          <li key={index}>
            <div dangerouslySetInnerHTML={{ __html: item }} />
          </li>
        ))}
      </ul>
      {items.length > collapseThreshold && (
        <button
          className="btn btn-link btn-sm text-muted pe-0"
          onClick={toggleOpen}
        >
          {isOpen
            ? 'show fewer'
            : `show ${items.length - collapseThreshold} more`}
        </button>
      )}
    </>
  )
}

export default CollapsibleList
