import { useMutation } from '@tanstack/react-query'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import useCurrentCompanyOrFail from '../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../hooks/useNotyf'
import { isConstraintViolationListError } from '../../lib/helpers/helperFunctions'
import constraintViolationService from '../../lib/services/constraintViolationService'
import panelNoteService from '../../lib/services/panelNoteService'
import { CreatePanelNoteRequest } from '../../types/requests/panel-notes'
import TextareaInput from '../form/TextareaInput'
import ActionButton from '../misc/ActionButton'
import { useInvalidatePanelNotes } from '../misc/CompanyProfile/tabs/YourComments/useInvalidatePanelNotes'

interface Props {
  panelIri: IRI
  otherCompanyName: string
  onHide: () => void
}

interface AddNoteFormValues {
  body: string
}

const AddNoteFormFields: Record<
  keyof AddNoteFormValues,
  keyof AddNoteFormValues
> = {
  body: 'body',
}

const AddNoteModal: React.FC<Props> = ({
  onHide,
  panelIri,
  otherCompanyName,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const notyf = useNotyf()
  const invalidatePanelNotes = useInvalidatePanelNotes()
  const initialValues: AddNoteFormValues = { body: '' }

  const createPanelNoteMutation = useMutation({
    mutationFn: (note: string) => {
      const request: CreatePanelNoteRequest = {
        company: currentCompany['@id'],
        panel: panelIri,
        body: note,
      }

      return panelNoteService.createPanelNote(request)
    },
    onSuccess: async () => {
      await invalidatePanelNotes()
      onHide()
      notyf.success('Comment successfully added')
    },
  })

  const validationSchema = yup.object().shape({
    [AddNoteFormFields.body]: yup.string().required('Please enter something'),
  })

  function handleSubmit(
    values: AddNoteFormValues,
    helpers: FormikHelpers<AddNoteFormValues>,
  ) {
    createPanelNoteMutation.mutate(values.body, {
      onError: (error) => {
        if (isConstraintViolationListError(error)) {
          helpers.setErrors(constraintViolationService.formatErrors(error))
          return
        }

        notyf.error('Something went wrong')
      },
    })
  }

  const isProcessing = createPanelNoteMutation.isPending

  return (
    <Modal show onHide={onHide} aria-label="Company services">
      <Modal.Header closeButton>
        <div>
          <Modal.Title className="mb-0">Add comment</Modal.Title>
          <div>{otherCompanyName}</div>
        </div>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Modal.Body>
            <TextareaInput
              name={AddNoteFormFields.body}
              label="Add comment"
              placeholder="Add company history"
              rows={5}
              autoFocus
              isDisabled={isProcessing}
            />
          </Modal.Body>

          <Modal.Footer className="border-top-0">
            <Button variant="outline-secondary" onClick={onHide}>
              Cancel
            </Button>

            <ActionButton
              disabled={isProcessing}
              variant="primary"
              type="submit"
              style={{ minWidth: '145px' }}
              isProcessing={isProcessing}
              isProcessingText="Adding comment"
            >
              Add comment
            </ActionButton>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}

export default AddNoteModal
