import React from 'react'

import userIconUrl from '../../../../assets/img/icons/user-outlines.svg'
import { THEME_PALETTE_LIGHT } from '../../../../lib/constants'
import { CompanyProfileItemFcaIndividual } from '../../../../types/responses/companies'
import TabTitle from '../components/TabTitle'

interface Props {
  fcaIndividuals: CompanyProfileItemFcaIndividual[]
}

const Team: React.FC<Props> = ({ fcaIndividuals }) => (
  <>
    <TabTitle>FCA registered team members</TabTitle>

    <div className="row gx-3 gy-3">
      {fcaIndividuals.map((individual) => (
        <div key={individual.irn} className="col-6 col-xl-4">
          <div
            className="card card--round-border p-3 h-100 justify-content-between"
            style={{ borderColor: THEME_PALETTE_LIGHT.grayLight }}
          >
            <div
              className="d-inline-block mb-3 rounded-circle"
              style={{
                width: 80,
                height: 80,
                padding: 24,
                backgroundColor: THEME_PALETTE_LIGHT.gray,
              }}
            >
              <img src={userIconUrl} alt="person profile icon" />
            </div>

            <p className="mb-1 fw-semibold" style={{ color: 'black' }}>
              {individual.name}
            </p>

            <p className="mb-1" style={{ minHeight: '50px' }}>
              Specialist qualifications:{' '}
              {individual.qualifications.length > 0 && (
                <>{individual.qualifications.join(' • ')}</>
              )}
              {individual.qualifications.length === 0 && <>N/A</>}
            </p>

            <p className="mt-auto">
              FCA number:{' '}
              <a
                href={
                  'https://register.fca.org.uk/s/individual?id=' + individual.id
                }
                target="_blank"
                rel="noreferrer"
              >
                {individual.irn}
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  </>
)

export default Team
