import React from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import CompleteRegistrationPopUp from '../components/flows/CompleteRegistrationFlow/CompleteRegistrationPopup'
import { useHomeRoute } from '../hooks/useHomeRoute'

const CompleteRegistrationPage: React.FC = () => {
  const homeRoute = useHomeRoute()
  const navigate = useNavigate()

  return (
    <Container>
      <CompleteRegistrationPopUp
        onComplete={() => {
          navigate(homeRoute)
        }}
      />
    </Container>
  )
}

export default CompleteRegistrationPage
