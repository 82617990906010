import { useEffect, useRef } from 'react'

import posthogService from '../../lib/services/posthogService'
import { PosthogEvent } from './types'

export interface PosthogCaptureOptions {
  /** Return data for an event to capture on mount (leave out to capture nothing on mount) */
  onMountDataProvider?: () => PosthogEvent
  /** Return data for an event to capture on unmount (leave out to capture nothing on unmount) */
  onUnmountDataProvider?: () => PosthogEvent
  /**
   * Capture an event every time a dependency changes, like `useEffect()`.
   * Note if passing this and onMountDataProvider, you'll capture both on mount
   */
  dependencyListener?: {
    /** Return data for an event to capture when deps change */
    dataProvider: () => PosthogEvent
    /** Deps to watch for changes */
    dependencyList: [unknown, ...unknown[]] // unknown[] of length >= 1
  }
}

/**
 * A hook for capturing events via Posthog.
 *
 * ### Basic usage
 *
 * ```ts
 * const { posthogCapture } = usePosthogCapture()
 *
 * posthogCapture({
 *    appArea: 'some-app-area',
 *    actionName: 'some-action',
 *    // ...
 * })
 * ```
 *
 * ### Capture on component mount / unmount
 *
 * ```ts
 * usePosthogCapture({
 *  onMount: () => ({
 *    appArea: 'some-app-area',
 *    actionName: 'some-action',
 *    // ...
 *  }),
 *  onUnmount: () => ({
 *    appArea: 'some-app-area',
 *    actionName: 'some-action',
 *    // ...
 *  })
 *  onDepChange: () => (
 * )
 * })
 * ```
 *
 * ### Capture and tell posthog to send the request instantly
 *
 * ```ts
 * const { posthogCaptureNow } = usePosthogCapture()
 *
 * await posthogCaptureNow({
 *    appArea: 'some-app-area',
 *    actionName: 'some-action',
 *    // ...
 * })
 *
 * // It's now more likely to be safe to do things like refreshing the page without data loss
 * // ...
 * ```
 *
 * @param options An object containing callbacks to return event data to be logged `onMount` or `onUnmount`
 * @returns A callback to capture additional events
 */
function usePosthogCapture(options: PosthogCaptureOptions = {}) {
  const onMountIsDoneRef = useRef<boolean>(false)

  useEffect(() => {
    const { onMountDataProvider, onUnmountDataProvider, dependencyListener } =
      options

    // Capture on deps change
    if (dependencyListener !== undefined) {
      posthogService.capture(dependencyListener.dataProvider())
    }

    if (!onMountIsDoneRef.current) {
      if (onMountDataProvider !== undefined) {
        // Capture on mount
        posthogService.capture(onMountDataProvider())
      }

      onMountIsDoneRef.current = true
    }

    if (onUnmountDataProvider !== undefined) {
      return () => {
        // Capture on unmount
        posthogService.capture(onUnmountDataProvider())
      }
    }
  }, [
    options,
    // Ignore lint because we need to be able to take deps from outside this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...(options.dependencyListener === undefined
      ? []
      : options.dependencyListener.dependencyList),
  ])

  // Allow capturing whenever
  return {
    posthogCapture: (eventData: PosthogEvent) =>
      posthogService.capture(eventData),
    /**
     * Nothing complicated - captures with the `send_instantly` option set
     * to true, then naively waits a set amount of time before resolving.
     *
     * Use this only if you want a better chance that the event has been
     * captured before moving on.
     *
     * Honestly this is a hacky workaround for the fact PostHog's JS API doesn't
     * provide a way to know when their queue's been flushed.
     *
     * See https://github.com/PostHog/posthog-js/issues/205, for example
     *
     * @param timeout Assume it's done after this amount of time (ms). Increase this if it's very important that this be logged
     */
    posthogCaptureNow: async (
      eventData: PosthogEvent,
      timeout: number = 500,
    ) => {
      posthogService.capture(eventData, true)

      // Return after `timeout`ms, regardless of what's happened
      await new Promise((resolve) => setTimeout(resolve, timeout))
    },
  }
}

export default usePosthogCapture
