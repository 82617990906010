import React from 'react'
import invariant from 'tiny-invariant'

import serviceFeeService, {
  FEE_CATEGORY_OPTION_OTHER,
} from '../../../../lib/services/serviceFeeService'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeeCategory: React.FC<Props> = ({ serviceFee }) => {
  const feeCategory = serviceFee.feeCategory

  invariant(feeCategory, 'Expected feeCategory to be set')

  if (feeCategory === FEE_CATEGORY_OPTION_OTHER) {
    return serviceFee.feeCategoryOther
  }

  const feeCategoryLabel =
    serviceFeeService.getServiceFeeCategoryByValue(feeCategory).label

  if (serviceFeeService.isOngoingFeesAgreement(feeCategory)) {
    const duration = serviceFeeService
      .getDurationOptions()
      .find(({ value }) => Number(value) === Number(serviceFee.duration))

    return duration
      ? `${feeCategoryLabel} (${duration.label})`
      : feeCategoryLabel
  }

  return feeCategoryLabel
}

export default ServiceFeeCategory
