import React from 'react'

import CardHeading from '../../../typography/CardHeading'

interface Props {
  children: React.ReactNode
}

const TabTitle: React.FC<Props> = ({ children }) => (
  <CardHeading className="mt-2 mb-3">{children}</CardHeading>
)

export default TabTitle
