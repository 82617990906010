import React from 'react'
import { Link } from 'react-router-dom'

import {
  COMPANY_IDENTIFIER,
  COMPANY_TYPE_CODE,
} from '../../../../../lib/constants'
import {
  bool2Human,
  extractURL,
} from '../../../../../lib/helpers/helperFunctions'
import { currentUrl } from '../../../../../lib/helpers/routeHelpers'
import routeService from '../../../../../lib/services/routeService'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import CollapsibleList from '../../../CollapsibleList'
import SectionHeading from './SectionHeading'

interface Props {
  companyProfile: CompanyProfileItem
}

type Detail = {
  label: string
  value: React.ReactNode | (() => React.ReactNode)
}

const AtAGlance: React.FC<Props> = ({ companyProfile }) => {
  const { fca, company } = companyProfile
  const isRegisteredAsIfa =
    companyProfile.company?.companyType.code === COMPANY_TYPE_CODE.ifa
  const websiteUrl = company?.url
    ? extractURL(company.url)
    : fca?.website
      ? extractURL(fca.website)
      : null
  const companyDetail = company?.companyDetail
  const companyNumber = company ? company.companyNumber : fca?.companyNumber
  const isTradingName = !!fca?.frnTradingNameId

  const details: Detail[] = [
    {
      label: 'Company #',
      value: companyNumber ? (
        <a
          target="_blank"
          href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
          rel="noreferrer"
        >
          {companyNumber}
        </a>
      ) : (
        'N/A'
      ),
    },
  ]

  if (websiteUrl) {
    details.push({
      label: 'Website',
      value: () => (
        <a target="_blank" href={websiteUrl.href} rel="noreferrer">
          {websiteUrl.hostname}
        </a>
      ),
    })
  }

  if (!isTradingName && companyProfile.companiesHouse) {
    details.push({
      label: 'Years operational',
      value: (
        <>
          {Number(
            (new Date().getTime() -
              new Date(
                companyProfile.companiesHouse.dateIncorporated,
              ).getTime()) /
              31536000000,
          ).toFixed(0)}
        </>
      ),
    })
  }

  if (fca) {
    details.push({
      label: 'Status',
      value: (
        <>
          {isTradingName ? (
            <>
              Trading name of{' '}
              <Link
                className="text-muted"
                to={routeService.companyProfile({
                  identifier: fca.frn,
                  identifierType: COMPANY_IDENTIFIER.frn,
                  backLabel: `Back to ${fca.name}`,
                  backUrl: currentUrl(),
                })}
              >
                {fca.frnName}
              </Link>
            </>
          ) : (
            fca.status
          )}
        </>
      ),
    })
  }

  if (fca) {
    details.push({
      label: 'FCA #',
      value: (
        <a
          target="_blank"
          href={'https://register.fca.org.uk/s/firm?id=' + fca.fcaId}
          rel="noreferrer"
        >
          {fca.frn}
        </a>
      ),
    })
  }

  if (companyProfile.isRegistered && companyDetail?.ifa) {
    if (typeof companyDetail?.ifa.isTransactionalWork === 'boolean') {
      details.push({
        label: 'Does one-off, transactional work',
        value: bool2Human(companyDetail?.ifa.isTransactionalWork),
      })
    }

    if (isRegisteredAsIfa) {
      details.push({
        label: 'Minimum investable assets',
        value: (
          <>
            {companyDetail?.ifa.minInvestableAssets
              ? companyDetail.ifa.minInvestableAssets.toLocaleString('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                })
              : 'No'}
          </>
        ),
      })
    }

    if (fca && !isTradingName) {
      details.push({
        label: 'HQ',
        value: fca.address + ' ' + fca.county + ' ' + fca.postcode,
      })
    }

    if (fca && !isTradingName && fca.tradingNames.length > 1) {
      details.push({
        label: 'Trading Names',
        value: (
          <CollapsibleList
            collapseThreshold={5}
            items={fca.tradingNames.map((tradingName) => tradingName.name)}
          />
        ),
      })
    }
  }

  return (
    <>
      <SectionHeading>At a glance</SectionHeading>

      <div className="table-responsive">
        <table className="table table-borderless table-sm table-ps-0 table-stacked mt-3 mt-sm-0">
          <tbody>
            {details.map((detail, index) => (
              <tr key={index}>
                <td style={{ minWidth: '130px' }}>
                  <span className="font-weight-500 text-black">
                    {detail.label}
                  </span>
                </td>

                <td>
                  {typeof detail.value === 'function'
                    ? detail.value()
                    : detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AtAGlance
