import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@rq-ratings/pro-regular-svg-icons'
import { Editor } from '@tiptap/react'
import classNames from 'classnames'
import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

import { RichTextInputProps } from './RichTextInput'

interface Props {
  editor: Editor
  variant: RichTextInputProps['variant']
}

const EditorToolbar: React.FC<Props> = ({ editor, variant }) => {
  return (
    <section className="d-flex gap-1">
      <ToolbarButton
        className={classNames(
          'font-weight-bold',
          editor.isActive('bold') ? 'text-white' : undefined,
        )}
        isActive={editor.isActive('bold')}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        B
      </ToolbarButton>

      <ToolbarButton
        className="fst-italic"
        isActive={editor.isActive('italic')}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        I
      </ToolbarButton>

      <ToolbarButton
        className="text-decoration-underline"
        isActive={editor.isActive('underline')}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        U
      </ToolbarButton>

      {variant === 'advanced' && (
        <ToolbarButton
          isActive={editor.isActive('bulletList')}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FontAwesomeIcon icon={faList} />
        </ToolbarButton>
      )}
    </section>
  )
}

type ToolbarButtonProps = ButtonProps & {
  isActive: boolean
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  isActive,
  className,
  ...props
}) => (
  <Button
    variant={isActive ? 'secondary' : 'outline-secondary'}
    className={classNames(
      'd-flex align-items-center justify-content-center',
      className,
    )}
    size="sm"
    style={{ width: '25px', height: '25px' }}
    {...props}
  ></Button>
)

export default EditorToolbar
