import { useMutation } from '@tanstack/react-query'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
import * as yup from 'yup'

import useNotyf from '../../hooks/useNotyf'
import { isConstraintViolationListError } from '../../lib/helpers/helperFunctions'
import constraintViolationService from '../../lib/services/constraintViolationService'
import panelNoteService from '../../lib/services/panelNoteService'
import TextareaInput from '../form/TextareaInput'
import ActionButton from '../misc/ActionButton'
import { useInvalidatePanelNotes } from '../misc/CompanyProfile/tabs/YourComments/useInvalidatePanelNotes'

interface Props {
  onHide: () => void
  panelNoteId: number
  panelNoteBody: string
  otherCompanyName: string
}

interface UpdateNoteFormValues {
  body: string
}

const UpdateNoteFormFields: Record<
  keyof UpdateNoteFormValues,
  keyof UpdateNoteFormValues
> = {
  body: 'body',
}

const EditNoteModal: React.FC<Props> = ({
  onHide,
  panelNoteId,
  panelNoteBody,
  otherCompanyName,
}) => {
  const notyf = useNotyf()
  const initialValues: UpdateNoteFormValues = { body: panelNoteBody }
  const invalidatePanelNotes = useInvalidatePanelNotes()

  const updatePanelNoteMutation = useMutation({
    mutationFn: (note: string) =>
      panelNoteService.updatePanelNote(panelNoteId, note),
    onSuccess: async () => {
      await invalidatePanelNotes()
      onHide()
      notyf.success('Comment successfully saved')
    },
  })

  const deletePanelNoteMutation = useMutation({
    mutationFn: () => panelNoteService.deletePanelNote(panelNoteId),
    onSuccess: async () => {
      await invalidatePanelNotes()
      onHide()
      notyf.success('Comment successfully deleted')
    },
    onError: () => {
      notyf.error('Failed to delete comment')
    },
  })

  const validationSchema = yup.object().shape({
    [UpdateNoteFormFields.body]: yup
      .string()
      .required('Please enter something'),
  })

  function handleSubmit(
    values: UpdateNoteFormValues,
    helpers: FormikHelpers<UpdateNoteFormValues>,
  ) {
    return updatePanelNoteMutation.mutate(values.body, {
      onError: (error) => {
        if (isConstraintViolationListError(error)) {
          helpers.setErrors(constraintViolationService.formatErrors(error))
          return
        }

        notyf.error('Something went wrong')
      },
    })
  }

  const isProcessing =
    updatePanelNoteMutation.isPending || deletePanelNoteMutation.isPending

  return (
    <Modal show onHide={onHide} aria-label="Company services">
      <Modal.Header closeButton>
        <div>
          <Modal.Title className="mb-0">Edit comment</Modal.Title>
          <div>{otherCompanyName}</div>
        </div>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Modal.Body>
            <TextareaInput
              name={UpdateNoteFormFields.body}
              placeholder="Add company history"
              isDisabled={isProcessing}
              rows={5}
              autoFocus
            />
          </Modal.Body>

          <Modal.Footer className="border-top-0">
            <ActionButton
              onClick={() => deletePanelNoteMutation.mutate()}
              variant="outline-danger"
              type="button"
              isProcessing={deletePanelNoteMutation.isPending}
              disabled={isProcessing}
              isProcessingText="Deleting comment"
            >
              Delete
            </ActionButton>

            <ActionButton
              disabled={isProcessing}
              variant="primary"
              type="submit"
              style={{ minWidth: '145px' }}
              isProcessing={updatePanelNoteMutation.isPending}
              isProcessingText="Saving comment"
            >
              Save comment
            </ActionButton>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}

export default EditNoteModal
