import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Alert } from 'react-bootstrap'

const DataDisclaimer: React.FC = () => (
  <div>
    <Alert variant="warning" className="mt-3 mb-0">
      <div className="alert-icon my-auto">
        <FontAwesomeIcon
          icon={faInfoCircle}
          fixedWidth
          size="lg"
          className="text-warning"
        />
      </div>

      <div className="alert-message">
        RQ has generated this profile based on public data. This firm is not a
        member of RQ and these details are unconfirmed.
      </div>
    </Alert>
  </div>
)

export default DataDisclaimer
