import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import * as yup from 'yup'

import useNotyf from '../../../../hooks/useNotyf'
import { isConstraintViolationListError } from '../../../../lib/helpers/helperFunctions'
import companyMessageService from '../../../../lib/services/companyMessageService'
import constraintViolationService from '../../../../lib/services/constraintViolationService'
import {
  CreateCompanyMessagePreviewRequest,
  CreateCompanyMessageRequest,
} from '../../../../types/requests/company-message'
import FormGroup from '../../../form/FormGroup'
import TextareaInput from '../../../form/TextareaInput'
import TextInput from '../../../form/TextInput'
import ActionButton from '../../ActionButton'
import { CompanyMessageData } from '../utils/types'

interface Props {
  toCompanyId: number
  toCompanyName: string
  handleClose: () => void
}

interface ContactCompanyFormValues {
  subject: string
  body: string
}

const ContactCompanyModal: React.FC<Props> = ({
  toCompanyId,
  toCompanyName,
  handleClose,
}) => {
  const notyf = useNotyf()

  const sendCompanyMessageMutation = useMutation({
    mutationFn: (data: CompanyMessageData) => {
      const request: CreateCompanyMessageRequest = {
        toCompany: data.toCompany,
        subject: data.subject,
        body: data.body,
      }

      return companyMessageService.createCompanyMessage(request)
    },
    onSuccess() {
      notyf.success('Message successfully sent')
    },
  })

  const companyMessagePreviewQuery = useQuery({
    queryKey: ['company-message', { toCompanyId: toCompanyId }],
    queryFn: () => {
      const request: CreateCompanyMessagePreviewRequest = {
        toCompany: toCompanyId,
      }

      return companyMessageService.createCompanyMessagePreview(request)
    },
  })

  const formikRef = useRef<FormikProps<ContactCompanyFormValues>>(null)

  const validationSchema = yup.object().shape({
    subject: yup.string().required('Please enter a message subject'),
    body: yup.string().required('Please enter a message body'),
  })

  const isLoadingDefaultMessage = companyMessagePreviewQuery.isLoading
  const isSendingMessage = sendCompanyMessageMutation.isPending

  const shouldDisableForm = isLoadingDefaultMessage || isSendingMessage

  const defaultMessage = companyMessagePreviewQuery.data

  return (
    <Modal show onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send a message to {toCompanyName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            subject: defaultMessage?.subject ?? '',
            body: defaultMessage?.body ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={(
            value: ContactCompanyFormValues,
            helpers: FormikHelpers<ContactCompanyFormValues>,
          ) =>
            sendCompanyMessageMutation.mutate(
              { ...value, toCompany: toCompanyId },
              {
                onSuccess() {
                  handleClose()
                },
                onError: (error: unknown) => {
                  if (isConstraintViolationListError(error)) {
                    helpers.setErrors(
                      constraintViolationService.formatErrors(error),
                    )
                    return
                  }

                  if (error instanceof AxiosError) {
                    error = error.response?.data?.['hydra:description']
                  }

                  notyf.error(error || 'Failed to send company message')
                },
              },
            )
          }
          innerRef={formikRef}
        >
          <Form>
            <FormGroup>
              <TextInput
                name="subject"
                label="Subject"
                placeholder="Subject"
                autoFocus
                isDisabled={shouldDisableForm}
              />
            </FormGroup>

            <FormGroup>
              <TextareaInput
                name="body"
                label="Message"
                placeholder="Body"
                rows={5}
                autoFocus
                isDisabled={shouldDisableForm}
              />
            </FormGroup>
          </Form>
        </Formik>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>

        <ActionButton
          variant="primary"
          type="submit"
          isProcessing={isSendingMessage}
          disabled={shouldDisableForm}
          isProcessingText="Sending..."
          onClick={() => {
            formikRef.current?.submitForm()
          }}
        >
          Send
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ContactCompanyModal
