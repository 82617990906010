import { FormikContextType, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

interface Props<Values = unknown> {
  onError: (helpers: FormikContextType<Values>) => void
}

function FormikSubmitErrorListener<Values>({ onError }: Props<Values>) {
  const formikContext = useFormikContext<Values>()
  const { isValidating, errors } = formikContext
  const [previousSubmitCount, setPreviousSubmitCount] = useState(
    formikContext.submitCount,
  )
  const submitCount = formikContext.submitCount
  const isNewSubmission = submitCount > 0 && submitCount !== previousSubmitCount
  const hasErrors = Object.keys(errors).length > 0

  useEffect(() => {
    setPreviousSubmitCount(submitCount)
  }, [submitCount])

  useEffect(() => {
    // TODO: Make the logic easier to understand
    if (isNewSubmission && !isValidating && hasErrors) {
      onError(formikContext)
      return
    }
  }, [previousSubmitCount, isValidating]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default FormikSubmitErrorListener
