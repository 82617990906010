import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  input: string
  limit: number
}

const CharacterLimitIndicator: React.FC<Props> = ({
  input,
  limit,
  className,
  ...props
}) => {
  const inputLength = input.length
  const isOverLimit = inputLength > limit

  return (
    <div
      className={classNames(
        'text-sm',
        isOverLimit ? 'text-danger' : 'text-muted',
        className,
      )}
      {...props}
    >
      {inputLength}/{limit}
    </div>
  )
}

export default CharacterLimitIndicator
