import React from 'react'

import { DATE_FORMATS } from '../../../../lib/constants'
import { formatDate } from '../../../../lib/helpers/helperFunctions'
import { ReferralWithESignatureDetailsItem } from '../../../../types/responses/referrals'
import SignatureBox, { OnSignatureChange } from './SignatureBox'

interface Props {
  referral: ReferralWithESignatureDetailsItem
  signature?: string
  onSign?: OnSignatureChange
  onSaveSignature?: () => void
  hasSubmittedSignedLetter: boolean
}

export const SIGNATUR_BOX_ID = 'js-signature-box'

const Footer: React.FC<Props> = ({
  referral,
  signature,
  onSign,
  onSaveSignature,
  hasSubmittedSignedLetter,
}) => {
  const { client, fromCompany } = referral
  const isPreview = !referral.id

  return (
    <section className="avoid-page-break">
      <div className="mb-3 mt-4">
        <p className="mb-2">Yours</p>
        <p className="font-weight-bold">{fromCompany.presentationName}</p>
      </div>

      <div
        className="d-flex gap-4 justify-content-md-between align-items-end"
        id={SIGNATUR_BOX_ID}
      >
        <div>
          <p>Acknowledged and accepted:</p>

          <div style={{ marginBottom: '6px' }}>
            <SignatureBox
              signature={signature}
              onSignatureChange={onSign}
              onSaveSignature={onSaveSignature}
              hasSubmittedSignedLetter={hasSubmittedSignedLetter}
            />
          </div>

          <p className="font-weight-bold mb-0">{client.fullName}</p>
        </div>

        <div
          className="rounded-3 px-3 py-2 text-center"
          style={{ backgroundColor: 'rgb(13, 150, 255, 0.1)', width: '165px' }}
        >
          {isPreview
            ? 'Date Signed'
            : formatDate(new Date(), DATE_FORMATS.DAY_MONTH_YEAR)}
        </div>
      </div>
    </section>
  )
}

export default Footer
