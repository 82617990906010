import { CreatePanelNoteRequest } from '../../types/requests/panel-notes'
import apiService from './apiService'

class PanelNoteService {
  private endpoint = '/v1/panel-notes'

  async createPanelNote(request: CreatePanelNoteRequest): Promise<unknown> {
    const response = await apiService.post<unknown>(this.endpoint, request)

    return response.data
  }

  async updatePanelNote(panelNoteId: number, body: string): Promise<unknown> {
    const response = await apiService.patch<unknown>(
      `${this.endpoint}/${panelNoteId}`,
      { body },
    )

    return response.data
  }

  async deletePanelNote(panelNoteId: number): Promise<unknown> {
    const response = await apiService.delete<unknown>(
      `${this.endpoint}/${panelNoteId}`,
    )

    return response.data
  }
}

const panelNoteService = new PanelNoteService()

export default panelNoteService
