import { useMutation } from '@tanstack/react-query'

import useAppDispatch from '../../../../hooks/useAppDispatch'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import sentryService from '../../../../lib/services/sentryService'
import { setCommercialAgreementsMetadata } from '../../../../redux/slices/commercialAgreementsMetadata'
import { CreateCommercialAgreementMetadataRequest } from '../../../../types/requests/commercial-agreements'

export function useCreateCommercialAgreementsMetadataMutation() {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationFn: (request: CreateCommercialAgreementMetadataRequest) => {
      return commercialAgreementService.createMetadata(request)
    },
    onSuccess: (metadata) => {
      dispatch(setCommercialAgreementsMetadata(metadata))
    },
    onError: () => {
      sentryService.captureMessageWithId({
        message: 'Failed to create commercial agreement metadata',
        messageId: 'app-commercial-agreement-metadata-failed',
      })
    },
  })
}
