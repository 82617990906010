import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@rq-ratings/pro-regular-svg-icons'
import { faArrowDown } from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { THEME_PALETTE } from '../../../../lib/constants'
import PlainButton from '../../PlainButton'

interface Props {
  signature?: string
  onSignatureChange?: OnSignatureChange
  onSaveSignature?: () => void
  hasSubmittedSignedLetter?: boolean
}

export type OnSignatureChange = (name: string) => void

const BOX_HEIGHT = 55

const SignatureBox: React.FC<Props> = ({
  signature = '',
  onSignatureChange,
  onSaveSignature,
  hasSubmittedSignedLetter = false,
}) => {
  const isSignable = !!onSignatureChange && !hasSubmittedSignedLetter
  const [isSigning, setIsSigning] = useState(false)
  const [name, setName] = useState(signature)
  const isValidName = name.trim().length > 0

  if (isSigning) {
    return (
      <div
        className="d-flex align-items-center"
        style={{ height: BOX_HEIGHT, width: '450px' }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault()

            if (!isValidName || !onSignatureChange) {
              return
            }

            onSignatureChange(name)
            setIsSigning(false)
          }}
          className="d-flex align-items-stretch w-100"
          style={{ height: '32px', gap: '2px' }}
        >
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control me-1"
            placeholder="Your full name"
            style={{ maxWidth: '210px' }}
            autoFocus
          />
          <OverlayTrigger overlay={<Tooltip>Save changes</Tooltip>}>
            <Button
              onClick={onSaveSignature}
              type="submit"
              variant="success"
              className="d-flex align-items-center"
              disabled={!isValidName}
            >
              <FontAwesomeIcon icon={faCheck} className="text-white text-lg" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>Cancel changes</Tooltip>}>
            <Button
              variant="danger"
              className="d-flex align-items-center"
              onClick={() => {
                setName('')
                setIsSigning(false)
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="text-white text-lg" />
            </Button>
          </OverlayTrigger>
        </form>
      </div>
    )
  }

  if (signature) {
    return (
      <div
        className="d-flex align-items-center gap-3"
        style={{ minHeight: BOX_HEIGHT }}
      >
        <div
          className="fst-italic font-weight-bold"
          style={{ fontSize: '34px', fontFamily: 'Allura', lineHeight: '1.4' }}
        >
          {signature}
        </div>

        {isSignable && (
          <PlainButton onClick={() => setIsSigning(true)}>(Edit)</PlainButton>
        )}
      </div>
    )
  }

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {isSignable
            ? 'Click to sign'
            : 'The client will be able to sign here'}
        </Tooltip>
      }
    >
      <section
        className={classNames(
          'd-flex justify-content-center align-items-center rounded-3 text-black text-center',
          isSignable ? 'cursor-pointer hover-dim' : '',
        )}
        style={{
          backgroundColor: THEME_PALETTE.yellowDocuSign,
          height: BOX_HEIGHT,
          width: '170px',
        }}
        onClick={() => {
          if (isSignable) {
            setIsSigning(true)
          }
        }}
      >
        <FontAwesomeIcon
          icon={faArrowDown}
          style={{
            fontSize: '18px',
            marginRight: '7px',
          }}
        />{' '}
        SIGN
      </section>
    </OverlayTrigger>
  )
}

export default SignatureBox
