import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info } from 'react-feather'

const RqCompliantFeeSharingTooltip: React.FC = () => (
  <OverlayTrigger
    placement="bottom"
    overlay={
      <Tooltip className="tooltip-md">
        Fee sharing agreements are a hassle. In addition to 'accounting to your
        client', you need to chase your referral partners for updates and
        invoices. RQ can do this for you. Click 'yes' to enable this
        functionality. If you'd like to learn more, use the chat to speak with
        our team.
      </Tooltip>
    }
  >
    <Info
      className="text-primary ms-1"
      size={14}
      style={{ marginTop: '-2px' }}
    />
  </OverlayTrigger>
)

export default RqCompliantFeeSharingTooltip
