import React from 'react'

interface Props {
  children: React.ReactNode
}

const CardHeading: React.FC<Props> = ({ children }) => (
  <div className="font-weight-bold text-black mb-1">{children}</div>
)

export default CardHeading
