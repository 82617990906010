import React from 'react'
import { Modal, Offcanvas } from 'react-bootstrap'
import { useMount } from 'react-use'
import invariant from 'tiny-invariant'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { useIsMinWidthMd } from '../../../hooks/useIsMinWidthMd'
import {
  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION,
  PENDING_PANEL_ACTION,
} from '../../../lib/constants'
import companyService from '../../../lib/services/companyService'
import {
  hideCommercialAgreementsModal,
  selectCommercialAgreementReferralDirection,
  selectCommercialAgreementsModalPanelId,
} from '../../../redux/slices/commercialAgreementsModal'
import SkeletonBox from '../../skeleton/SkeletonBox'
import { useCreateCommercialAgreementsMetadataMutation } from '../CommercialAgreementsForm/hooks/useCreateCommercialAgreementsMetadataMutation'
import TabTitle from '../CompanyProfile/components/TabTitle'
import CommercialAgreementDirectionDetails from './components/CommercialAgreementDirectionDetails'
import { useCommercialAgreementsQuery } from './useCommercialAgreementsQuery'

interface Props {
  otherCompanyId: number
  onConfirmAgreement?: () => Promise<void>
  onClose?: () => Promise<void>
  header?: string
  subText?: React.ReactNode
  showDirectionDescription?: boolean
  variant: 'modal' | 'inline' | 'offcanvas'
}

const CommercialAgreementsDetails: React.FC<Props> = ({
  otherCompanyId,
  onConfirmAgreement = async () => null,
  onClose = async () => null,
  header = 'Review commercial agreements',
  subText = 'Please review the following commercial agreement(s).',
  variant,
}) => {
  const isMinWidthMd = useIsMinWidthMd()
  const referralDirection = useAppSelector(
    selectCommercialAgreementReferralDirection,
  )
  const panelId =
    useAppSelector(selectCommercialAgreementsModalPanelId) ?? undefined
  const dispatch = useAppDispatch()
  const commercialAgreementsQuery = useCommercialAgreementsQuery(panelId)

  const isModal = variant === 'modal'

  const createCommercialAgreementsMetadataMutation =
    useCreateCommercialAgreementsMetadataMutation()

  useMount(() => {
    createCommercialAgreementsMetadataMutation.mutate({
      otherCompany: companyService.getIri(otherCompanyId),
      referralDirection:
        referralDirection || COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
    })
  })

  if (!panelId || !referralDirection) {
    return null
  }

  const isLoading =
    !commercialAgreementsQuery.data ||
    !createCommercialAgreementsMetadataMutation.data

  const metadata = createCommercialAgreementsMetadataMutation.data

  function renderModalTitle() {
    return <>{isLoading ? 'Commercial agreements' : header}</>
  }

  function renderModalBody() {
    if (isLoading) {
      return <SkeletonBox style={{ height: '52vh' }} />
    }

    invariant(metadata, 'Expected metadata to be set here')

    const { panel, outgoingReferralsAgreement, incomingReferralsAgreement } =
      commercialAgreementsQuery.data

    const needsReview = panel.pendingActions.includes(
      PENDING_PANEL_ACTION.reviewCommercialAgreement,
    )

    const currentCompanyName = metadata.currentCompany.presentationName
    const otherCompanyName = metadata.otherCompany.presentationName

    return (
      <section>
        {needsReview && <p className="text-lg mb-4">{subText}</p>}

        <div className="d-flex flex-column gap-5">
          {[outgoingReferralsAgreement, incomingReferralsAgreement].map(
            (agreement, index) => {
              const isOutgoingReferralsAgreement = index === 0
              const otherAgreement = isOutgoingReferralsAgreement
                ? incomingReferralsAgreement
                : outgoingReferralsAgreement

              return (
                <div key={index}>
                  <TabTitle>
                    {isOutgoingReferralsAgreement ? (
                      <>
                        Commercial terms for referrals from {currentCompanyName}{' '}
                        to {otherCompanyName}
                      </>
                    ) : (
                      <>
                        Commercial terms for referrals from {otherCompanyName}{' '}
                        to {currentCompanyName}
                      </>
                    )}
                  </TabTitle>

                  <CommercialAgreementDirectionDetails
                    key={index}
                    panel={panel}
                    agreement={agreement}
                    otherAgreementNeedsReview={!!otherAgreement?.needsReview}
                    referralDirection={
                      isOutgoingReferralsAgreement
                        ? COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
                        : COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
                    }
                    onAcceptInvite={() => {
                      if (isModal) {
                        hideModal()
                      }
                    }}
                    onConfirmAgreement={async () => {
                      await onConfirmAgreement()
                    }}
                  />
                </div>
              )
            },
          )}
        </div>
      </section>
    )
  }

  function hideModal() {
    dispatch(hideCommercialAgreementsModal())
  }

  async function handleClose() {
    await onClose()
    hideModal()
  }

  if (variant === 'inline') {
    return <>{renderModalBody()}</>
  }

  if (variant === 'offcanvas') {
    return (
      <Offcanvas
        show
        placement="end"
        onHide={handleClose}
        style={{ width: isMinWidthMd ? '880px' : '100%' }}
      >
        <Offcanvas.Header
          onHide={onClose}
          className="border-bottom pt-3 d-flex justify-content-between align-items-center"
          closeButton={!!onClose}
        >
          <Offcanvas.Title
            className="fw-bolder text-black pe-3"
            style={{ fontSize: '17px' }}
          >
            {renderModalTitle()}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="pb-5">
          <>{renderModalBody()}</>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  return (
    <Modal show onHide={handleClose} aria-label={header} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          <h3 className="text-black mb-0">{renderModalTitle()}</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderModalBody()}</Modal.Body>
    </Modal>
  )
}

export default CommercialAgreementsDetails
