import React from 'react'

import { useIsPdf } from '../../../../hooks/useIsPdf'
import CollapsibleContent from '../../CollapsibleContent'
import { ServiceFeeDetail } from '../ServiceFeesTableRows'

interface Props {
  serviceFee: ServiceFeeDetail
}

const ServiceFeePassBackNotes: React.FC<Props> = ({ serviceFee }) => {
  const isPdf = useIsPdf()
  if (!serviceFee.passBackFee) {
    return '-'
  }

  const content =
    'Yes' +
    (serviceFee.passBackFeeNotes ? `. ${serviceFee.passBackFeeNotes}` : '')

  return isPdf ? (
    content
  ) : (
    <CollapsibleContent content={content} collapseThreshold={100} />
  )
}

export default ServiceFeePassBackNotes
