import './CompleteRegistrationPopUp.scss'

import { useMutation, useQuery } from '@tanstack/react-query'
import React, { CSSProperties, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import { useInvalidateOnboardingChecklistQuery } from '../../../hooks/useInvalidateOnboardingChecklistQuery'
import useNotyf from '../../../hooks/useNotyf'
import { COMPANY_IDENTIFIER } from '../../../lib/constants'
import addressService from '../../../lib/services/addressService'
import companyExpertiseAreaService from '../../../lib/services/companyExpertiseAreaService'
import companyService from '../../../lib/services/companyService'
import { selectExpertiseAreasByCompanyTypeIri } from '../../../redux/slices/commonData'
import { selectCompleteRegistrationPopupIsEditInProgress } from '../../../redux/slices/completeRegistrationModal'
import { completeCompanyRegistration } from '../../../redux/slices/session'
import ActionButton from '../../misc/ActionButton'
import { useCompanyProfileQuery } from '../../misc/CompanyProfile/CompanyProfile'
import CompanyFcaServiceDetails from './steps/1_CompanyServices/CompanyFcaServiceDetails'
import CompanyNonFcaServices from './steps/1_CompanyServices/CompanyNonFcaServices'
import CompanyExpertiseAreas from './steps/2_CompanyExpertiseAreas/CompanyExpertiseAreas'
import CompanyOfficeLocations from './steps/3_CompanyOfficeLocations/CompanyOfficeLocations'
import MeetingLink from './steps/4_MeetingLink/MeetingLink'
import UploadLogo from './steps/5_UploadLogo/UploadLogo'
import UploadVideo from './steps/6_UploadVideo/UploadVideo'

interface Props {
  /** If you don't pass this, the modal can't be hidden */
  onHide?: () => void
  /** Optionally do something when this flow's completed */
  onComplete?: () => void
}

const CompleteRegistrationPopUp: React.FC<Props> = ({ onHide, onComplete }) => {
  const dispatch = useAppDispatch()
  const isEditInProgress = useAppSelector(
    selectCompleteRegistrationPopupIsEditInProgress,
  )

  const currentCompany = useCurrentCompanyOrFail()

  const expertiseAreas = useAppSelector(
    selectExpertiseAreasByCompanyTypeIri(currentCompany.companyType['@id']),
  )

  const servicesQuery = useQuery({
    queryKey: ['services', currentCompany.id],
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  const companyExpertiseAreasQuery = useQuery({
    queryKey: ['company-expertise-areas', currentCompany.id],
    queryFn: () => companyExpertiseAreaService.getCollectionForCurrentCompany(),
  })

  const addressesQuery = useQuery({
    queryKey: ['addresses', currentCompany.id],
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  const companyProfileQuery = useCompanyProfileQuery({
    identifier: currentCompany.id.toString(),
    identifierType: COMPANY_IDENTIFIER.companyId,
  })

  const { companiesHouse, fca } = companyProfileQuery.data
  const isTradingName = !!fca?.frnTradingNameId
  const hasDefaultHq =
    !isTradingName && companiesHouse && !!companiesHouse.address

  const flowSteps = {
    CompanyFcaServiceDetails: {
      title: 'What services does your firm offer?',
      name: 'List your services',
      render: () =>
        currentCompany.isFcaFirm ? (
          <CompanyFcaServiceDetails />
        ) : (
          <CompanyNonFcaServices />
        ),
      // Probably always true, since companies get default services and can't remove them
      isComplete: (): boolean =>
        servicesQuery.data !== undefined && servicesQuery.data.length > 0,
      isRequired: true,
      isNecessary: true,
    },
    companyExpertiseAreas: {
      title: "What are your firm's areas of expertise?",
      name: 'Select your areas of expertise',
      render: () => <CompanyExpertiseAreas />,
      isComplete: () =>
        companyExpertiseAreasQuery.data !== undefined &&
        companyExpertiseAreasQuery.data.length > 0,
      isRequired: false,
      isNecessary: expertiseAreas.length > 0,
    },
    companyOfficeLocations: {
      title: "Where are your firm's office locations?",
      name: 'Enter your office locations',
      render: () => <CompanyOfficeLocations />,
      isComplete: (): boolean =>
        hasDefaultHq ||
        (addressesQuery.data !== undefined && addressesQuery.data.length > 0),
      // This is only for additional addresses, hence not required
      isRequired: true,
      isNecessary: true,
    },
    meetingLink: {
      title: 'Your meeting links',
      name: 'Provide your meeting links',
      render: () => <MeetingLink />,
      isComplete: (): boolean =>
        !!currentCompany.scheduleClientCallUrl &&
        !!currentCompany.scheduleCompanyCallUrl,
      isRequired: false,
      isNecessary: true,
    },
    uploadLogo: {
      title: "Upload your firm's logo",
      name: "Upload your firm's logo",
      render: () => <UploadLogo />,
      isComplete: (): boolean => !!currentCompany.logoUrl,
      isRequired: true,
      isNecessary: true,
    },
    uploadVideo: {
      title: 'Share a video about you or your firm (optional)',
      name: 'Upload video about your firm',
      render: () => <UploadVideo />,
      isComplete: (): boolean =>
        !!currentCompany.videoUrl || currentCompany.hasUnprocessedVideo,
      isRequired: false,
      isNecessary: true,
    },
  }

  type StepKey = keyof typeof flowSteps

  const [currentStepKey, setCurrentStepKey] = useState<StepKey>(
    Object.keys(flowSteps)[0] as StepKey,
  )
  const [visitedSteps, setVisitedSteps] = useState<string[]>([currentStepKey])

  function currentStepIsFirstStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[0]
  }

  function currentStepIsLastStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[flowStepKeys.length - 1]
  }

  function goToStepAfter(current: StepKey): void {
    const flowStepKeys = Object.keys(flowSteps) as StepKey[]

    const currentStepIndex = flowStepKeys.indexOf(current)

    const newStepKey = flowStepKeys[
      // Don't go past the last one
      Math.min(currentStepIndex + 1, flowStepKeys.length - 1)
    ] as StepKey

    if (!flowSteps[newStepKey].isNecessary) {
      // Skip this unnecessary step, and move forward again
      goToStepAfter(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStepBefore(current: StepKey): void {
    const flowStepKeys = Object.keys(flowSteps) as StepKey[]

    const currentStepIndex = flowStepKeys.indexOf(current)

    const newStepKey = flowStepKeys[
      // Don't go past the first one
      Math.max(currentStepIndex - 1, 0)
    ] as StepKey

    if (!flowSteps[newStepKey].isNecessary) {
      // Skip this unnecessary step, and move back again
      goToStepBefore(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStep(key: StepKey) {
    if (
      isEditInProgress &&
      !confirm(
        'Changes will be lost if you move away from this step. Move away?',
      )
    ) {
      // Cancel the navigation
      return
    }

    if (!visitedSteps.includes(key)) {
      setVisitedSteps([...visitedSteps, key])
    }

    setCurrentStepKey(key)
  }

  function isReadyToComplete(): boolean {
    for (const step of Object.values(flowSteps)) {
      if (step.isRequired && !step.isComplete()) {
        return false
      }
    }

    return true
  }

  function complete() {
    completeRegistrationMutation.mutate()
  }

  const notyf = useNotyf()

  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()

  const completeRegistrationMutation = useMutation({
    mutationFn: () => companyService.completeRegistration(currentCompany.id),
    onSuccess: async () => {
      dispatch(completeCompanyRegistration())
      await invalidateOnboardingChecklistQuery()
      notyf.success('Registration successfully completed')

      if (onComplete !== undefined) {
        onComplete()
      }
    },
    onError: () => {
      notyf.error('There was a problem completing your registration')
    },
  })

  if (currentCompany.isRegistrationComplete) {
    return null
  }

  const isNavigationAllowed =
    !flowSteps[currentStepKey].isRequired ||
    flowSteps[currentStepKey].isComplete()

  return (
    <Modal
      show
      centered
      size="lg"
      fullscreen="sm-down"
      onHide={() => {
        if (onHide === undefined) {
          return
        }

        if (
          isEditInProgress &&
          !confirm('Changes will be lost if you close this popup')
        ) {
          // Cancel the hide
          return
        }

        // Go on then, let's hide it
        onHide()
      }}
      id="complete-registration-modal"
    >
      <Modal.Header className="border-0 d-flex justify-content-center">
        <Modal.Title>
          <h2 className="h1 mt-3 text-center fw-bolder">
            {flowSteps[currentStepKey].title}
          </h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column justify-content-between text-center">
        <div className="mb-3 flex-grow-1">
          {flowSteps[currentStepKey].render()}
        </div>
      </Modal.Body>

      <Modal.Footer className="sticky-bottom d-flex flex-column">
        {/* Flow step navigation */}
        <div
          id="complete-registration-modal-steps-indicator"
          style={
            {
              '--number-of-columns': Object.values(flowSteps).filter(
                (step) => step.isNecessary,
              ).length,
            } as CSSProperties
          }
          className="mb-3"
        >
          {(Object.keys(flowSteps) as StepKey[]).map((stepKey: StepKey) => {
            if (!flowSteps[stepKey].isNecessary) {
              return null
            }

            return (
              <OverlayTrigger
                key={stepKey}
                overlay={<Tooltip>{flowSteps[stepKey].name}</Tooltip>}
              >
                {/* The clickable area, mostly invisible. Bigger than the
                      visible bit as otherwise the click/touch target is too small */}
                <button
                  type="button"
                  onClick={() => {
                    if (isNavigationAllowed) {
                      goToStep(stepKey)
                    } else {
                      notyf.error(
                        'Please complete this step before moving to another',
                      )
                    }
                  }}
                >
                  {/* The visual bit */}
                  <span
                    className={stepKey === currentStepKey ? 'bg-primary' : ''}
                  ></span>
                </button>
              </OverlayTrigger>
            )
          })}
        </div>

        <div className="d-flex gap-3">
          {!currentStepIsFirstStep() && (
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={() => goToStepBefore(currentStepKey)}
              disabled={!isNavigationAllowed}
            >
              Back
            </Button>
          )}

          {!currentStepIsLastStep() && (
            <Button
              variant="primary"
              size="lg"
              onClick={() => goToStepAfter(currentStepKey)}
              disabled={!isNavigationAllowed}
            >
              Next
            </Button>
          )}

          {currentStepIsLastStep() && (
            <ActionButton
              variant="success"
              size="lg"
              onClick={complete}
              disabled={!isReadyToComplete()}
              isProcessing={completeRegistrationMutation.isPending}
            >
              Finish
            </ActionButton>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CompleteRegistrationPopUp
