import { useAuth } from './useAuth'
import { useCurrentUserOrFail } from './useCurrentUserOrFail'

export function usePermissions() {
  const currentUser = useCurrentUserOrFail()
  const { currentCompany } = useAuth()
  const currentCompanyId = currentCompany?.id
  const permissions = currentUser.permissions

  return {
    canSearchPanels: permissions.includes(`c:${currentCompanyId}/panel/search`),
    canInviteToPanel: permissions.includes(
      `c:${currentCompanyId}/panel/invite`,
    ),
    canManageUsers: permissions.includes(`c:${currentCompanyId}/user/admin`),
    canManageReferralApprovals: permissions.includes(
      `c:${currentCompanyId}/referral-approval-user/edit`,
    ),
    canManagePaymentProfile: permissions.includes(
      `c:${currentCompanyId}/company/payment-profile`,
    ),
    canCreateCompany: permissions.includes(`company/create`),
  }
}
