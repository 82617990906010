import React from 'react'
import { Card, Col, Container, ContainerProps, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../lib/routes'
import PageTitle from '../../../typography/PageTitle'
import BackLink from '../../BackLink'

type Props = ContainerProps & {
  backLabel?: string
  backUrl?: string
}

const DetailsNotFound: React.FC<Props> = ({ backLabel, backUrl, ...props }) => {
  const pageTitle = 'Firm not found'

  return (
    <Container {...props}>
      <Helmet title={pageTitle} />

      {backLabel && backUrl && <BackLink to={backUrl} label={backLabel} />}

      <PageTitle>{pageTitle}</PageTitle>

      <Row>
        <Col lg={8}>
          <Card className="border-box">
            <Card.Body>
              <p>This firm's details could not be found.</p>

              <Link to={ROUTES.addRelationship} className="btn btn-primary">
                Try another search
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DetailsNotFound
