import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import useNotyf from '../../../../../../hooks/useNotyf'
import errorTypeService from '../../../../../../lib/services/errorTypeService'
import serviceService from '../../../../../../lib/services/serviceService'
import ActionButton from '../../../../../misc/ActionButton'

interface Props {
  serviceId?: number
  serviceAreaId: number
  serviceAreaName: string
  companyId: number
  onChangeSaved: () => void
}

const CompanyServiceToggleButton: React.FC<Props> = ({
  serviceId,
  serviceAreaId,
  serviceAreaName,
  companyId,
  onChangeSaved,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(serviceId !== undefined)
  const notyf = useNotyf()

  // Watch for changes
  useEffect(() => {
    setIsSelected(serviceId !== undefined)
  }, [serviceId])

  const postMutation = useMutation({
    mutationFn: async () => {
      setIsSelected(true)

      return serviceService.postService(companyId, serviceAreaId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      // Put this back how we found it before the mutation started
      setIsSelected(false)
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async () => {
      if (serviceId === undefined) {
        return
      }

      setIsSelected(false)
      return serviceService.deleteService(serviceId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: (error) => {
      // Sometimes it's forbidden because the service already has a referral attached
      if (
        errorTypeService.isApiError(error) &&
        errorTypeService.isForbiddenError(error)
      ) {
        notyf.error({
          message: `Cannot remove: ${error.response?.data['hydra:description']}`,
          duration: 4000,
        })
      } else {
        notyf.error('Something went wrong - please try again')
      }

      // Put this back how we found it before the mutation started
      setIsSelected(true)
    },
  })

  // I.e. the prop passed doesn't reflect what we now think the state should be
  // which will be because we're waiting to re-query in the parent component
  const isWaitingForSync = (serviceId !== undefined) !== isSelected

  return (
    <ActionButton
      variant={isSelected ? 'primary' : 'outline-secondary'}
      size="lg"
      className="fs-5"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      isProcessing={
        postMutation.isPending || deleteMutation.isPending || isWaitingForSync
      }
      // Don't change text during processing
      isProcessingText={serviceAreaName}
      onClick={(): void => {
        if (isSelected) {
          deleteMutation.mutate()
        } else {
          postMutation.mutate()
        }
      }}
    >
      {serviceAreaName}
    </ActionButton>
  )
}

export default CompanyServiceToggleButton
